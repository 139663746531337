import React, { useContext } from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import { AuthContext } from "./AuthContext";
import { redirectHome } from "../services/shareconstant";

function PrivateRoute({ component: Component, scopes, ...rest }) {
  const authcontext = useContext(AuthContext);

  return (
    <AuthContext.Consumer>
      {(login) => (
        <Route
          {...rest}
          render={(props) => {
            if (Object.keys(authcontext.state.userinfoglobal).length === 0 && authcontext.state.CompanyAffilliatedCountry!=="CA") {
             
              authcontext.addRedirectOnLoginHandler(
                JSON.stringify(props.history.location.pathname)
              );
              return props.history.push(redirectHome());
            }
            else if(authcontext.state.CompanyAffilliatedCountry==="CA") {
              return props.history.push("/Restricted");
            }

            //Not Using Scope for ALPA
            // 2. Display message if user lacks required scope(s).
            // if (scopes.length > 0 && !auth.userHasScopes(scopes)) {
            //   return (
            //     <h1>
            //       Unauthorized - You need the following scope(s) to view this
            //       page: {scopes.join(",")}.
            //     </h1>
            //   );
            // }

            // 3. Render component
            return <Component {...props} />;
          }}
        />
      )}
    </AuthContext.Consumer>
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  scopes: PropTypes.array,
};

PrivateRoute.defaultProps = {
  scopes: [],
};

export default PrivateRoute;
