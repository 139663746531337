import React from "react";
import Spinner from "./../Spinner";
import {
  urlContents
} from "../../services/serviceURLs";
import { Fetch } from "./../../services/useFetch";
export default function Resources(props) {
    return (    
      <Fetch url={urlContents+"/Resources"}>
      {(data, loading, error) => {
        if (loading) return <Spinner />;
        if (error) throw error;

        return(
          <div>
            <form className="k-form">
              <div className="heading-form"></div>
              {/* <div>{data.HTMLData}</div> */}
              <div dangerouslySetInnerHTML={{__html: data.HTMLData}}/>           
            </form>
            <br></br>
          </div>
            );
      }}
    </Fetch>
    
  );  
}