import * as React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Notification } from "@progress/kendo-react-notification";
import {
  Card,
  CardTitle,
  CardBody,
  CardActions,
} from "@progress/kendo-react-layout";
import { dateFormat } from "../../services/shareconstant";
export const CopyBeneficiaryForm = (props) => {
  // reduce iterates over each item, using accumulator
  let beneficiaryData = props.copybeneficiaries.reduce((acc, next) => {
    // reusable beneficiary var
    let nextBeneficiary = {
      id: next.beneficiaryid,
      name: next.name,
      dateOfBirth: next.dateOfBirth,
      relationship: next.relationship,
      beneficiarytype: next.beneficiarytype,
      type: next.type,
      percentage: next.percentage,
      inPlan: true,
    };

    // find similar inusrance, and join them
    let exist = acc.find((v) =>
      v.InsuranceID > 0
        ? v.InsuranceID === next.InsuranceID
        : v.InsuranceElectionID === next.InsuranceElectionID
    );
    if (exist) {
      // insurance exists, update its beneficiary
      exist.beneficiaries.push(nextBeneficiary);
    } else {
      // create new insurance
      acc.push({
        InsuranceID: next.InsuranceID,
        InsuranceElectionID: next.InsuranceElectionID,
        InsurancePlanTypeID: next.InsurancePlanTypeID,
        InsurancePlanTypeName: next.InsurancePlanTypeName,
        InsurancePlanTypeDescription: next.InsurancePlanTypeDescription,
        startdate:
          next.startdate === "1900-01-01T00:00:00" ||
          next.startdate === "0001-01-01T00:00:00"
            ? ""
            : new Date(next.startdate),
        beneficiaries: [nextBeneficiary],
      });
    }
    return acc;
  }, []);

  const copyClick = (card) => {
    //Step 1 - original array update with inPlan false
    props.beneficiaries.forEach((element) => {
      element.inPlan = false;
    });

    //Step 2 - update original array elements with new value
    // Filter the relationship based on Plan Type category
    let copyBeneficiary;
    if (props.category === "Life" && props.primaryInsuredParty === "Member")
      copyBeneficiary = card.filter((item) => item.relationship !== "Self");
    else if (
      props.category === "Life" &&
      props.primaryInsuredParty === "Spouse"
    )
      copyBeneficiary = card.filter((item) => item.relationship !== "Spouse");
    else copyBeneficiary = card;

    //Merge Array
    const finalBeneficiaries = props.beneficiaries.map((item) => {
      const obj = copyBeneficiary.find((o) => o.id === item.id);
      return { ...item, ...obj };
    });

    props.setBeneficiaries(finalBeneficiaries);
    props.setOpenCopyForm(false);
  };

  return (
    <Dialog
      className="copyBeneficary"
      title={"Copy Beneficiary"}
      onClose={props.cancelEdit}
    >
      <div className="editDependency styledGrid ">
        <div>
          {beneficiaryData.length > 0 &&
            props.category === "Life" &&
            props.primaryInsuredParty === "Member" && (
              <Notification
                type={{ style: "warning", icon: true }}
                closable={false}
              >
                Self beneficiaries are ineligible for the current plan. If one
                is included on a plan you copy from, they will be omitted.
              </Notification>
            )}

          {beneficiaryData.length > 0 &&
            props.category === "Life" &&
            props.primaryInsuredParty === "Spouse" && (
              <Notification
                type={{ style: "warning", icon: true }}
                closable={false}
              >
                Spouse beneficiaries are ineligible for the current plan. If one
                is included on a plan you copy from, they will be omitted.
              </Notification>
            )}
        </div>
        <div className="card-list-wrap">
          {beneficiaryData.length === 0 && (
            <p>No beneficiaries found to copy.</p>
          )}
          {beneficiaryData.map((card, index) => {
            return (
              <div key={index}>
                <Card>
                  <div>
                    <CardBody>
                      <CardTitle>{card.InsuranceID >= 0 ? (
                        <p class="text-ellipsis description-block">
                          {card.InsurancePlanTypeDescription} ID #
                          {card.InsuranceID}
                        </p>
                      ) : (
                        <p class="text-ellipsis description-block">{card.InsurancePlanTypeDescription}</p>
                      )}</CardTitle>                     

                      <p>Start Date: {dateFormat(card.startdate)}</p>
                      {/* <p>Insurance: {card.InsuranceID >= 0 ? 'Update' : 'New'}</p> */}
                      {card.beneficiaries && (
                        <div>
                          <Grid data={card.beneficiaries}>
                            <Column
                              field="beneficiarytype"
                              width="140px"
                              title="Beneficiary Type"
                            />
                            <Column field="name" title="Name" />
                            {/* <Column field="dateOfBirth" title="Date of Birth" width="120px" format="{0:MM/dd/yyyy}" /> */}
                            <Column
                              field="relationship"
                              title="Relationship"
                              width="120px"
                            />

                            <Column field="type" title="Type" />
                            <Column field="percentage" title="Percentage" />
                          </Grid>
                        </div>
                      )}
                    </CardBody>
                    <CardActions>
                      <div className="main-action">
                        <Button
                          primary={true}
                          className="k-flat"
                          onClick={() => copyClick(card.beneficiaries)}
                        >
                          {" "}
                          Copy{" "}
                        </Button>
                      </div>
                    </CardActions>
                  </div>
                </Card>
              </div>
            );
          })}
          <br />
          <button
            type={"button"}
            className="k-button"
            onClick={props.cancelEdit}
          >
            Cancel
          </button>
        </div>
      </div>
    </Dialog>
  );
};
