import React from "react";
import { Loader } from "@progress/kendo-react-indicators";

export default function Spinner() {
  return (
    <div className="col-4 spinnerLoading">
      <Loader type="infinite-spinner" size="large" themeColor="primary" />
    </div>
  );
}
