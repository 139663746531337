import React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import {
  currencyFormat
} from "../../services/shareconstant";
export default function CostSummary(props) {  
  const cellMonthlyCost = (props) => {
    const cost = parseFloat(props.dataItem.estimatedMonthlyCost).toFixed(2);
    if (cost < 0.0) {
      return <td>To be Determined</td>;
    }
    return <td>{currencyFormat(cost)}</td>;
  };
  
  return (
    props.data[0] ? (<div class="page-wrapper" className="eoi-details">
        <div class="mb-3 styledGrid CostGrid">
        <Grid data={props.data}>
          <Column field="id" title="Policy ID" />
          <Column field="insurancePlanTypeDescription" title="Approved Plan"/>
          <Column title="Estimated Monthly Cost" cell={cellMonthlyCost} />
        </Grid>
        </div>
        <br/> 
      </div>) : (
      <div>
          <p>You do not have Cost Summary</p> <br/> 
      </div>
    )
);
}