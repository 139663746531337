import React from "react";
import { Card, CardTitle, CardBody } from "@progress/kendo-react-layout";
import { dateFormat } from "../../services/shareconstant";
import ReviewCard from "./ReviewCard";
export default function ReviewNewElections(props) {
  return (
    <div className="card-list-wrap">
      {props.electionData &&
        props.electionData.map((card, index) => {
          return (
            <div key={index} className="card-items">
              <Card>
                <div>
                  <CardBody>
                    <CardTitle>
                    {card.insuranceID >= 0 ? (
                      <p class="text-ellipsis description-block">
                        {card.insurancePlanTypeDescription} ID #
                        {card.insuranceID}
                      </p>
                    ) : (
                      <p class="text-ellipsis description-block">
                        {card.insurancePlanTypeDescription}
                      </p>
                    )}
                      {card.nonContributoryOptIn === true &&
                      JSON.parse(localStorage.getItem("showApprentice")) ===
                        true
                        ? ` (Apprentice)`
                        : null}
                    </CardTitle>                    
                    <p className="startDate-label">
                      Start Date: {dateFormat(card.startDate)}
                    </p>
                    <ReviewCard card={card} current={true} />
                  </CardBody>
                </div>
              </Card>
            </div>
          );
        })}
    </div>
  );
}
