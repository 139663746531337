import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import EditBeneficiaryForm from "./EditBeneficiaryForm";
import { STATUS } from "../../model/constants";
import { PostSave } from "../../services/useFetch";
import { urlBeneficiaries } from "../../services/serviceURLs";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import Spinner from "./../Spinner";
import { AuthContext } from "../AuthContext";
import { redirectHome, ChangesSaveMessage } from "../../services/shareconstant";

export default function ReviewBeneficiariesForm(props) {
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [openAddForm, setOpenAddForm] = useState(false);
  const [editItem, setEditItem] = useState({});
  const [status, setStatus] = useState(STATUS.IDLE);
  const [saveError, setSaveError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const authContext = useContext(AuthContext);
  const loggedinpersonid = authContext.state.userinfoglobal.LinkId;

  const EditCommandCell = (props) => {
    return (
      <td className="editBtn">
        <Button primary={false} onClick={() => props.enterEdit(props.dataItem)}>
          <span className="k-icon k-i-edit"></span>
        </Button>
      </td>
    );
  };

  const emptyBeneficiary = {
    id: -1,
    personid: loggedinpersonid,
    name: "",
    dateOfBirth: "",
    gender: "",
    relationship: "",
    isActive: true,
    beneficiarytype: "Person",
    taxidentifier: "",
    line1: "",
    line2: "",
    line3: "",
    city: "",
    country: "",
    stateprovince: "",
    postalcode: "",
    county: "",
    telephone: "",
    dateOfTrust: "",
  };
  useEffect(() => {
    const initialbeneficiaries = props.data.map(function (item) {
      return {
        id: item.id,
        personid: loggedinpersonid,
        name: item.name,
        dateOfBirth:
          item.dateOfBirth === "1900-01-01T00:00:00" ||
          item.dateOfBirth === "0001-01-01T00:00:00"
            ? ""
            : new Date(item.dateOfBirth),
        gender: item.gender,
        relationship: item.relationship,
        isActive: item.isActive,
        beneficiarytype: item.beneficiarytype,
        taxidentifier: item.taxidentifier,
        line1: item.line1,
        line2: item.line2,
        line3: item.line3,
        city: item.city,
        country: item.country,
        stateprovince: item.stateprovince,
        postalcode: item.postalcode,
        county: item.county,
        telephone: item.telephone,
        dateOfTrust:
          item.dateOfTrust === "1900-01-01T00:00:00" ||
          item.dateOfTrust === "0001-01-01T00:00:00"
            ? ""
            : new Date(item.dateOfTrust),
      };
    });
    setBeneficiaries(initialbeneficiaries);
  }, [loggedinpersonid, props.data]);

  const enterEdit = (dataItem) => {
    //Date format with moment to handle date issue
    //dataItem.dateOfBirth = (dataItem.dateOfBirth === null || dataItem.dateOfBirth === '' || dataItem.dateOfBirth === '0001-01-01T00:00:00' || dataItem.beneficiarytype !== 'Person')? '': new Date(moment(dataItem.dateOfBirth).format("YYYY/MM/DD"));
    dataItem.dateOfBirth =
      dataItem.dateOfBirth === null ||
      dataItem.dateOfBirth === "null" ||
      dataItem.dateOfBirth === "" ||
      dataItem.dateOfBirth === "1900-01-01" ||
      dataItem.dateOfBirth === "0001-01-01T00:00:00" ||
      dataItem.beneficiarytype !== "Person"
        ? ""
        : new Date(dataItem.dateOfBirth);
    dataItem.dateOfTrust =
      dataItem.dateOfTrust === null ||
      dataItem.dateOfTrust === "null" ||
      dataItem.dateOfTrust === "" ||
      dataItem.dateOfTrust === "1900-01-01" ||
      dataItem.dateOfTrust === "0001-01-01T00:00:00" ||
      dataItem.beneficiarytype !== "Trust"
        ? ""
        : new Date(dataItem.dateOfTrust);
    setEditItem(dataItem);
    setOpenForm(true);
  };

  async function handleSubmit(event) {
    if (event.id > 0) {
      setOpenForm(false);
    } else {
      setOpenAddForm(false);
    }

    //Update Event Value based on Condition
    event.relationship =
      event.beneficiarytype !== "Person" ? "" : event.relationship;
    event.telephone = event.beneficiarytype !== "Person" ? "" : event.telephone;
    event.gender = event.beneficiarytype !== "Person" ? "" : event.gender;

    //Update taxidentifier for My Estate & Person Type, Address fields for My Estate Type
    event.taxidentifier =
      event.beneficiarytype === "My Estate" ||
      event.beneficiarytype === "Person"
        ? ""
        : event.taxidentifier;
    event.name = event.beneficiarytype === "My Estate" ? "" : event.name;
    event.line1 = event.beneficiarytype === "My Estate" ? "" : event.line1;
    event.line2 = event.beneficiarytype === "My Estate" ? "" : event.line2;
    event.line3 = event.beneficiarytype === "My Estate" ? "" : event.line3;
    event.city = event.beneficiarytype === "My Estate" ? "" : event.city;
    event.country = event.beneficiarytype === "My Estate" ? "" : event.country;
    event.stateprovince =
      event.beneficiarytype === "My Estate" ? "" : event.stateprovince;
    event.postalcode =
      event.beneficiarytype === "My Estate" ? "" : event.postalcode;
    event.county = event.beneficiarytype === "My Estate" ? "" : event.county;

    // event.dateOfBirth = (event.dateOfBirth === null || event.dateOfBirth === 'null' ||  event.dateOfBirth === '' || event.dateOfBirth === '0001-01-01T00:00:00' || event.beneficiarytype !== 'Person') ? '1900-01-01' : new Date(moment(event.dateOfBirth).format("YYYY-MM-DD"));
    event.dateOfBirth =
      event.dateOfBirth === null ||
      event.dateOfBirth === "null" ||
      event.dateOfBirth === "" ||
      event.dateOfBirth === "1900-01-01" ||
      event.dateOfBirth === "0001-01-01T00:00:00" ||
      event.dateOfBirth === "0001-01-01T00:00:00" ||
      event.beneficiarytype !== "Person"
        ? "1900-01-01"
        : event.dateOfBirth.toLocaleDateString();
    event.dateOfTrust =
      event.dateOfTrust === null ||
      event.dateOfTrust === "null" ||
      event.dateOfTrust === "" ||
      event.dateOfTrust === "1900-01-01" ||
      event.dateOfTrust === "0001-01-01T00:00:00" ||
      event.dateOfTrust === "0001-01-01T00:00:00" ||
      event.beneficiarytype !== "Trust"
        ? "1900-01-01"
        : event.dateOfTrust.toLocaleDateString();
    setStatus(STATUS.SUBMITTING);
    try {
      const saveresponse = await PostSave(
        `${urlBeneficiaries}/${event.id}`,
        `POST`,
        event
      );
      // To handle null date
      // event.dateOfBirth = (event.dateOfBirth === null || event.dateOfBirth === '' || event.dateOfBirth === '1900-01-01' || event.beneficiarytype !== 'Person')? '': new Date(moment(event.dateOfBirth).format("YYYY-MM-DD"));
      event.dateOfBirth =
        event.dateOfBirth === null ||
        event.dateOfBirth === "" ||
        event.dateOfBirth === "1900-01-01" ||
        event.beneficiarytype !== "Person"
          ? ""
          : new Date(event.dateOfBirth);
      event.dateOfTrust =
        event.dateOfTrust === null ||
        event.dateOfTrust === "" ||
        event.dateOfTrust === "1900-01-01" ||
        event.beneficiarytype !== "Trust"
          ? ""
          : new Date(event.dateOfTrust);
      setSaveError(null);
      if (saveresponse.ok) {
        if (event.id > 0) {
          const arr = [...beneficiaries];
          const index = arr.findIndex((x) => x.id === event.id);
          arr[index] = event;
          setBeneficiaries(arr);
        } else {
          const json = await saveresponse.json();
          const newBeneficiary = { ...event, id: json.id };

          setBeneficiaries((newBeneficiaries) => [
            ...newBeneficiaries,
            newBeneficiary,
          ]);
        }
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
        setError(false);
        setStatus(STATUS.COMPLETED);
      } else {
        throw saveresponse;
      }
    } catch (e) {
      setSaveError(e.statusText);
      setStatus(STATUS.ERROR);
      setSuccess(false);
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  }

  function handleAdd(event) {
    event.preventDefault();
    setOpenAddForm(true);
  }

  function handleCancelEdit() {
    setOpenForm(false);
  }

  function handleAddCancelEdit() {
    setOpenAddForm(false);
  }

  const MyEditCommandCell = (props) => (
    <EditCommandCell {...props} enterEdit={enterEdit} />
  );

  const history = useHistory();
  const gotoHome = () => {
    history.push(redirectHome());
  };

  return (
    <div className="page-wrapper">
      <div className="panelbar-wrapper myBeneficiaries">
        <form className="k-form">
          <div className="heading-form">My Beneficiaries</div>
          <Notification type={{ style: "info", icon: true }} closable={false}>
            {" "}
            Please update your beneficiaries as applicable. Note that
            updating beneficiaries here does not automatically enroll them in an
            insurance product.
          </Notification>{" "}
          <br /> <br />
          <div className="mb-3 wraper-btn-add-beneficiary">
            <Button primary={true} onClick={handleAdd}>
              Add New Beneficiary
            </Button>

            {openAddForm && (
              <EditBeneficiaryForm
                cancelEdit={handleAddCancelEdit}
                onSubmit={handleSubmit}
                item={emptyBeneficiary}
              />
            )}
            {status === STATUS.SUBMITTING && <Spinner />}
          </div>
          <div className="mb-3 notificationGrp">
            <NotificationGroup
              style={{
                alignItems: "flex-start",
                flexWrap: "wrap",
              }}
            >
              <Fade enter={true} exit={true}>
                {success && (
                  <Notification
                    type={{ style: "success", icon: true }}
                    closable={true}
                    onClose={() => setSuccess(false)}
                  >
                    <span>{ChangesSaveMessage}</span>
                  </Notification>
                )}
              </Fade>
              <Fade enter={true} exit={true}>
                {error && (
                  <Notification
                    type={{ style: "error", icon: true }}
                    closable={true}
                    onClose={() => setError(false)}
                  >
                    <span> {saveError}</span>
                  </Notification>
                )}
              </Fade>
            </NotificationGroup>
          </div>
          <fieldset>
            <div className="mb-3 styledGrid review-beneficiaryGrid">
              <Grid data={beneficiaries}>
                <Column field="beneficiarytype" title="Beneficiary Type" />
                <Column field="name" title="Name" />
                <Column
                  field="dateOfBirth"
                  title="Date of Birth"
                  format="{0:MM/dd/yyyy}"
                />
                <Column field="relationship" title="Relationship" />
                <Column
                  field="isActive"
                  title="Active"
                  cell={(props) => (
                    <td>
                      <input
                        type="checkbox"
                        checked={props.dataItem[props.field]}
                        onChange={() => {} }
                        className="disabledCheckBox" 
                      />
                    </td>
                  )}
                />
                <Column cell={MyEditCommandCell} title="Edit" />
              </Grid>
              {openForm && (
                <EditBeneficiaryForm
                  cancelEdit={handleCancelEdit}
                  onSubmit={handleSubmit}
                  item={editItem}
                />
              )}
            </div>
          </fieldset>
          <Button onClick={gotoHome} primary={true} className="btnLink">
            {" "}
            Back to Home
          </Button>
        </form>
      </div>
    </div>
  );
}
