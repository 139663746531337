import React from "react";
import { FetchAll } from "../../services/useFetchAll";
import Spinner from "../Spinner";
import PageNotFound from "../PageNotFound";
import {
  urlHistoricalCoverage,
  urlPersonAPAsOf,
} from "../../services/serviceURLs";
import HistoricalCoverageFrom from "./HistoricalCoverageForm";
import { historyMinDate, historyMaxDate } from "../../services/shareconstant";
export default function HistoricalCoverage(props) {
  const date = Date.parse(
    props.historyDate === null ? 0 : props.historyDate.toString()
  );

  if (!isNaN(date) && date !== 946665000000) {
    if (
      props.historyDate.setHours(0, 0, 0, 0) <=
        historyMaxDate.setHours(0, 0, 0, 0) &&
      props.historyDate.setHours(0, 0, 0, 0) >=
        historyMinDate.setHours(0, 0, 0, 0)
    ) {
      return (
        <FetchAll
          urls={[
            `${urlHistoricalCoverage}/${props.historyDate.getFullYear()}/${
              props.historyDate.getMonth() + 1
            }/${props.historyDate.getDate()}`,
            `${urlPersonAPAsOf}/${props.historyDate.getFullYear()}/${
              props.historyDate.getMonth() + 1
            }/${props.historyDate.getDate()}`,
          ]}
        >
          {(data, loading, error) => {
            if (loading) return <Spinner />;
            if (!data) return <PageNotFound />;
            if (error) throw error;
            return (
              <HistoricalCoverageFrom data={data[0]} isAP={data[1].isAP} />
            );
          }}
        </FetchAll>
      );
    } else return null;
  } else return null;
}
