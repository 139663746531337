const baseEbizURL = process.env.REACT_APP_API_EBiz_URL;
//const baseEbizURL = "https://ebiz60dev.alpa.org/SOA/";
export const urlLogin = `${baseEbizURL}Authentication/Login/eBusinessWebUser/`;
export const urlLogout = `${baseEbizURL}Authentication/Logout/`;
export const urlUserInfo = `${baseEbizURL}UserInformation`;

const baseInsuranceURL = process.env.REACT_APP_API_Insurance_URL;
//const baseInsuranceURL = "https://ebiz60dev.alpa.org/SOA/v1/Insurance/";
export const urlPersonalInformation = `${baseInsuranceURL}PersonalInfo`;
export const urlDependents = `${baseInsuranceURL}Dependents`;
export const urlBeneficiaries = `${baseInsuranceURL}Beneficiaries`;
export const urlCurrentCoverage = `${baseInsuranceURL}CurrentCoverage`;
export const urlCurrentCoverageOld = `${baseInsuranceURL}CurrentCoverageOld`;
export const urlEnrollNewCoverage = `${baseInsuranceURL}EligibleNewCoverage`;
export const urlEnrollNewCoverageLife = `${baseInsuranceURL}EligibleNewCoverageLife`;
export const urlEnrollNewCoverageDisability = `${baseInsuranceURL}EligibleNewCoverageDisability`;
export const urlEnrollNewCoverageCritical = `${baseInsuranceURL}EligibleNewCoverageCritical`;
export const urlEnrollNewCoverageDental = `${baseInsuranceURL}EligibleNewCoverageDental`;
export const urlEnrollmentQuestions = `${baseInsuranceURL}EnrollmentQuestions`;
export const urlEnrollmentInputAttributes = `${baseInsuranceURL}EnrollmentInputAttributes`;
export const urlRateEstimationAttributes = `${baseInsuranceURL}RateEstimationAttributes`;
export const urlEstimateCost = `${baseInsuranceURL}EstimateCost`;
export const urlEnrollmentSave = `${baseInsuranceURL}Election`;
export const urlReviewElections = `${baseInsuranceURL}ReviewElections`;
export const urlElectionCost = `${baseInsuranceURL}ElectionCost/`;
export const urlUpdateElectionDetails = `${baseInsuranceURL}UpdateElectionDetails/`;
export const urlFinalizeElections = `${baseInsuranceURL}FinalizeElections`;
export const urlLinkDepedent = `${urlDependents}/Link/`;
export const urlLinkBeneficiary = `${urlBeneficiaries}/Link/`;
export const urlcopyBeneficiary = `${baseInsuranceURL}Beneficiaries/Plans`;
export const urlPendingUpdateElectionsForNonFuturePlans = `${urlCurrentCoverage}/PendingUpdateElectionsForNonFuturePlans`;
export const urlPendingUpdateElectionsForFuturePlans = `${urlCurrentCoverage}/PendingUpdateElectionsForFuturePlans`;
export const urlPendingNewElections = `${urlCurrentCoverage}/PendingNewElections`;
export const urlPendingTerminations = `${urlCurrentCoverage}/PendingTerminations`;
export const urlNoPendingElections = `${urlCurrentCoverage}/NoPendingElections`;
export const urlPendingCancellations = `${urlCurrentCoverage}/PendingCancellations`;
export const urlPendingReinstatements = `${urlCurrentCoverage}/PendingReinstatements`;
export const urlMemberPlanDetails = `${baseInsuranceURL}MemberPlanDetails`;
export const urlEffectiveDate = `${baseInsuranceURL}EffectiveDate`;
export const urlCurrentDate = `${baseInsuranceURL}CurrentDate`;
export const urlPersonAPBenefitsInfo = `${baseInsuranceURL}PersonAPBenefitsInfo`;
export const urlSaveFreePlan = `${baseInsuranceURL}SaveFreePlan`;
export const urlAPBeneficiaries = `${baseInsuranceURL}Beneficiaries/-1/-1`;
export const urlAPEnrollmentQuestions = `${baseInsuranceURL}EnrollmentQuestions/-1/-1/40`;
export const urlHistoricalCoverage = `${baseInsuranceURL}HistoricalCoverage`;
export const urlPersonAPAsOf = `${baseInsuranceURL}PersonAPAsOf`;
export const urlPersonInsuranceEOIRequestsInfo = `${baseInsuranceURL}PersonInsuranceEOIRequestsInfo`;
export const urlPersonInsuranceEOIRequests = `${baseInsuranceURL}PersonInsuranceEOIRequests`;
export const urlEOIPendingUpdateElectionsForNonFuturePlans = `${urlCurrentCoverage}/EOIPendingUpdateElectionsForNonFuturePlans`;
export const urlEOIPendingUpdateElectionsForFuturePlans = `${urlCurrentCoverage}/EOIPendingUpdateElectionsForFuturePlans`;
export const urlEOIPendingNewElections = `${urlCurrentCoverage}/EOIPendingNewElections`;
export const urlContents = `${baseInsuranceURL}Contents`;
export const urlAffirmationContents = `${baseInsuranceURL}Affirmation`;
export const urlCompanyAffilliatedCountry = `${baseInsuranceURL}CompanyAffilliatedCountry`;

