import React, { useState,useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { redirectHome } from "../../services/shareconstant";
import { Notification } from "@progress/kendo-react-notification";
import {

  dateFormat,
  datewotimestampFormat,
} from "../../services/shareconstant";
import { urlPersonInsuranceEOIRequests } from "../../services/serviceURLs";
import EOIRequestDetails from "../Notification/EOIRequestDetails";
import CostSummary from "../Confirmation/CostSummary";

export default function Confirmation(props) {
  const history = useHistory();
  const onHome = () => {
    history.push(redirectHome());
  };
  const [getError, setGetError] = useState(null);
  const [error, setError] = useState(false);
  const [eoiRequest, seteoiRequest] = useState([]);
  //  const now = new Date();
  //  const nextMonthFirst = new Date(now.getFullYear(), now.getMonth() + 1, 1) //Need to change based on Server side Effective Date Logic
  useEffect(() => {
      const requestOptions = {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      };
      try {
        fetch(
          `${urlPersonInsuranceEOIRequests}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            seteoiRequest(result);
          });
        } catch (e) {
          setGetError(e.statusText);
          setError(true);
        }
  },[]
  );
  
  if (props.location.state) {
    return (
      <>
        <div className="completeSelection summarySelection">
          <p className="heading-form">Confirmation</p>
          <Notification
            type={{ style: "success", icon: true }}
            closable={false}
          >
            {" "}
            Congratulations, your benefits have been confirmed.
          </Notification>
          <p className="heading-form">
            Your Cost Summary as of{" "}
            {dateFormat(props.location.state.electionDates.effectivedate)
              ? dateFormat(props.location.state.electionDates.effectivedate)
              : datewotimestampFormat(
                  props.location.state.electionDates.effectivedate
                )}{" "}
          </p>
          <br />
          <CostSummary data={props.location.state.data} />
          <div>          
          <br />
          <br />
          <br />
          <br />
          <Notification type={{ style: "info", icon: true }} closable={false}>
            Additional verification is required for the following election(s).
            Coverage amount will be determined once you have been approved.
          </Notification>
          <EOIRequestDetails data={eoiRequest} />
          <br />
          <br />
          </div>
          
          <Notification type={{ style: "info", icon: true }} closable={false}>
            {" "}
            This is summary of your benefit elections. It does not take the place of
            or supersede official plan requirements including evidence of
            insurability(EOI) completion. Any coverage elections you make
            requiring EOI approval from an insurance carrier will not be
            effective until the insurance carrier provides confirmation of
            approval regardless of what may be displayed on this summary. In the
            event of discrepancy between the information within this summary and
            official plan documents, the plan documents will prevail.
          </Notification>

          <br />
          <Button primary={true} onClick={onHome}>
            {" "}
            Home
          </Button>
        </div>
      </>
    );
  }
  return <Redirect to={redirectHome} />;
}
