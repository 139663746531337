import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import HelpForm from "./HelpForm";

class Help extends Component {
  static contextType = AuthContext;

  cobrowseSupportCode() {
    return (
      <div>
        {" "}
        <button
                type={"submit"}
                className="k-button k-primary"
                onClick={window["showSessionCode"]}
              >
                Generate a Support Code
              </button>       
      </div>
    );
  }

  render() {
    
      return (        
        <div className="page-wrapper"> 
          <div className="panelbar-wrapper">
              <HelpForm></HelpForm>  
          </div>
          <div className="panelbar-wrapper">
            {this.cobrowseSupportCode()}
          </div>
         
        </div>
        
      )
}
}

export default withRouter(Help);
