import React from "react";
const Logout = () => {
  
  return (
    <div className="Logout">
      <p>You have been successfully logged out.</p>
      <p>Please close the browser to complete the logout process.</p>
    </div>
  );
};

export default Logout;
