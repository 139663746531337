import React, { useState } from "react";
import {
  Card,
  CardTitle,
  CardBody,
  CardActions,
} from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { formatDate, parseDate } from "@telerik/kendo-intl";
import ReviewCard from "./ReviewCard";
import { urlUpdateElectionDetails } from "../../services/serviceURLs";
import { GetFetch } from "../../services/useFetch";
import { currencyFormat } from "../../services/shareconstant";
import { STATUS } from "../../model/constants";
import Spinner from "../Spinner";
import { Notification } from "@progress/kendo-react-notification";
import { Tooltip } from "@progress/kendo-react-tooltip";
import CustomEOIToolTip from "../CurrentCoverage/CustomEOIToolTip";

export default function ReviewUpdateDetails(props) {
  const [status, setStatus] = useState(STATUS.IDLE);
  const [getError, setGetError] = useState(null);
  //const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [showChanges, setshowChanges] = useState(false);
  const [details, setDetails] = useState({});

  const showClick = async () => {
    setStatus(STATUS.SUBMITTING);
    setGetError(null);
    setError(false);
    if (Object.entries(details).length === 0) {
      try {
        const saveresponse = await GetFetch(
          `${urlUpdateElectionDetails}/${props.electionData.insuranceElectionID}`
        );
        if (saveresponse.ok) {
          const output = await saveresponse.json();
          setDetails(output);
          setStatus(STATUS.COMPLETED);
        } else {
          throw saveresponse;
        }
      } catch (e) {
        setGetError(e.statusText);
        setStatus(STATUS.ERROR);
        // setSuccess(false);
        setError(true);
      }
    } else {
      setStatus(STATUS.COMPLETED);
    }
    setshowChanges(!showChanges);
  };

  return (
    <>
      {status === STATUS.SUBMITTING && <Spinner />}

      {error && (
        <Notification type={{ style: "error", icon: true }} closable={false}>
          {getError}
        </Notification>
      )}
      <div className="card-items">
        <Card>
          <div>
            <CardBody>
              <CardTitle>{props.electionData.insuranceID >= 0 ? (
                <p class="text-ellipsis description-block">
                  {props.electionData.insurancePlanTypeDescription} ID #
                  {props.electionData.insuranceID}
                </p>
              ) : (
                <p class="text-ellipsis description-block">
                  {props.electionData.insurancePlanTypeDescription}
                </p>
              )}</CardTitle>              
              <p className="startDate-label">
                Start Date:{" "}
                {formatDate(
                  parseDate(props.electionData.startDate, [
                    "yyyy/MM/dd",
                    "yyyy-MM-ddTHH:mm:ss",
                  ]),
                  "MM/dd/yyyy"
                )}
              </p>
              <ReviewCard card={props.electionData} current={props.current} />
            </CardBody>
            <CardActions>
              <div className="main-action">
                <Button
                  primary={true}
                  className="k-flat"
                  onClick={() => showClick()}
                >
                  {" "}
                  {showChanges ? `Hide Details` : `Show Details`}{" "}
                </Button>
              </div>
            </CardActions>
            {showChanges === true && (
              <>
                <div className="mb-12">
                  <table className="election-table">
                    <tr>
                      <th>Description</th>
                      <th>Previous Election </th>
                      <th>Changed Election </th>
                    </tr>
                    <tr>
                      <td>
                        {/* Top level  - Key */}
                        {details.Previous &&
                          Object.keys(details.Previous).map(
                            (key, i) =>
                              key !== "Attributes" &&
                              key !== "Beneficiaries" &&
                              key !== "Dependents" && (
                                <p key={i}>
                                  <span>
                                    {" "}
                                    {key === "fullCost" && (props.card.status==='EOI Pending') ? <>
                                    <Tooltip
                                      content={(prop) => (
                                        <CustomEOIToolTip                               
                                        />
                                      )}
                                      anchorElement="target"
                                      position="bottom"
                                    >
                                      <span className="label-viewdetails">
                                        Full Cost{" "}
                                      </span>
                                      <span
                                        className="k-icon k-i-information"
                                        title="Full Cost"
                                      ></span>
                                    </Tooltip>
                                  </> : (key === "FullCost"?"Full Cost":key)}
                                  </span>
                                </p>
                              )
                          )}
                        {/* 2nd level  Attributes - Key */}
                        {details.Previous.Attributes &&
                          Object.keys(details.Previous.Attributes).map(
                            (key, i) => (
                              <p key={i}>
                                <span> {key}</span>
                              </p>
                            )
                          )}
                      </td>
                      <td>
                        {/* Top level Previous - Value */}
                        {details.Previous &&
                          Object.keys(details.Previous).map(
                            (key, i) =>
                              key !== "Attributes" &&
                              key !== "Beneficiaries" &&
                              key !== "Dependents" && (
                                <p key={i}>
                                  {
                                    // details.Previous[key] &&
                                    <span>
                                      {" "}
                                      {isNaN(details.Previous[key])
                                        ? details.Previous[key] 
                                        : details.Previous[key] === -1 ? 'TBD'
                                        : currencyFormat(
                                            parseFloat(details.Previous[key])
                                          )}
                                    </span>
                                  }
                                </p>
                              )
                          )}
                        {/* 2nd level Previous Attributes - Value */}
                        {details.Previous.Attributes &&
                          Object.keys(details.Previous.Attributes).map(
                            (key, i) => (
                              <p key={i}>
                                <span>
                                  {" "}
                                  {isNaN(details.Previous.Attributes[key])
                                    ? details.Previous.Attributes[key]
                                    : details.Previous.Attributes[key] === -1 ? 'TBD' : currencyFormat(
                                        parseFloat(
                                          details.Previous.Attributes[key]
                                        )
                                      )}
                                </span>
                              </p>
                            )
                          )}
                      </td>
                      <td>
                        {/* Top level Changed - Value */}
                        {details.Changed &&
                          Object.keys(details.Changed).map(
                            (key, i) =>
                              key !== "Attributes" &&
                              key !== "Beneficiaries" &&
                              key !== "Dependents" && (
                                <p key={i}>
                                  {
                                    //details.Changed[key] &&
                                    <span>
                                      {" "}
                                      {isNaN(details.Changed[key])
                                        ? details.Changed[key]
                                        : details.Changed[key] === -1 ? 'TBD'
                                        : currencyFormat(
                                            parseFloat(details.Changed[key])
                                          )}
                                    </span>
                                  }
                                </p>
                              )
                          )}
                        {/* 2nd level Changed Attributes - Value */}
                        {details.Changed.Attributes &&
                          Object.keys(details.Changed.Attributes).map(
                            (key, i) => (
                              <p key={i}>
                                <span>
                                  {" "}
                                  {isNaN(details.Changed.Attributes[key])
                                    ? details.Changed.Attributes[key]
                                    : details.Changed.Attributes[key] === -1 ? 'TBD' 
                                    : currencyFormat(
                                        parseFloat(
                                          details.Changed.Attributes[key]
                                        )
                                      )}
                                </span>
                              </p>
                            )
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {/* 3rd level  Array for Beneficiary - Key */}
                        {details.Previous.Beneficiaries && (
                          <p> Beneficiaries </p>
                        )}
                      </td>
                      <td>
                        {/* 3rd level Previous Array for Beneficiary - Value */}
                        {
                          details.Previous.Beneficiaries && (
                            <table width="100%" cellPadding="0" cellSpacing="0">
                              <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Percentage</th>
                              </tr>
                              {details.Previous.Beneficiaries.map(
                                (beneficiary, index) => (
                                  <tr>
                                    {" "}
                                    <td>{beneficiary.Name}</td>{" "}
                                    <td>{beneficiary.Type}</td>{" "}
                                    <td>{beneficiary.Percentage}% </td>{" "}
                                  </tr>
                                )
                              )}
                            </table>
                          )
                          //     <Grid
                          //     data={details.Previous.Beneficiaries}
                          //     >

                          //     <Column field="Name" title="Name"  />
                          //     <Column field="Type" title="Type" />
                          //     <Column field="Percentage" title="Percentage"    format="{0:#'%'}" />
                          //  </Grid>
                        }
                      </td>
                      <td>
                        {/* 3rd level Changed Array for Beneficiary - Value */}
                        {
                          details.Changed.Beneficiaries && (
                            <table width="100%" cellPadding="0" cellSpacing="0">
                              <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Percentage</th>
                              </tr>{" "}
                              {details.Changed.Beneficiaries.map(
                                (beneficiary, index) => (
                                  <tr>
                                    <td>{beneficiary.Name}</td>
                                    <td>{beneficiary.Type}</td>{" "}
                                    <td>{beneficiary.Percentage}% </td>{" "}
                                  </tr>
                                )
                              )}
                            </table>
                          )

                          //     <Grid
                          //     data={details.Changed.Beneficiaries}
                          //     >

                          //     <Column field="Name" title="Name" />
                          //     <Column field="Type" title="Type" />
                          //     <Column field="Percentage" title="Percentage"  format="{0:#'%'}" />
                          //  </Grid>
                        }
                      </td>
                    </tr>
                    <tr className="dependantTable">
                      <td>
                        {details.Previous.Dependents && <p> Dependents </p>}
                      </td>
                      <td>
                        {/* 3rd level Previous Array for Dependents - Value */}
                        {
                          details.Previous.Dependents && (
                            <table width="100%" cellPadding="0" cellSpacing="0">
                              <tr>
                                <th>Name</th>
                                <th>Relationship</th>
                              </tr>
                              {details.Previous.Dependents.map(
                                (dependent, index) => (
                                  <tr>
                                    {" "}
                                    <td>{dependent.Name}</td>{" "}
                                    <td>{dependent.Relationship}</td>{" "}
                                  </tr>
                                )
                              )}
                            </table>
                          )
                          // <Grid
                          //     data={details.Previous.Dependents}
                          //     >

                          //     <Column field="Name" title="Name" />
                          //     <Column field="Relationship" title="Relationship" />
                          //  </Grid>
                        }
                      </td>
                      <td>
                        {/* 3rd level Changed Array for dependent - Value */}
                        {
                          details.Changed.Dependents && (
                            <table width="100%" cellPadding="0" cellSpacing="0">
                              <tr>
                                <th>Name</th>
                                <th>Relationship</th>
                              </tr>{" "}
                              {details.Changed.Dependents.map(
                                (dependents, index) => (
                                  <tr>
                                    <td>{dependents.Name}</td>
                                    <td>{dependents.Relationship}</td>{" "}
                                  </tr>
                                )
                              )}
                            </table>
                          )
                          //     <Grid
                          //     data={details.Changed.Dependents}
                          //     >

                          //     <Column field="Name" title="Name" />
                          //     <Column field="Relationship" title="Relationship" />
                          //  </Grid>
                        }
                      </td>
                    </tr>
                  </table>
                </div>
              </>
            )}
          </div>
        </Card>
      </div>
    </>
  );
}
