import React, { useState, useEffect, useContext } from "react";
import {
  urlEnrollmentQuestions,
  urlDependents,
  urlBeneficiaries,
  urlEnrollmentSave,
  urlcopyBeneficiary,
  urlEnrollmentInputAttributes,
  urlRateEstimationAttributes,
} from "../../services/serviceURLs";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Stepper } from "@progress/kendo-react-layout";
import { Questions } from "./Questions";
import { FamilyReview } from "./FamilyReview";
import { DetermineCoverage } from "./DetermineCoverage";
import { Affirmation } from "./Affirmation";
import { PlanCost } from "./PlanCost";
import Spinner from "../Spinner";
import { AuthContext } from "../AuthContext";
import { STATUS, PLANSTEP } from "../../model/constants";
import { PostSave } from "../../services/useFetch";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useHistory, Redirect } from "react-router-dom";
import { Notification } from "@progress/kendo-react-notification";
import {
  redirectHome,
  errorSaveMessage,
  SuccessSaveMessage,
} from "../../services/shareconstant";

export default function PlanWizard(props) {
  const history = useHistory();
  const enrollmentPages = props.location.state?.plan?.enrollmentPages;
  const insuranceID = props.location.state?.plan?.insuranceID || -1;
  const hasBeneficiaries = props.location.state?.plan?.hasBeneficiaries;
  const insurancePlanTypeID = props.location.state?.plan?.insurancePlanTypeID;
  const insuranceElectionID =
    props.location.state?.plan?.insuranceElectionID || -1;
  const category = props.location.state?.plan?.category;
  const primaryInsuredParty = props.location.state?.plan?.primaryInsuredParty;

  const [step, setStep] = useState(0);
  const [formState, setFormState] = useState({});
  const [steps, setSteps] = useState([]);
  const [enrollmentPagecount, setEnrollmentPagecount] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [dependents, setDependents] = useState([]);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [coverageData, setCoverageData] = useState([]);
  const [rateEstimationAttributes, setRateEstimationAttributes] = useState([]);
  const authContext = useContext(AuthContext);
  const loggedinpersonid = authContext.state.userinfoglobal.LinkId;
  const [benificaryValidMsg, setBenificaryValidMsg] = useState(``);
  const [coverageamountValidMsg, setCoverageamountValidMsg] = useState(``);
  const [dependentValidMsg, setDependentValidMsg] = useState(``);

  const [status, setStatus] = useState(STATUS.IDLE);
  const [saveError, setSaveError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [electionMsg, setElectionMsg] = useState(``);

  const [copybeneficiaries, setcopyBeneficiaries] = useState([]);

  const insurancePlanTypeDescription =
    props.location.state?.plan?.insurancePlanTypeDescription;
  const [insuranceDraftElectionID, setinsuranceDraftElectionID] = useState(-1);

  const electionDates = props.location.state?.electionDates;

  const onHome = () => {
    history.push(redirectHome());
  };

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    };
    function getQuestions() {
      if (enrollmentPages.includes("questions")) {
        return fetch(
          `${urlEnrollmentQuestions}/${insuranceElectionID}/${insuranceID}/${insurancePlanTypeID}`,
          requestOptions
        ).then((response) => response.json());
      } else {
        return [];
      }
    }

    function getDependent() {
      if (enrollmentPages.includes("dependents")) {
        return fetch(
          `${urlDependents}/${insuranceElectionID}/${insuranceID}`,
          requestOptions
        ).then((response) => response.json());
      } else {
        return [];
      }
    }

    function getBeneficiaries() {
      if (enrollmentPages.includes("coverage") && hasBeneficiaries === true) {
        return fetch(
          `${urlBeneficiaries}/${insuranceElectionID}/${insuranceID}`,
          requestOptions
        ).then((response) => response.json());
      } else {
        return [];
      }
    }

    function getCoverageData() {
      if (enrollmentPages.includes("coverage")) {
        return fetch(
          `${urlEnrollmentInputAttributes}/${insuranceElectionID}/${insuranceID}/${insurancePlanTypeID}`,
          requestOptions
        ).then((response) => response.json());
      } else {
        return [];
      }
    }

    function getRateEstimationAttributes() {
      if (enrollmentPages.includes("coverage")) {
        return fetch(
          `${urlRateEstimationAttributes}/${insurancePlanTypeID}`,
          requestOptions
        ).then((response) => response.json());
      } else {
        return [];
      }
    }

    function getCopyBeneficiaries() {
      if (enrollmentPages.includes("coverage") && hasBeneficiaries === true) {
        return fetch(`${urlcopyBeneficiary}`, requestOptions).then((response) =>
          response.json()
        );
      } else {
        return [];
      }
    }

    function getAllDataPromise() {
      return Promise.all([
        getQuestions(),
        getDependent(),
        getBeneficiaries(),
        getCoverageData(),
        getCopyBeneficiaries(),
        getRateEstimationAttributes(),
      ]);
    }
    function setStepsInit() {
      const separated = enrollmentPages.split("|");
      setEnrollmentPagecount(separated.length);

      separated.forEach(function (item) {
        // questions|dependents|coverage|affirmation|review

        let labelValue = "";

        // eslint-disable-next-line default-case
        switch (item) {
          case `questions`:
            labelValue = PLANSTEP.questions; // "Question";

            break;
          case `coverage`:
            labelValue = PLANSTEP.coverage; // "Determine Coverage";

            break;
          case `affirmation`:
            labelValue = PLANSTEP.affirmation; //"Affirmation";
            break;
          case `review`:
            labelValue = PLANSTEP.review; //"Plan Cost";
            break;
          case `dependents`:
            labelValue = PLANSTEP.dependents; ///"Family Review";
            break;
        }
        if (labelValue !== "") {
          const newStep = { label: labelValue, isValid: undefined };
          setSteps((newSteps) => [...newSteps, newStep]);
        }
      });
    }

    if (enrollmentPages !== undefined && enrollmentPages !== "") {
      getAllDataPromise().then(
        ([
          resultQuestions,
          resultDependents,
          resultBeneficiaries,
          resultCoverageData,
          resultCopyBeneficiaries,
          resultRateEstimationAttributes,
        ]) => {
          if (resultQuestions.length > 0) {
            let questionDefaultValue = {};
            resultQuestions.forEach(function (resultQuestion) {
              questionDefaultValue[resultQuestion.questionID.toString()] =
                resultQuestion.defaultAnswer;
            });

            setFormState((formState) => {
              return {
                ...formState,
                questions: questionDefaultValue,
              };
            });
            setQuestions(resultQuestions);
          }
          if (resultDependents.length > 0) {
            const initialdependents = resultDependents.map(function (item) {
              return {
                id: item.id,
                personid: loggedinpersonid,
                firstname: item.firstname,
                lastname: item.lastname,
                dateOfBirth:
                  item.dateOfBirth === "1900-01-01T00:00:00" ||
                  item.dateOfBirth === "0001-01-01T00:00:00"
                    ? ""
                    : new Date(item.dateOfBirth),
                gender: item.gender,
                relationship: item.relationship,
                line1: item.line1,
                line2: item.line2,
                line3: item.line3,
                city: item.city,
                country: item.country,
                stateprovince: item.stateprovince,
                postalcode: item.postalcode,
                county: item.county,
                telephone: item.telephone,
                middlename: item.middlename,
                suffix: item.suffix,
                disabilitydate:
                  item.disabilitydate === "1900-01-01T00:00:00" ||
                  item.disabilitydate === "0001-01-01T00:00:00"
                    ? ""
                    : new Date(item.disabilitydate),
                inPlan: item.inPlan,
                isActive: item.isActive,
              };
            });
            setDependents(initialdependents);
          }

          if (resultBeneficiaries.length > 0) {
            const initialbeneficiaries = resultBeneficiaries.map(function (
              item
            ) {
              return {
                id: item.id,
                personid: loggedinpersonid,
                name: item.name,
                dateOfBirth:
                  item.dateOfBirth === "1900-01-01T00:00:00" ||
                  item.dateOfBirth === "0001-01-01T00:00:00"
                    ? ""
                    : new Date(item.dateOfBirth),
                gender: item.gender,
                relationship: item.relationship,
                isActive: item.isActive,
                beneficiarytype: item.beneficiarytype,
                taxidentifier: item.taxidentifier,
                line1: item.line1,
                line2: item.line2,
                line3: item.line3,
                city: item.city,
                country: item.country,
                stateprovince: item.stateprovince,
                postalcode: item.postalcode,
                county: item.county,
                telephone: item.telephone,
                dateOfTrust:
                  item.dateOfTrust === "1900-01-01T00:00:00" ||
                  item.dateOfTrust === "0001-01-01T00:00:00"
                    ? ""
                    : new Date(item.dateOfTrust),
                inPlan: item.inPlan,
                type: item.type ? item.type : "Primary",
                percentage: parseFloat(item.percentage),
                inEdit: true,
              };
            });
            setBeneficiaries(initialbeneficiaries);
          }

          if (resultCoverageData.length > 0) {
            setCoverageData(resultCoverageData);
            resultCoverageData.forEach(function (inputData) {
              setFormState((formState) => {
                return {
                  ...formState,
                  [inputData.name]:
                    inputData.type === "Money"
                      ? parseInt(inputData.defaultValue)
                      : inputData.defaultValue,
                };
              });
            });
          }

          if (resultCopyBeneficiaries.length > 0) {
            const initialcopybeneficiaries = resultCopyBeneficiaries.map(
              function (item) {
                return {
                  InsuranceID: item.InsuranceID,
                  InsuranceElectionID: item.InsuranceElectionID,
                  InsurancePlanTypeID: item.InsurancePlanTypeID,
                  InsurancePlanTypeName: item.InsurancePlanTypeName,
                  InsurancePlanTypeDescription:
                    item.InsurancePlanTypeDescription,
                  beneficiaryid: item.beneficiaryID,
                  personid: loggedinpersonid,
                  name: item.name,
                  dateOfBirth:
                    item.dateOfBirth === "1900-01-01T00:00:00" ||
                    item.dateOfBirth === "0001-01-01T00:00:00"
                      ? ""
                      : new Date(item.dateOfBirth),
                  // gender: item.gender,
                  relationship: item.relationship,
                  // isActive: item.isActive,
                  beneficiarytype: item.beneficiarytype,
                  // taxidentifier: item.taxidentifier,
                  // line1: item.line1,
                  // line2: item.line2,
                  // line3: item.line3,
                  // city: item.city,
                  // country: item.country,
                  // stateprovince: item.stateprovince,
                  // postalcode: item.postalcode,
                  // county: item.county,
                  // telephone: item.telephone,
                  // dateOfTrust:(item.dateOfTrust === '1900-01-01T00:00:00' || item.dateOfTrust === '0001-01-01T00:00:00') ? '' : new Date(item.dateOfTrust),
                  // inPlan: item.inPlan,
                  type: item.type ? item.type : "Primary",
                  percentage: parseFloat(item.percentage),
                  startdate:
                    item.startDate === "1900-01-01T00:00:00" ||
                    item.startDate === "0001-01-01T00:00:00"
                      ? ""
                      : new Date(item.startDate),
                };
              }
            );
            setcopyBeneficiaries(initialcopybeneficiaries);
          }

          if (resultRateEstimationAttributes.length > 0) {
            setRateEstimationAttributes(resultRateEstimationAttributes);
          }
          setStepsInit();
        }
      );
    }
  }, [
    enrollmentPages,
    hasBeneficiaries,
    insuranceElectionID,
    insuranceID,
    insurancePlanTypeID,
    loggedinpersonid,
  ]);

  const lastStepIndex = steps.length - 1;
  const isLastStep = lastStepIndex === step;
  const isPreviousLastStep = steps.length - 2 === step;
  // const isPreviousStepsValid = steps
  //     .slice(0, step)
  //     .findIndex(currentStep => currentStep.isValid === false) === -1;

  const onStepSubmit = React.useCallback(
    async (event) => {
      const { isValid, values } = event;

      const currentSteps = steps.map((currentStep, index) => ({
        ...currentStep,
        isValid: index === step ? isValid : currentStep.isValid,
      }));

      setSteps(currentSteps);
      const reservedPercentage = 100;
      if (currentSteps[step].isValid) {
        if (
          currentSteps[step].label === PLANSTEP.dependents &&
          primaryInsuredParty === "Spouse"
        ) {
          if (
            dependents.some(
              (item) =>
                (item.relationship === "Child" ||
                item.relationship === "Domestic Partner Child" ||
                item.relationship === "Disabled Child") &&
                item.inPlan === true
            )
          ) {
            setDependentValidMsg(`A Spouse or Domestic Partner record is only allowed.`);
            currentSteps[step].isValid = false;
          } else if (
            dependents.some(
              (item) =>
                (item.relationship === "Spouse" ||
                  item.relationship === "Domestic Partner") &&
                item.inPlan === true
            )
          ) {
            setDependentValidMsg(``);
          } else {
            setDependentValidMsg(
              `A Spouse or Domestic Partner record is required.`
            );
            currentSteps[step].isValid = false;
          }
        }
        if (
          currentSteps[step].label === PLANSTEP.dependents &&
          primaryInsuredParty === "Children"
        ) {
          if (
            dependents.some(
              (item) =>
                (item.relationship === "Spouse" ||
                item.relationship === "Domestic Partner") &&
                item.inPlan === true
            )
          ) {
            setDependentValidMsg(`A Child or Domestic Partner Child or Disabled Child record is only allowed.`);
            currentSteps[step].isValid = false;
          } else if (
            dependents.some(
              (item) =>
                (item.relationship === "Child" ||
                  item.relationship === "Domestic Partner Child" ||
                  item.relationship === "Disabled Child") &&
                item.inPlan === true
            )
          ) {
            setDependentValidMsg(``);
          } else {
            setDependentValidMsg(
              `A Child or Domestic Partner Child or Disabled Child record is required.`
            );
            currentSteps[step].isValid = false;
          }
        }

        if (currentSteps[step].label === PLANSTEP.coverage) {
          let validCoverage = true;
          for (let coverage of coverageData) {
            if (
              coverage.type === `Money` &&
              coverage.allowedValues !== undefined &&
              coverage.allowedValues !== null
            ) {
              validCoverage = coverage.allowedValues
                .split("|")
                .map((x) => parseInt(x))
                .includes(values[coverage.name]);
              if (validCoverage === false) {
                setCoverageamountValidMsg(
                  `Please enter a valid value for ${coverage.label}`
                );
                currentSteps[step].isValid = false;
              }
            }
          }

          if (validCoverage) {
            setCoverageamountValidMsg(``);
            if (
              currentSteps[step].label === PLANSTEP.coverage &&
              hasBeneficiaries === true
            ) {
              //check count of beneficiary array }- atleast one primary
              //if count is greater than 0 then check percentage up to 100 for primary
              //if count is greater than 0 then check percentage up to 100 for secondary
              //return;
              const filteredBeneficiary = beneficiaries.filter(
                (item) => item.inPlan === true
              );
              if (filteredBeneficiary.length > 0) {
                const primaryBeneficiary = filteredBeneficiary.filter(
                  (item) => item.type === "Primary"
                );
                if (primaryBeneficiary.length > 0) {
                  const sumPercent = primaryBeneficiary.reduce(
                    (totalsum, item) => totalsum + item.percentage,
                    0
                  );
                  if (sumPercent === reservedPercentage) {
                    const secondaryBeneficiary = filteredBeneficiary.filter(
                      (item) => item.type === "Secondary"
                    );
                    if (secondaryBeneficiary.length > 0) {
                      const sumPercent = secondaryBeneficiary.reduce(
                        (totalsum, item) => totalsum + item.percentage,
                        0
                      );
                      if (sumPercent === reservedPercentage) {
                        currentSteps[step].isValid = true;
                        setBenificaryValidMsg(``);
                      } else {
                        currentSteps[step].isValid = false;
                        setBenificaryValidMsg(
                          `Total Secondary Beneficiaries Percentage must be 100`
                        );
                      }
                      //sumPercent < 100 || sumPercent > 100 ? currentSteps[step].isValid=false : currentSteps[step].isValid=true;
                    } else {
                      currentSteps[step].isValid = true;
                      setBenificaryValidMsg(``);
                    }
                  } else {
                    currentSteps[step].isValid = false;
                    setBenificaryValidMsg(
                      `Total Primary Beneficiaries Percentage must be 100`
                    );
                  }
                  //sumPercent < 100 || sumPercent > 100 ? currentSteps[step].isValid=false : currentSteps[step].isValid=true;
                } else {
                  setBenificaryValidMsg(
                    `At least one Primary Beneficiary required`
                  );
                  currentSteps[step].isValid = false;
                }
                //  Grid Percentage field Validation
                //  Commented as no longer required
                if (currentSteps[step].isValid) {
                  const validBeneficiaryPercentage = filteredBeneficiary.filter(
                    (item) => item.percentage <= 0 || item.percentage > 100
                  );

                  if (validBeneficiaryPercentage.length > 0) {
                    setBenificaryValidMsg(
                      `The percentage should be greater than 0 or less than and equal to 100.`
                    );
                    currentSteps[step].isValid = false;
                  }
                }
                //Add Validation
                if (currentSteps[step].isValid) {
                  let relationship;
                  if (category === "Life" && primaryInsuredParty === "Member")
                    relationship = "Self";
                  else if (
                    category === "Life" &&
                    primaryInsuredParty === "Spouse"
                  )
                    relationship = "Spouse";
                  if (relationship !== "") {
                    const validBeneficiary = filteredBeneficiary.filter(
                      (item) => item.relationship === relationship
                    );
                    if (validBeneficiary.length > 0) {
                      setBenificaryValidMsg(
                        `Cannot assign a '${relationship}' beneficiary on a ${primaryInsuredParty} life plan`
                      );
                      currentSteps[step].isValid = false;
                    }
                  }
                }
              } else {
                setBenificaryValidMsg(
                  `At least one Primary Beneficiary required`
                );
                currentSteps[step].isValid = false;
              }
            }
          }
        }
      }
      if (!currentSteps[step].isValid) {
        return;
      }

      if (isPreviousLastStep) {
        let affirmationAcknowledged = false;
        if (enrollmentPages.includes("affirmation")) {
          affirmationAcknowledged = formState.terms;
        }

        let enrollmentQuestions = [];
        if (enrollmentPages.includes("questions")) {
          for (const property in formState.questions) {
            const tempQuestion = {
              QuestionID: parseInt(property),
              Answer: formState.questions[property],
            };
            enrollmentQuestions.push(tempQuestion);
          }
        }

        let dependentIDs = [];
        if (enrollmentPages.includes("dependents")) {
          const inPlanDependents = dependents.filter(
            (item) => item.inPlan === true
          );
          inPlanDependents.forEach(function (inPlanDependent) {
            dependentIDs.push(inPlanDependent.id);
          });
        }

        let savebeneficiaries = [];
        if (enrollmentPages.includes("coverage") && hasBeneficiaries === true) {
          savebeneficiaries = beneficiaries
            .filter((item) => item.inPlan === true)
            .map(function (item) {
              return {
                BeneficiaryID: item.id,
                Type: item.type,
                Percentage: parseInt(item.percentage),
              };
            });
        }

        let attributes = {};
        if (enrollmentPages.includes("coverage")) {
          coverageData.forEach(function (coverage) {
            attributes = {
              ...attributes,
              [coverage.name]: formState[coverage.name].toString(),
            };
          });
          Object.assign(attributes, {insuranceID: (insuranceID).toString(), insuranceElectionID: (insuranceElectionID).toString(), insuranceDraftElectionID: (insuranceDraftElectionID).toString()});
        }

        let saveElection = {
          electionType: parseInt(insuranceID) > 0 ? `Update` : `New`,
          insuranceID: parseInt(insuranceID),
          insurancePlanTypeID: parseInt(insurancePlanTypeID),
          affirmationAcknowledged: affirmationAcknowledged,
          enrollmentQuestions: JSON.stringify(enrollmentQuestions),
          dependentIDs: dependentIDs,
          beneficiaries: JSON.stringify(savebeneficiaries),
          attributes: JSON.stringify(attributes),
          insuranceElectionID: parseInt(insuranceElectionID),
          isPreviousLastStep: 1,
          insuranceDraftElectionID: parseInt(insuranceDraftElectionID)
        };

        try {
          const saveresponse = await PostSave(
            `${urlEnrollmentSave}`,
            `POST`,
            saveElection
          );
          setSaveError(null);
          if (saveresponse.ok) {
            const electionresult = await saveresponse.json();
            setinsuranceDraftElectionID(electionresult.InsuranceElectionID);
            if (
              electionresult.Message !== "NotCreated" &&
              electionresult.Message !== "Cancelled" &&
              electionresult.Message !== "Success"
            ) {
              setElectionMsg(electionresult.Message);
              setSuccess(true);
              setError(false);
              currentSteps[step].isValid = false;
            } else currentSteps[step].isValid = true;
          } else {
            throw saveresponse;
          }
        } catch (e) {
          setSaveError(e.statusText);
          setStatus(STATUS.ERROR);
          setSuccess(false);
          setError(true);
        }
      }
      setStep(() => Math.min(step + 1, lastStepIndex));
      setFormState(values);
      if (isLastStep) {
        setStatus(STATUS.SUBMITTING);
        let affirmationAcknowledged = false;
        if (enrollmentPages.includes("affirmation")) {
          affirmationAcknowledged = formState.terms;
        }

        let enrollmentQuestions = [];
        if (enrollmentPages.includes("questions")) {
          for (const property in formState.questions) {
            const tempQuestion = {
              QuestionID: parseInt(property),
              Answer: formState.questions[property],
            };
            enrollmentQuestions.push(tempQuestion);
          }
        }

        let dependentIDs = [];
        if (enrollmentPages.includes("dependents")) {
          const inPlanDependents = dependents.filter(
            (item) => item.inPlan === true
          );
          inPlanDependents.forEach(function (inPlanDependent) {
            dependentIDs.push(inPlanDependent.id);
          });
        }

        let savebeneficiaries = [];
        if (enrollmentPages.includes("coverage") && hasBeneficiaries === true) {
          savebeneficiaries = beneficiaries
            .filter((item) => item.inPlan === true)
            .map(function (item) {
              return {
                BeneficiaryID: item.id,
                Type: item.type,
                Percentage: parseInt(item.percentage),
              };
            });
        }

        let attributes = {};
        if (enrollmentPages.includes("coverage")) {
          coverageData.forEach(function (coverage) {
            attributes = {
              ...attributes,
              [coverage.name]: formState[coverage.name].toString(),
            };
          });
          Object.assign(attributes, {insuranceID: (insuranceID).toString(), insuranceElectionID: (insuranceElectionID).toString(), insuranceDraftElectionID: (insuranceDraftElectionID).toString()});
        }

        let saveElection = {
          electionType: parseInt(insuranceID) > 0 ? `Update` : `New`,
          insuranceID: parseInt(insuranceID),
          insurancePlanTypeID: parseInt(insurancePlanTypeID),
          affirmationAcknowledged: affirmationAcknowledged,
          enrollmentQuestions: JSON.stringify(enrollmentQuestions),
          dependentIDs: dependentIDs,
          beneficiaries: JSON.stringify(savebeneficiaries),
          attributes: JSON.stringify(attributes),
          insuranceElectionID: parseInt(insuranceElectionID),
          isPreviousLastStep: 0,
          insuranceDraftElectionID: parseInt(insuranceDraftElectionID),
          bypassElection: false
        };

        try {
          const saveresponse = await PostSave(
            `${urlEnrollmentSave}`,
            `POST`,
            saveElection
          );

          setSaveError(null);
          if (saveresponse.ok) {
            const electionresult = await saveresponse.json();
            setinsuranceDraftElectionID(electionresult.InsuranceElectionID);
            setElectionMsg(electionresult.Message);
            setSuccess(true);
            setError(false);
            setStatus(STATUS.COMPLETED);
          } else {
            throw saveresponse;
          }
        } catch (e) {
          setSaveError(e.statusText);
          setStatus(STATUS.ERROR);
          setSuccess(false);
          setError(true);
        }
      }
    },
    [
      beneficiaries,
      category,
      coverageData,
      dependents,
      enrollmentPages,
      formState,
      hasBeneficiaries,
      insuranceDraftElectionID,
      insuranceID,
      insurancePlanTypeID,
      isLastStep,
      isPreviousLastStep,
      lastStepIndex,
      primaryInsuredParty,
      step,
      steps,
      insuranceElectionID,
    ]
  );

  const onPrevClick = React.useCallback(
    (event) => {
      event.preventDefault();
      setStep(() => Math.max(step - 1, 0));
    },
    [step, setStep]
  );

  const stepComponent = (formProps) => {
    let component = null;
    switch (steps[step].label) {
      case PLANSTEP.questions:
        component = (
          <Questions
            formProps={formProps}
            data={questions}
            labelValue={PLANSTEP.questions}
          />
        );
        break;
      case PLANSTEP.coverage:
        component = (
          <DetermineCoverage
            formProps={formProps}
            labelValue={PLANSTEP.coverage}
            card={insurancePlanTypeDescription}
            beneficiaries={beneficiaries}
            setBeneficiaries={setBeneficiaries}
            benificaryValidMsg={benificaryValidMsg}
            hasBeneficiaries={hasBeneficiaries}
            coverageData={coverageData}
            setCoverageData={setCoverageData}
            coverageamountValidMsg={coverageamountValidMsg}
            category={category}
            primaryInsuredParty={primaryInsuredParty}
            copybeneficiaries={copybeneficiaries}
            rateEstimationAttributes={rateEstimationAttributes}
            plan={props.location.state.plan}
            electionDates={electionDates}
          />
        );
        break;
      case PLANSTEP.affirmation:
        component = (
          <Affirmation
            formProps={formProps}
            labelValue={PLANSTEP.affirmation}
            plan={props.location.state.plan}
          />
        );
        break;
      case PLANSTEP.review:
        component = (
          <PlanCost
            formProps={formProps}
            plan={props.location.state.plan}
            labelValue={PLANSTEP.review}
            coverageData={coverageData}
            insuranceDraftElectionID={insuranceDraftElectionID}
            electionDates={electionDates}
          />
        );
        break;
      case PLANSTEP.dependents:
        component = (
          <FamilyReview
            formProps={formProps}
            dependents={dependents}
            setDependents={setDependents}
            labelValue={PLANSTEP.dependents}
            dependentValidMsg={dependentValidMsg}
            primaryInsuredParty={primaryInsuredParty}
          />
        );
        break;
      // no default
    }
    return component;
  };

  function handleCloseDialog() {
    history.push(redirectHome());
  }

  if (enrollmentPages === undefined || enrollmentPages === "") {
    return <Redirect to={redirectHome} />;
  }

  if (steps.length === 0 || enrollmentPagecount !== steps.length) {
    return <Spinner></Spinner>;
  } else {
    return (
      <div className="page-wrapper">
        {status === STATUS.SUBMITTING && <Spinner />}

        {(success || error) && (
          <Dialog onClose={handleCloseDialog}>
            {success && electionMsg === "Success" && (
              <Notification
                type={{ style: "success", icon: true }}
                closable={false}
              >
                {SuccessSaveMessage}
              </Notification>
            )}
            {success &&
              electionMsg !== "Success" &&
              (electionMsg === "NotCreated" || electionMsg === "Cancelled") && (
                <Notification
                  type={{ style: "info", icon: true }}
                  closable={false}
                >
                  The information you submitted is identical to your current
                  coverage details. No changes will be made.
                </Notification>
              )}
            {success &&
              electionMsg !== "NotCreated" &&
              electionMsg !== "Cancelled" &&
              electionMsg !== "Success" && (
                <Notification
                  type={{ style: "error", icon: true }}
                  closable={false}
                >
                  Validation Failed - {electionMsg}.
                </Notification>
              )}
            {error && (
              <Notification
                type={{ style: "error", icon: true }}
                closable={false}
              >
                {errorSaveMessage}
                {saveError}
              </Notification>
            )}

            <DialogActionsBar>
              <button className="k-button" onClick={handleCloseDialog}>
                OK
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        <div className="heading-page">
          <p className="heading-form">
            {props.location.state?.plan?.insurancePlanTypeDescription}
          </p>
          <Stepper value={step} items={steps} />
          <Form
            initialValues={formState}
            onSubmitClick={onStepSubmit}
            render={(formRenderProps) => (
              <div style={{ alignSelf: "center" }}>
                <FormElement>
                  {stepComponent(formRenderProps)}
                  <span
                    style={{ marginTop: "40px" }}
                    className={"k-form-separator"}
                  />
                  <div
                    style={{
                      justifyContent: "space-between",
                      alignContent: "center",
                    }}
                    className={"k-form-buttons k-buttons-end"}
                  >
                    <span style={{ alignSelf: "center" }}>
                      Step {step + 1} of {steps.length}
                    </span>
                    <div>
                    <Button
                          style={{ marginRight: "16px" }}
                          onClick={onHome}
                        >
                          Cancel
                        </Button>
                      {step !== 0 ? (
                        <Button
                          style={{ marginRight: "16px" }}
                          onClick={onPrevClick}
                        >
                          Previous
                        </Button>
                      ) : undefined}
                      <Button
                        primary={true}
                        //  disabled={!formRenderProps.allowSubmit}
                        onClick={formRenderProps.onSubmit}
                      >
                        {isLastStep ? "Save My Election" : "Next"}
                      </Button>
                    </div>
                  </div>
                </FormElement>
              </div>
            )}
          />
        </div>
      </div>
    );
  }
}
