import React, { Component, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import "./../styles/App.css";
//import Home from "./Home";
import Nav from "./Nav";
import PrivateRoute from "./PrivateRoute";
import Login from "./Login";
import PersonalInformation from "./PersonalInformation/PersonalInformation";
import ReviewBeneficiaries from "./ReviewBeneficiaries/ReviewBeneficiaries";
import ReviewDependents from "./ReviewDependents/ReviewDependents";
import ReviewElection from "./ReviewElection/ReviewElection";
import Confirmation from "./Confirmation/Confirmation";
import { urlUserInfo } from "../services/serviceURLs";
import PlanWizard from "./PlanWizard/PlanWizard";
import CurrentCoverage from "./CurrentCoverage/CurrentCoverage";
import { AuthContext } from "./AuthContext";
import  EOIRequest  from "./Notification/EOIRequest";
import  Help  from "./Help/Help";
import  Resources  from "./Resources/Resources";
import  FDXEOIRequest  from "./Notification/FDXEOIRequest";
import Logout from "./Logout";
import Restricted from "./Restricted";
import Spinner from "./Spinner";
//const Home = React.lazy(() =>  import("./Home"));
const Home = React.lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Home")), 5000)
    )
);
class App extends Component {
  static contextType = AuthContext;
  componentDidMount() {
    const requestOptions = {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    };

    fetch(urlUserInfo, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.result !== "ok") {
          this.context.clearStorage();
          if(process.env.REACT_APP_ENV==="PROD")
          this.context.login();
        } else {
          this.context.addUserHandler(result.userInfo);
        }
      });
  }

  render() {
    return (
      <>
        <Nav />
        <Suspense fallback={<Spinner />}>
        <Switch>
          <Route
            path="/"
            exact
            render={(props) => <Home {...props} key={Date.now()} />}
          />
          <Route path="/Login" exact render={(props) => <Login {...props} />} />
          <PrivateRoute path="/personalinfo" component={PersonalInformation} />
          <PrivateRoute path="/beneficiaries" component={ReviewBeneficiaries} />
          <PrivateRoute path="/dependents" component={ReviewDependents} />
          <PrivateRoute path="/plan" component={PlanWizard} />
          <PrivateRoute path="/confirmation" component={Confirmation} />
          <PrivateRoute path="/coverage" component={CurrentCoverage} />
          <PrivateRoute path="/reviewelection" component={ReviewElection} />
          <PrivateRoute path="/eoirequest" component={EOIRequest} />
          <PrivateRoute path="/help" component={Help} />
          <PrivateRoute path="/resources" component={Resources} />
          <PrivateRoute path="/fdxeoirequest" component={FDXEOIRequest} />
          <Route path="/logout" component={Logout} />
          <Route path="/Restricted" component={Restricted} />
        </Switch>
        </Suspense>
      </>
    );
  }
}

export default App;
