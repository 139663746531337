import React, { useState } from "react";
import { Label } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import CoverageRateEstimater from "./CoverageRateEstimater";
import { CoverageInput } from "./CoverageInput";

export const CoverageRateSection = (props) => {
  const [openRateEstimaterForm, setOpenRateEstimatorForm] = useState(false);

  function handleClick(event) {
    event.preventDefault();
    setOpenRateEstimatorForm(true);
  }
  const toggleDialog = () => {
    setOpenRateEstimatorForm(!openRateEstimaterForm);
  };

  return (
    <div>
      <fieldset className={"k-form-fieldset"}>
        <p className="StepName-Mbl visible-Mb">{props.labelValue}</p>
        <div className="combine-form">
          <div className="mb-2 coverageInput">
            {props.coverageData.map((coverage, i) => (
              <CoverageInput
                key={i}
                coverage={coverage}
                plan={props.plan}
              ></CoverageInput>
            ))}
          </div>

          <div className="mb-3 rate-estimator">
            <Label>Rate Estimator</Label>
            <Button className="btnLink" primary={true} onClick={handleClick}>
              Click to see your rates
            </Button>
            {openRateEstimaterForm && (
              <CoverageRateEstimater
                plan={props.plan}
                rateEstimationAttributes={props.rateEstimationAttributes}
                onClose={toggleDialog}
                onSubmit={toggleDialog}
                electionDates={props.electionDates}
              />
            )}
          </div>
        </div>

        <div className="mb-3">
          <span className="amount-error">{props.coverageamountValidMsg}</span>
        </div>
      </fieldset>
    </div>
  );
};
