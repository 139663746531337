import React from "react";
import { QuestionIndividual } from "./QuestionIndividual";
import { Notification } from "@progress/kendo-react-notification";

export const Questions = (props) => {
  return (
    <>
      <p className="StepName-Mbl visible-Mb">{props.labelValue}</p>

      {props.showInfo && (
        <Notification type={{ style: "info", icon: true }} closable={false}>
          {" "}
          Information below would be required to determine the Insurance
          eligibility and rates
        </Notification>
      )}
      <div className="questions-wrapper">
        {props.data.map((question, questionID) => (
          <QuestionIndividual
            key={question.questionID}
            question={question}
          ></QuestionIndividual>
        ))}
      </div>
    </>
  );
};

Questions.defaultProps = {
  showInfo: true,
};
