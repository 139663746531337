import React from "react";
import { CardSubtitle } from "@progress/kendo-react-layout";
import { Tooltip } from "@progress/kendo-react-tooltip";
import CustomToolTip from "../CurrentCoverage/CustomToolTip";
import { currencyFormat } from "../../services/shareconstant";
import CustomEOIToolTip from "../CurrentCoverage/CustomEOIToolTip";
export default function ReviewCard(props) {
  return (
    <>
      {props.current ? (
        <div className="wrapper-Block">
          <div className="planDetailsBlock">
            {props.card.currentDetails.summaryAttributes &&
              Object.keys(props.card.currentDetails.summaryAttributes).map(
                (key, i) => (
                  <p key={i}>
                   {(props.card.status==='EOI Pending') && key==='EOI Amount' ?
                   <CardSubtitle className="label-viewdetails">
                   {" "}
                   {key}
                 </CardSubtitle>                
                  :((key!=='EOI Amount')? 
                  <CardSubtitle className="label-viewdetails">
                   {" "}
                   {key}
                 </CardSubtitle>  
                 :null)             
                }
                
                 {(props.card.status==='EOI Pending') && key==='EOI Amount' ?
                      currencyFormat(
                        parseInt(
                          props.card.currentDetails.summaryAttributes[key]
                        )
                      )               
                  :((key!=='EOI Amount')? 
                  <span>
                   {" "}                    
                   {isNaN(props.card.currentDetails.summaryAttributes[key])
                     ? props.card.currentDetails.summaryAttributes[key]
                     : currencyFormat(
                         parseInt(
                           props.card.currentDetails.summaryAttributes[key]
                         )
                       )}
                 </span>
                 :null)
                 }
                  </p>
                )
              )}
          </div>
          <div className="memberCostBlock">
            {props.card.discountOrPremiumWaiverEligible === true
              ? props.card.currentDetails &&
                Object.keys(props.card.currentDetails).map(
                  (key, i) =>
                    key !== "discountOrPremiumWaiverAttributes" &&
                    key !== "summaryAttributes" && (
                      <p key={i}>
                        {key === "memberCost" ? (
                          <>
                            <Tooltip
                              content={(prop) => (
                                <CustomToolTip
                                  key={i}
                                  data={
                                    props.card?.currentDetails
                                      ?.discountOrPremiumWaiverAttributes
                                  }
                                />
                              )}
                              anchorElement="target"
                              position="bottom"
                            >
                              <span className="label-viewdetails">
                                Member Monthly Cost{" "}
                              </span>
                              <span
                                className="k-icon k-i-information"
                                title="Member Monthly Cost"
                              ></span>
                            </Tooltip>
                          </>
                        ) : (
                          <CardSubtitle className="label-viewdetails">
                            {" "}                          
                          {key === "fullCost" && (props.card.status==='EOI Pending') ? <>
                          <Tooltip
                            content={(prop) => (
                              <CustomEOIToolTip                               
                              />
                            )}
                            anchorElement="target"
                            position="bottom"
                          >
                            <span className="label-viewdetails">
                              Full Monthly Cost{" "}
                            </span>
                            <span
                              className="k-icon k-i-information"
                              title="Full Monthly Cost"
                            ></span>
                          </Tooltip>
                        </> : (key === "fullCost"?"Full Monthly Cost":key)}
                          </CardSubtitle>
                        )}

                        <span>
                          {" "}
                          {isNaN(props.card.currentDetails[key])
                            ? props.card.currentDetails[key]
                            : props.card.currentDetails[key] === -1 ? 'TBD' : currencyFormat(
                                props.card.currentDetails[key]
                              )}{" "}
                        </span>
                      </p>
                    )
                )
              : props.card.currentDetails &&
                Object.keys(props.card.currentDetails).map(
                  (key, i) =>
                    key !== "discountOrPremiumWaiverAttributes" &&
                    key !== "summaryAttributes" &&
                    key !== "fullCost" && (
                      <p key={i}>
                        <CardSubtitle className="label-viewdetails">
                          {" "}
                          {key === "memberCost" ? "Member Monthly Cost" : key}{" "}
                        </CardSubtitle>

                        <span>
                          {" "}
                          {isNaN(props.card.currentDetails[key])
                            ? props.card.currentDetails[key]
                            : props.card.currentDetails[key] === -1 ? 'TBD' : currencyFormat(
                                props.card.currentDetails[key]
                              )}{" "}
                        </span>
                      </p>
                    )
                )}
          </div>
        </div>
      ) : (
        <div className="wrapper-Block">
          <div className="planDetailsBlock">
            {props.card.futureDetails.summaryAttributes &&
              Object.keys(props.card.futureDetails.summaryAttributes).map(
                (key, i) => (
                  <p key={i}>
                    <CardSubtitle className="label-viewdetails">
                      {" "}
                      {key}
                    </CardSubtitle>
                    <span className="value-viewDetails">
                      {" "}
                      {isNaN(props.card.futureDetails.summaryAttributes[key])
                        ? props.card.futureDetails.summaryAttributes[key]
                        : currencyFormat(
                            parseInt(
                              props.card.futureDetails.summaryAttributes[key]
                            )
                          )}
                    </span>
                  </p>
                )
              )}
          </div>
          <div className="memberCostBlock">
            {props.card.discountOrPremiumWaiverEligible === true
              ? props.card.futureDetails &&
                Object.keys(props.card.futureDetails).map(
                  (key, i) =>
                    key !== "discountOrPremiumWaiverAttributes" &&
                    key !== "summaryAttributes" &&
                    key !== "effectiveDate" && (
                      <p key={i}>
                        {key === "memberCost" ? (
                          <>
                            <Tooltip
                              content={(prop) => (
                                <CustomToolTip
                                  key={i}
                                  data={
                                    props.card?.futureDetails
                                      ?.discountOrPremiumWaiverAttributes
                                  }
                                />
                              )}
                              anchorElement="target"
                              position="bottom"
                            >
                              <span className="label-viewdetails">
                                Member Monthly Cost{" "}
                              </span>
                              <span
                                className="k-icon k-i-information"
                                title="Member Monthly Cost"
                              ></span>
                            </Tooltip>
                          </>
                        ) : (
                          <CardSubtitle className="label-viewdetails">
                            {" "}
                            {key === "fullCost" && (props.card.status==='EOI Pending') ? <>
                          <Tooltip
                            content={(prop) => (
                              <CustomEOIToolTip                               
                              />
                            )}
                            anchorElement="target"
                            position="bottom"
                          >
                            <span className="label-viewdetails">
                              Full Monthly Cost{" "}
                            </span>
                            <span
                              className="k-icon k-i-information"
                              title="Full Monthly Cost"
                            ></span>
                          </Tooltip>
                        </> : (key === "fullCost"?"Full Monthly Cost":key)}
                          </CardSubtitle>
                        )}
                        <span className="value-viewDetails">
                          {" "}
                          {isNaN(props.card.futureDetails[key])
                            ? props.card.futureDetails[key]
                            : props.card.futureDetails[key] === -1 ? 'TBD' : currencyFormat(
                                props.card.futureDetails[key]
                              )}{" "}
                        </span>
                      </p>
                    )
                )
              : props.card.futureDetails &&
                Object.keys(props.card.futureDetails).map(
                  (key, i) =>
                    key !== "discountOrPremiumWaiverAttributes" &&
                    key !== "summaryAttributes" &&
                    key !== "fullCost" &&
                    key !== "effectiveDate" && (
                      <p key={i}>
                        <CardSubtitle className="label-viewdetails">
                          {" "}
                          {key === "memberCost" ? "Member Monthly Cost" : key}{" "}
                        </CardSubtitle>
                        <span className="value-viewDetails">
                          {" "}
                          {isNaN(props.card.futureDetails[key])
                            ? props.card.futureDetails[key]
                            : props.card.futureDetails[key] === -1 ? 'TBD' : currencyFormat(
                                props.card.futureDetails[key]
                              )}{" "}
                        </span>
                      </p>
                    )
                )}
          </div>
        </div>
      )}
    </>
  );
}
