import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Menu, MenuItem } from "@progress/kendo-react-layout";
import { Badge } from "@progress/kendo-react-indicators";
import { Button } from "@progress/kendo-react-buttons";
import { AuthContext } from "./AuthContext";
import alpaball from '../styles/alpa-ball.svg'
class Nav extends Component {
  static contextType = AuthContext;

  handleNotificationClick() {
    this.props.history.push('/eoirequest');
  }

  cobrowseSupportCode() {
    return (
      // <Tooltip position="bottom" anchorElement="pointer">
      //   <span
      //     className="k-icon k-i-track-changes-accept k-icon-64"
      //     style={{ color: "white", fontSize: "24px" }}
      //     title="Generate a Support Code"
      //     onClick={window["showSessionCode"]}
      //   ></span>
      // </Tooltip>

      <div className="generate-code" title="Generate a Support Code">
        {" "}
        <span
          className="k-icon k-i-track-changes-accept k-icon-64"
          onClick={window["showSessionCode"]}
        ></span>
      </div>
    );
  }
  state = {
    isBoxVisible:false
  };
  toggleBox = () => {
    this.setState(prevState => ({ isBoxVisible: !prevState.isBoxVisible }));
  };
  render() {
const { isBoxVisible } = this.state; 
    if (Object.keys(this.context.state.userinfoglobal).length !== 0) {
      return (
        <div>
          <div className="navBarHeader">
            <div className="brand-name">
              <img
                className="logo-alpa"
                src={alpaball}
                alt="ALPA Logo"
              />{" "}
              <span className="brandName"> Air Line Pilots Association, Int’l</span>
            </div>
           
            <div className="action-button">
              <span>
                Welcome,{" "}
                <span className="userName">
                  {this.context.state.userinfoglobal.FirstName}{" "}
                  {this.context.state.userinfoglobal.LastName}{""}
                </span>{""}
                </span>
              <span className="logged-in-user">
                <span className="k-icon k-i-arrow-chevron-down" onClick={this.toggleBox}></span>
              <div className={`box ${isBoxVisible ? "" : "hidden"}`}>
                <Button primary={true} onClick={() => this.context.logout()}>
                Log Out
              </Button>
              </div>    
              </span>
              
            </div>
            
             {/* {renderNotificationIcon()} */}
             {this.context.state.CompanyAffilliatedCountry!=="CA"?(
             <div className="notification-icon">
           <span className="k-icon k-i-bell notifications" onClick={() => this.handleNotificationClick()}>
             <Badge shape="dot" themeColor="warning" />
           </span>
         </div>
         ):null}
          </div>
          {this.context.state.CompanyAffilliatedCountry!=="CA"?(
          <div className="subMenuWrapper">
            <nav>
              <label htmlFor="drop" className="toggle">
                Menu
              </label>
              <input type="checkbox" id="drop" />
              <Menu onSelect={this.onSelect} className="subMenu menu">
                <MenuItem text="Home" data={{ route: "/" }} />

                <MenuItem
                  text="Personal Information"
                  data={{ route: "/personalinfo" }}
                />
                <MenuItem
                  text="Review Beneficiaries"
                  data={{ route: "/beneficiaries" }}
                />
                <MenuItem
                  text="Review Dependents"
                  data={{ route: "/dependents" }}
                />
                <MenuItem
                  text="Resources"
                  data={{ route: "/resources" }}
                />
                <MenuItem
                  text="Help"
                  data={{ route: "/help" }}
                />
              </Menu>
            </nav>
          </div>
          ):
          <div className="subMenuWrapper">
          <nav>
            <label htmlFor="drop" className="toggle">
              Menu
            </label>
            <input type="checkbox" id="drop" />
            <Menu onSelect={this.onSelect} className="subMenu menu">
              <MenuItem text="Home" data={{ route: "/" }} />
            </Menu>
          </nav>
        </div>}
          <div style={{ padding: 5 }}>{this.props.children}</div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="navBarHeader">
            <div className="brand-name">
              {" "}
              <img
                className="logo-alpa"
                src={alpaball}                
                alt="ALPA-Logo"
              />{" "}
             Air Line Pilots Association, Int’l
            </div>
            <div className="action-button">
              <Button primary={false} onClick={() => this.context.login()}>
                Log In
              </Button>
            </div>
            
          </div>
          <Menu onSelect={this.onSelect}>
            <MenuItem text="Home" data={{ route: "/" }} />
          </Menu>
          <div style={{ padding: 10 }}>{this.props.children}</div>
        </div>
      );
    }
  }
  onSelect = (event) => {
    this.props.history.push(event.item.data.route);
  };
}

export default withRouter(Nav);
