import { useState, useRef, useEffect } from "react";

const requestOptions = {
  method: "GET",
  headers: { "Content-Type": "application/json" },
  credentials: "include",
  redirect: "follow",
};

export default function useFetch(url) {
  const isMounted = useRef(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    isMounted.current = true;
    async function init() {
      try {
        const response = await fetch(`${url}`, requestOptions);
        if (response.ok) {
          const json = await response.json();
          if (isMounted.current) setData(json);
        } else {
          throw response;
        }
      } catch (e) {
        if (isMounted.current) setError(e);
      } finally {
        if (isMounted.current) setLoading(false);
      }
    }
    init();

    return () => {
      isMounted.current = false;
    };
  }, [url]);

  return { data, error, loading };
}

export function Fetch({ url, children }) {
  const { data, loading, error } = useFetch(url);
  return children(data, loading, error);
}

export async function GetFetch(url) {
  return fetch(url, requestOptions);
}

export async function SaveFetch(url, methodType, data) {
  const requestOptions = {
    method: methodType,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    credentials: "include",
    body: data,
    redirect: "follow",
  };
  return fetch(url, requestOptions);
}
export async function PostFetch(url, formdata) {
  const requestOptions = {
    method: "POST",
    body: formdata,
    credentials: "include",
    redirect: "follow",
  };
  return fetch(url, requestOptions);
}

export async function PostSave(url, methodType, data) {
  const requestOptions = {
    method: methodType,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    credentials: "include",
    redirect: "follow",
  };
  return fetch(url, requestOptions);
}
