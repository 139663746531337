import React, { useState, useEffect} from "react";
import { Button } from "@progress/kendo-react-buttons";
import { useHistory, Redirect } from "react-router-dom";
import ReviewUpdateElectionsForFuturePlans from "./ReviewUpdateElectionsForFuturePlans";
import ReviewUpdateElectionsForNonFuturePlans from "./ReviewUpdateElectionsForNonFuturePlans";
import ReviewNewElections from "./ReviewNewElections";
import ReviewCancellations from "./ReviewCancellations";
import ReviewTerminations from "./ReviewTerminations";
import ReviewReinstatements from "./ReviewReinstatements";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import Spinner from "../Spinner";
import { STATUS } from "../../model/constants";
import { GetFetch } from "../../services/useFetch";
import { urlFinalizeElections , urlPersonInsuranceEOIRequests} from "../../services/serviceURLs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Notification } from "@progress/kendo-react-notification";
//import EOIDetails from "./EOIDetails";
import {
  redirectHome,
  errorSaveMessage,
  SuccessSaveMessage,
} from "../../services/shareconstant";

export default function ReviewElection(props) {
  const [status, setStatus] = useState(STATUS.IDLE);
  const [saveError, setSaveError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [confirmationData, setConfirmationData] = useState(null);
  const [openConfirmationForm, setOpenConfirmationForm] = useState(false);
  const [eoiRequest, seteoiRequest] = useState([]);
  
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    };
    try {
      fetch(
        `${urlPersonInsuranceEOIRequests}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          seteoiRequest(result);
        });
      } catch (e) {
        setSaveError(e.statusText);
        setError(true);
      }
},[]
);

  const channgeData =
    props.location.state?.pendingUpdateElectionsForFuturePlans.length +
    props.location.state?.pendingUpdateElectionsForNonFuturePlans.length;
  const terminateData =
    props.location.state?.pendingTerminations.length +
    props.location.state?.pendingCancellations.length;
  const disableReviewElection = props.location.state?.electionData.some(
    (item) => item.Type === `Error`
  );
  const waringExist = props.location.state?.electionData.some(
    (item) => item.Type === `Warning`
  );

  const history = useHistory();

  const onBack = () => {
    history.push(redirectHome());
  };

  const onSubmitConfirm = () => {
    toggleConfirmationDialog();
    finalizeElections();
  };

  const finalizeElections = async () => {
    setStatus(STATUS.SUBMITTING);
    try {
      const saveresponse = await GetFetch(`${urlFinalizeElections}`);
      setSaveError(null);
      if (saveresponse.ok) {
        const output = await saveresponse.json();
        setConfirmationData(output);
        setError(false);
        setStatus(STATUS.COMPLETED);
        setSuccess(true);
      } else {
        throw saveresponse;
      }
    } catch (e) {
      setSaveError(e.statusText);
      setStatus(STATUS.ERROR);
      setSuccess(false);
      setError(true);
    }
  };

  const onElectionDone = () => {
    if (disableReviewElection) return false;

    if (waringExist) {
      toggleConfirmationDialog();
    } else {
      finalizeElections();
    }
  };

  const toggleConfirmationDialog = () => {
    setOpenConfirmationForm(!openConfirmationForm);
  };

  function handleCloseDialog() {
    history.push({
      pathname: `/confirmation`,
      state: {
        data: confirmationData,
        electionDates: props.location.state?.electionDates,
      },
    });
  }

  const submitButton = (
    <>
      {" "}
      <span className="topActionButtons">
        <Button primary={false} onClick={onBack}>
          {" "}
          Back
        </Button>{" "}
        &nbsp; &nbsp;
        <Button
          primary={true}
          onClick={onElectionDone}
          disabled={disableReviewElection}
        >
          Submit My Elections
        </Button>
      </span>
    </>
  );

  if (props.location.state) {
    return (
      <>
        {status === STATUS.SUBMITTING && <Spinner />}

        {(success || error) && (
          <Dialog onClose={handleCloseDialog}>
            {success && (
              <Notification
                type={{ style: "success", icon: true }}
                closable={false}
              >
                {SuccessSaveMessage}
              </Notification>
            )}
            {error && (
              <Notification
                type={{ style: "error", icon: true }}
                closable={false}
              >
                {errorSaveMessage}. {saveError}
              </Notification>
            )}
            <DialogActionsBar>
              <button className="k-button" onClick={handleCloseDialog}>
                OK
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {openConfirmationForm && (
          <Dialog title={"Confirm"} onClose={toggleConfirmationDialog}>
            <Notification
              type={{ style: "warning", icon: true }}
              closable={false}
            >
              There are potentially unresolved issues with your elections. Are
              you sure you want to submit?
            </Notification>
            <DialogActionsBar>
              <Button primary={true} onClick={onSubmitConfirm}>
                Yes
              </Button>
              <Button primary={false} onClick={toggleConfirmationDialog}>
                No
              </Button>
            </DialogActionsBar>
          </Dialog>
        )}

        <div className="completeSelection">
          <div className="heading-form">Review Elections</div>
          <Notification type={{ style: "info", icon: true }} closable={false}>
            {" "}
            <p>
              Take a moment to review all your benefits to ensure they are correct.{" "}
            </p>{" "}
            Edit the benefit that you wish to change, or if no further edits
            are required, click "Submit My Elections".
            <p>
              If your Elections require evidence of insurability, you will receive a
              link after submitting. The link will also be available on the portal Homepage.
            </p>
          </Notification>
          <div className="heading-form clearFloats">
            Your Benefit Selections
            {submitButton}
          </div>

          <PanelBar className="zIndexFix">
            {props.location.state?.electionData.length !== 0 && (
              <PanelBarItem expanded={true} title="ERROR/WARNING">
                {props.location.state.electionData.map((item, index) => {
                  return (
                    <Notification
                      type={{
                        style: item.Type === `Error` ? `error` : `warning`,
                        icon: true,
                      }}
                      closable={false}
                    >
                      <span>{item.Message}</span>
                    </Notification>
                  );
                })}
              </PanelBarItem>
            )}

            {props.location.state?.pendingNewElections.length !== 0 && (
              <PanelBarItem expanded={true} title="NEW ELECTIONS">
                <ReviewNewElections
                  electionData={props.location.state?.pendingNewElections}
                />
              </PanelBarItem>
            )}
            {channgeData > 0 && (
              <PanelBarItem expanded={true} title="CHANGES">
                {props.location.state?.pendingUpdateElectionsForFuturePlans
                  .length !== 0 && (
                  <ReviewUpdateElectionsForFuturePlans
                    electionData={
                      props.location.state?.pendingUpdateElectionsForFuturePlans
                    }
                  />
                )}
                {props.location.state
                  ?.pendingUpdateElectionsForNonFuturePlans !== 0 && (
                  <ReviewUpdateElectionsForNonFuturePlans
                    electionData={
                      props.location.state
                        ?.pendingUpdateElectionsForNonFuturePlans
                    }
                  />
                )}
              </PanelBarItem>
            )}
            {terminateData > 0 && (
              <PanelBarItem expanded={true} title="TERMINATIONS">
                {props.location.state?.pendingTerminations.length !== 0 && (
                  <ReviewTerminations
                    electionData={props.location.state?.pendingTerminations}
                  />
                )}
                {props.location.state?.pendingCancellations.length !== 0 && (
                  <ReviewCancellations
                    electionData={props.location.state?.pendingCancellations}
                  />
                )}
              </PanelBarItem>
            )}
            {props.location.state?.pendingReinstatements.length !== 0 && (
              <PanelBarItem expanded={true} title="REINSTATEMENTS">
                <ReviewReinstatements
                  electionData={props.location.state?.pendingReinstatements}
                />
              </PanelBarItem>
            )}
          </PanelBar>
          {/* <div>
          <Notification type={{ style: "info", icon: true }} closable={false}>
            Additional verification is required for the following election(s).
            Coverage amount will be determined once you have been approved.
          </Notification>
          <EOIDetails data={eoiRequest} />
          <br />
          <br />
          <Notification type={{ style: "info", icon: true }} closable={false}>
            {" "}
            This is summary of your benefit elections. It does not take the place of
            or supersede official plan requirements including evidence of
            insurability(EOI) completion. Any coverage elections you make
            requiring EOI approval from an insurance carrier will not be
            effective until the insurance carrier provides confirmation of
            approval regardless of what may be displayed on this summary. In the
            event of discrepancy between the information within this summary and
            official plan documents, the plan documents will prevail.
          </Notification>
          </div> */}
          {/* <div>
            <Notification type={{ style: "info", icon: true }} closable={false}>
              {" "}
              <p>Your Benefit Selections Pending Approval</p>
              <p>
                Additional verification is required for the following
                election(s). Coverage amount will be determined once you have
                been approved.
              </p>
            </Notification>
          </div>  */}
          <div className="action-buttons">{submitButton}</div>
        </div>
      </>
    );
  }
  return <Redirect to={redirectHome} />;
}
