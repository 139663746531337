import * as React from "react";
import { Field } from "@progress/kendo-react-form";
import { requiredValidator } from "../Common/validators";
import { FormComboBox, FormNumericTextBox } from "../Common/form-components";

import { apCoverageAmountDisablePlan } from "../../model/constants";
import { CoverageAmountDisablePlanML10Or20 } from "../../model/constants";

export const CoverageInput = (props) => {
  const amountdisabled =    
    ((props.plan.nonContributoryOptIn === true &&
      JSON.parse(localStorage.getItem("isAP")) === true && apCoverageAmountDisablePlan.find(
      (str) => str === props.plan.insurancePlanTypeName
    )) ||
    (CoverageAmountDisablePlanML10Or20.find(
      (str) => str === props.plan.insurancePlanTypeName
    ) && props.plan.insuranceID >0 ));

  return (
    <>
      {props.coverage.type === "Money" && (
        <>
          <Field
            id={props.coverage.name}
            name={props.coverage.name}
            label={props.coverage.label}
            component={FormNumericTextBox}
            validator={requiredValidator}
            step={props.coverage.increment}
            format="C0"
            disabled={amountdisabled}
            min={0}
          />
          <div>
            <span className="help-Text">{props.coverage.helpText}</span>
          </div>
          <div>
            <span className="help-Text">{props.coverage.helpText2}</span>
          </div>
        </>
      )}

      {props.coverage.type === "Dropdown" && (
        <>
          <Field
            label={props.coverage.label}
            key={props.coverage.name}
            id={props.coverage.name}
            name={props.coverage.name}
            data={props.coverage.allowedValues.split("|")}
            validator={requiredValidator}
            component={FormComboBox}
            suggest={true}
            placeholder="Select One"
          />
        </>
      )}
    </>
  );
};
