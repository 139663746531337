import React from "react";
import HistoricalShowDetails from "./HistoricalShowDetails";

export default function HistoricalCoverageFrom(props) {
  return (
    <>
      {props.data.map((card) => {
        return (
          <HistoricalShowDetails
            key={card.insuranceID}
            card={card}
            isAP={props.isAP}
          />
        );
      })}
    </>
  );
}
