import React from "react";
import { useHistory } from "react-router-dom";
import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import { Notification } from "@progress/kendo-react-notification";
import { redirectHome } from "../../services/shareconstant";

export default function PersonalInformationForm(props) {
  const history = useHistory();
  const gotoHome = () => {
    history.push(redirectHome());
  };

  return (
    <div className="page-wrapper">
      <div className="heading-form">My Information</div>
    <div className="panelbar-wrapper">
      <form className="k-form">        
        <fieldset className="info-box">
          <Notification type={{ style: "info", icon: true }} closable={false}>
            {" "}
            For account updates, please sign into <a target="_blank" href="https:\\www.alpa.org/memberaccount">Member Account</a>.
          </Notification>

          <div className="combine-form">
            <div className="mb-3 k-form-field">
              <Label editorId={props.data.alpaId}>ALPA ID</Label>
              <Input name="alpaId" value={props.data.alpaId} readonly="true" />
            </div> 
            <div className="mb-3 k-form-field">
              <Label editorId={props.data.AirName}>Airline</Label>
              <Input name="alpaId" value={props.data.AirName} readonly="true" />
            </div> 
            <div className="mb-3 k-form-field">
              <Label editorId={props.data.PilotStatus}>Pilot Status</Label>
              <Input name="alpaId" value={props.data.PilotStatus} readonly="true" />
            </div>            
          </div>
          <div className="combine-form">
            <div className="mb-3 k-form-field">
              <Label editorId={props.data.firstName}>First Name</Label>
              <Input
                name="firstName"
                value={props.data.firstName}
                readonly="true"
              />
            </div>
            <div className="mb-3 k-form-field">
              <Label editorId={props.data.middleName}>Middle Name</Label>
              <Input
                name="middleName"
                value={props.data.middleName}
                readonly="true"
              />
            </div>
            <div className="mb-3 k-form-field">
              <Label editorId={props.data.lastName}>Last Name</Label>
              <Input
                name="lastName"
                value={props.data.lastName}
                readonly="true"
              />
            </div>
          </div>
          <div className="combine-form">
            <div className="mb-3 k-form-field">
              <Label editorId={props.data.DOB}>DOB</Label>
              <Input name="email" value={props.data.DOB} readonly="true" /> 
              {/* {formatDate(
                  parseDate(props.data.DOB, [
                    "yyyy/MM/dd",
                    "yyyy-MM-ddTHH:mm:ss",
                  ]),
                  "MM/dd/yyyy"
                )} */}
            </div>
            <div className="mb-3 k-form-field">
              <Label editorId={props.data.sex}>Gender</Label>
              <Input name="sex" value={props.data.sex} readonly="true" />
            </div>
            <div className="mb-3 k-form-field">
              <Label editorId={props.data.homePhone}>Home Telephone</Label>
              <Input name="sex" value={props.data.homePhone} readonly="true" />
            </div>
          </div>
          <div className="combine-form">
            <div className="mb-3 k-form-field">
                <Label editorId={props.data.email}>Email</Label>
                <Input name="email" value={props.data.email} readonly="true" />
              </div>   
            <div className="mb-3 k-form-field">
              <Label editorId={props.data.MemberClassification}>Member Classification</Label>
              <Input name="MemberClassification" value={props.data.MemberClassification} readonly="true" />
            </div>
                    
          </div>
        </fieldset>
        <div className="form-wrapper">
          <div className="heading-form">My Address</div>
          <fieldset className="info-box">
            <div className="mb-3 k-form-field">
              <Label>
                {props.data.addressLine1} {props.data.addressLine2}
              </Label>
              <Label>
                {props.data.city}, {props.data.state} {props.data.zipCode}
              </Label>
            </div>
          </fieldset>
        </div>
        <Button onClick={gotoHome} primary={true} className="btnLink">
          Back to Home
        </Button>
      </form>
    </div>
    </div>
  );
}
