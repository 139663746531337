import React from "react";
import ReviewUpdateDetails from "./ReviewUpdateDetails";

export default function ReviewUpdateElectionsForNonFuturePlans(props) {
  return (
    <div className="updateElections">
      <div className="card-list-wrap">
        {props.electionData &&
          props.electionData.map((card, index) => {
            return (
              <ReviewUpdateDetails
                key={index}
                electionData={card}
                current={false}
              />
            );
          })}
      </div>
    </div>
  );
}
