export const benificaryrelationships = [
  "Self",
  "Spouse",
  "Child",
  "Parent",
  "Sibling",
  "Domestic Partner",
  "Domestic Partner Child",
  "Grand Child",
  "Other",
];
export const depedentrelationships = [
  "Spouse",
  "Child",
  "Domestic Partner",
  "Domestic Partner Child",
  "Disabled Child",
];
export const genders = ["Male", "Female"];
export const STATUS = {
  IDLE: "IDLE",
  SUBMITTED: "SUBMITTED",
  SUBMITTING: "SUBMITTING",
  COMPLETED: "COMPLETED",
  ERROR: "ERROR",
};
export const countries = ["United States", "Canada"];
export const beneficiarytypes = [
  "Person",
  "Trust",
  "Organization/Charity",
  "My Estate",
];
export const types = ["Primary", "Secondary"];
export const PLANSTEP = {
  questions: "Question",
  coverage: "Determine Coverage",
  affirmation: "Affirmation",
  review: "Plan Cost",
  dependents: "Family Review",
};

export const apCoverageAmountDisablePlan = ["MLGTLM", "MLDP", "VOYACIM"];
export const CoverageAmountDisablePlanML10Or20 = ["ML10M", "ML20M", "ML10S","ML20S"];
