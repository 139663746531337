import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardTitle,
  CardBody,
  CardActions,
} from "@progress/kendo-react-layout";

export default function EnrollNewForm(props) {
  const history = useHistory();
  const enrollClick = (card) => {
    history.push({
      pathname: `/plan`,
      state: { plan: card, electionDates: props.electionDates },
    });
  };

  return (
    <>
      {props.enrollCoverage.length === 0 && <div className="cardNoRecord">There are no available plans to display.<br></br></div>}
      <div className="card-list-wrap">
        {props.enrollCoverage.map((card, index) => {
          return (
            <div key={index} className="card-items">
              <Card>
                <div>
                  <CardBody>
                    <CardTitle>{card.insuranceID >= 0 ? (
                        <p class="text-ellipsis description-block">
                          {card.insurancePlanTypeDescription} ID #
                          {card.insuranceID}
                        </p>
                      ) : (
                        <p class="text-ellipsis description-block">{card.insurancePlanTypeDescription}</p>
                      )}
                      </CardTitle>      
                      <p class="description-details"><div dangerouslySetInnerHTML={{__html: card.PlanTypeDetails}}/></p>             
                  </CardBody>
                  <CardActions>
                    <Button
                      primary={true}
                      className="k-flat"
                      onClick={() => enrollClick(card)}
                    >
                      Enroll
                    </Button>
                  </CardActions>
                </div>
              </Card>
            </div>
          );
        })}
      </div>
    </>
  );
}
