import React from "react";
import { CardSubtitle } from "@progress/kendo-react-layout";
import { Tooltip } from "@progress/kendo-react-tooltip";
import CustomToolTip from "./CustomToolTip";
import { currencyFormat } from "../../services/shareconstant";
import CustomEOIToolTip from "./CustomEOIToolTip";

export default function HistoricalCoverageCard(props) {
  return (
    <>
    <div>{props.card.status}</div>
      <div className="wrapper-Block">
        <div className="planDetailsBlock">
          {props.card.summaryAttributes &&
            Object.keys(props.card.summaryAttributes).map((key, i) => (
              <p key={i}>
                <CardSubtitle className="label-viewdetails">
                  {" "}
                  {key}
                </CardSubtitle>
                <span>
                  {" "}
                  {isNaN(props.card.summaryAttributes[key])
                    ? props.card.summaryAttributes[key]
                    : currencyFormat(
                        parseInt(props.card.summaryAttributes[key])
                      )}
                </span>
              </p>
            ))}
        </div>
        <div className="memberCostBlock">
          {props.card.discountOrPremiumWaiverEligible === true
            ? props.card.currentDetails &&
              Object.keys(props.card.currentDetails).map(
                (key, i) =>
                  key !== "discountOrPremiumWaiverAttributes" &&
                  key !== "summaryAttributes" && (
                    <p key={i}>
                      {key === "memberCost" ? (
                        <>
                          <Tooltip
                            content={(prop) => (
                              <CustomToolTip
                                key={i}
                                data={
                                  props.card?.currentDetails
                                    ?.discountOrPremiumWaiverAttributes
                                }
                              />
                            )}
                            anchorElement="target"
                            position="bottom"
                          >
                            <span className="label-viewdetails">
                              Member Cost{" "}
                            </span>
                            <span
                              className="k-icon k-i-information"
                              title="Member Cost"
                            ></span>
                          </Tooltip>
                        </>
                      ) : (
                        <CardSubtitle className="label-viewdetails">
                         {key === "fullCost" && (props.card.status==='EOI Pending') ? <>
                          <Tooltip
                            content={(prop) => (
                              <CustomEOIToolTip                               
                              />
                            )}
                            anchorElement="target"
                            position="bottom"
                          >
                            <span className="label-viewdetails">
                              Full Cost{" "}
                            </span>
                            <span
                              className="k-icon k-i-information"
                              title="Full Cost"
                            ></span>
                          </Tooltip>
                        </> : (key === "fullCost"?"Full Cost":key)}
                        </CardSubtitle>
                      )}
                      <span className="label-viewdetails">
                        {" "}
                        {isNaN(props.card.currentDetails[key])
                          ? props.card.currentDetails[key]
                          : props.card.currentDetails[key] === -1
                          ? "TBD"
                          : currencyFormat(props.card.currentDetails[key])}{" "}
                      </span>
                    </p>
                  )
              )
            : props.card.currentDetails &&
              Object.keys(props.card.currentDetails).map(
                (key, i) =>
                  key !== "discountOrPremiumWaiverAttributes" &&
                  key !== "summaryAttributes" &&
                  key !== "fullCost" && (
                    <p key={i}>
                      <CardSubtitle className="label-viewdetails">
                        {" "}
                        {key === "memberCost" ? "Member Cost" : key}{" "}
                      </CardSubtitle>
                      <span>
                        {" "}
                        {isNaN(props.card.currentDetails[key])
                          ? props.card.currentDetails[key]
                          : props.card.currentDetails[key] === -1
                          ? "TBD"
                          : currencyFormat(props.card.currentDetails[key])}{" "}
                      </span>
                    </p>
                  )
              )}
        </div>
      </div>
    </>
  );
}