import React, { useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardTitle,
  CardBody,
  CardActions,
  CardSubtitle,
} from "@progress/kendo-react-layout";
import {
  dateFormat,
  datewotimestampFormat,
  terminationMessage1,
  redirectHome,
  errorSaveMessage,
  SuccessSaveMessage,
  terminationMessage2,
  reinstatementMessage1,
  reinstatementMessage2,
} from "../../services/shareconstant";
import { Notification } from "@progress/kendo-react-notification";
import { STATUS } from "../../model/constants";
import { PostSave } from "../../services/useFetch";
import { urlEnrollmentSave } from "../../services/serviceURLs";
import Spinner from "../Spinner";
import CoverageCard from "./CoverageCard";
import CurrentCoverageCard from "./CurrentCoverageCard";
//import {AuthContext} from "../AuthContext";
import CustomButtonToolTip from "./CustomButtonToolTip";
import { Tooltip } from "@progress/kendo-react-tooltip";

export default function NoPendingElections(props) {
  const [openTerminateForm, setOpenTerminateForm] = useState(false);
  const [terminateCard, setTerminateCard] = useState({});
  const [status, setStatus] = useState(STATUS.IDLE);
  const [saveError, setSaveError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [electionMsg, setElectionMsg] = useState(``);
  const [openCancelForm, setOpenCancelForm] = useState(false);
  const [cancelCard, setCancelCard] = useState({});
  const [openReinstateForm, setOpenReinstateForm] = useState(false);
  const [reinstateCard, setReinstateCard] = useState({});
  const history = useHistory();

  const modifyClick = (card) => {
    history.push({
      pathname: `/plan`,
      state: { plan: card, electionDates: props.electionDates },
    });
  };
  const EOIClick = (card) => {
    history.push({
      pathname: `/eoirequest`
    });
  };

  const terminateClick = (card) => {
    setTerminateCard(card);
    setOpenTerminateForm(true);
  };

  const toggleTerminateDialog = () => {
    setOpenTerminateForm(!openTerminateForm);
  };

  const cancelClick = (card) => {
    setCancelCard(card);
    setOpenCancelForm(true);
  };

  const toggleCancelDialog = () => {
    setOpenCancelForm(!openCancelForm);
  };  

  const toggleReinstateDialog = () => {
    setOpenReinstateForm(!openReinstateForm);
  };

  const onReinstateConfirm = async () => {
    try {
      setOpenReinstateForm(false);
      setStatus(STATUS.SUBMITTING);
      const saveElection = {
        electionType: `Reinstatement`,
        insuranceID: reinstateCard.insuranceID,
        insurancePlanTypeID: reinstateCard.insurancePlanTypeID,
      };
      const saveresponse = await PostSave(
        `${urlEnrollmentSave}`,
        `POST`,
        saveElection
      );
      setSaveError(null);
      if (saveresponse.ok) {
        const electionresult = await saveresponse.json();
        setElectionMsg(electionresult.Message);
        setSuccess(true);
        setError(false);
        setStatus(STATUS.COMPLETED);
      } else {
        throw saveresponse;
      }
    } catch (e) {
      setSaveError(e.statusText);
      setStatus(STATUS.ERROR);
      setSuccess(false);
      setError(true);
    }
  };
  const onTerminateConfirm = async () => {
    try {
      setOpenTerminateForm(false);
      setStatus(STATUS.SUBMITTING);
      const saveElection = {
        electionType: `Termination`,
        insuranceID: terminateCard.insuranceID,
        insurancePlanTypeID: terminateCard.insurancePlanTypeID,
      };
      const saveresponse = await PostSave(
        `${urlEnrollmentSave}`,
        `POST`,
        saveElection
      );
      setSaveError(null);
      if (saveresponse.ok) {
        const electionresult = await saveresponse.json();
        setElectionMsg(electionresult.Message);
        setSuccess(true);
        setError(false);
        setStatus(STATUS.COMPLETED);
      } else {
        throw saveresponse;
      }
    } catch (e) {
      setSaveError(e.statusText);
      setStatus(STATUS.ERROR);
      setSuccess(false);
      setError(true);
    }
  };

  const onCancelConfirm = async () => {
    try {
      setOpenCancelForm(false);
      setStatus(STATUS.SUBMITTING);
      const saveElection = {
        electionType: `Cancellation`,
        insuranceID: cancelCard.insuranceID,
        insurancePlanTypeID: cancelCard.insurancePlanTypeID,
      };
      const saveresponse = await PostSave(
        `${urlEnrollmentSave}`,
        `POST`,
        saveElection
      );
      setSaveError(null);
      if (saveresponse.ok) {
        const electionresult = await saveresponse.json();
        setElectionMsg(electionresult.Message);
        setSuccess(true);
        setError(false);
        setStatus(STATUS.COMPLETED);
      } else {
        throw saveresponse;
      }
    } catch (e) {
      setSaveError(e.statusText);
      setStatus(STATUS.ERROR);
      setSuccess(false);
      setError(true);
    }
  };

  function handleCloseDialog() {
    history.push(redirectHome());
  }

  return (
    <>
      {status === STATUS.SUBMITTING && <Spinner />}

      {(success || error) && (
        <Dialog onClose={handleCloseDialog}>
          {success && electionMsg === "Success" && (
            <Notification
              type={{ style: "success", icon: true }}
              closable={false}
            >
              <span>{SuccessSaveMessage}</span>
            </Notification>
          )}
          {error && (
            <Notification
              type={{ style: "error", icon: true }}
              closable={false}
            >
              <span>
                {errorSaveMessage} {saveError}
              </span>
            </Notification>
          )}
          <DialogActionsBar>
            <button className="k-button" onClick={handleCloseDialog}>
              OK
            </button>
          </DialogActionsBar>
        </Dialog>
      )}

      {openTerminateForm && (
        <Dialog title={"Confirm Terminate"} onClose={toggleTerminateDialog}>
          <p>{terminationMessage1}</p>
          <CardSubtitle>
            {terminateCard.insurancePlanTypeDescription}{" "}
          </CardSubtitle>
          <p>
            {terminationMessage2}{" "}
            {dateFormat(props.electionDates.effectivedate)
              ? dateFormat(props.electionDates.effectivedate)
              : datewotimestampFormat(props.electionDates.effectivedate)}
          </p>
          <DialogActionsBar>
            <Button primary={true} onClick={onTerminateConfirm}>
              {" "}
              Confirm
            </Button>
            <Button primary={false} onClick={toggleTerminateDialog}>
              {" "}
              Cancel
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}

      {openReinstateForm && (
        <Dialog title={"Confirm Reinstatement"} onClose={toggleReinstateDialog}>
          {/* <p >Please confirm Reinstatement of the below plan. </p>              */}
          <p>{reinstatementMessage1}</p>
          <CardSubtitle>
            {reinstateCard.insurancePlanTypeDescription}{" "}
          </CardSubtitle>
          <p>
            {reinstatementMessage2} {dateFormat(reinstateCard.endDate)}
          </p>
          {/* <p>Clicking Confirm will reinstate the plan effective. </p> */}
          <DialogActionsBar>
            <Button primary={true} onClick={onReinstateConfirm}>
              {" "}
              Confirm
            </Button>
            <Button primary={false} onClick={toggleReinstateDialog}>
              {" "}
              Cancel
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}

      {openCancelForm && (
        <Dialog title={"Confirm Terminate"} onClose={toggleCancelDialog}>
          <p>{terminationMessage1} </p>
          <CardSubtitle>
            {cancelCard.insurancePlanTypeDescription}{" "}
          </CardSubtitle>
          <p>
            {terminationMessage2} {dateFormat(cancelCard.startDate)}
          </p>
          <DialogActionsBar>
            <Button primary={true} onClick={onCancelConfirm}>
              {" "}
              Confirm
            </Button>
            <Button primary={false} onClick={toggleCancelDialog}>
              {" "}
              Cancel
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}

      {props.noPendingElections.map((card, index) => {
        return (
          <div key={index} className="card-items">
            <Card>
              <div>
              <br/>
                <CardBody>
                  {card.futureDetails ? (
                    <CurrentCoverageCard
                      card={card}
                      current={true}
                    ></CurrentCoverageCard>
                  ) : (
                    <>
                      <CardTitle>
                        <p class="text-ellipsis description-block">
                      {card.insurancePlanTypeDescription} ID #
                        {card.insuranceID}
                        {card.nonContributoryOptIn === true &&
                        JSON.parse(localStorage.getItem("showApprentice")) ===
                          true
                          ? ` (Apprentice)`
                          : null}
                          </p>
                      </CardTitle>                     
                      <p className="startDate-label">
                        Start Date:
                        {card.startDate < props.electionDates.currentdate ? (
                          <span> {dateFormat(card.startDate)}</span>
                        ) : (
                          <span className="startDate-new">
                            {" "}
                            {dateFormat(card.startDate)}
                          </span>
                        )}
                        {/* Show EndDate if Cancellation (Future Date instead of 1/1/1900) */}
                        {/* new Date(card.endDate).setHours(0,0,0,0) >= new Date().setHours(0,0,0,0)  */}
                        {card.endDate !== "1900-01-01T00:00:00" && (
                          <span className="endDate-label">
                            End Date:{" "}
                            <span className="endDate-new">
                              {dateFormat(card.endDate)}
                            </span>
                          </span>
                        )}
                      </p>
                      <CoverageCard card={card}></CoverageCard>{" "}
                    </>
                  )}
                </CardBody>
                <CardActions>
                <div className="eoi-action">
                      {card.status==='EOI Pending' ? (
                          <Button
                            primary={true}
                            className="k-flat"
                            onClick={() => EOIClick(card)}
                          >
                            {" "}
                            EOI Pending{" "}
                          </Button>
                      ):null}  
                  </div>
                  <div className="main-action">                  
                    {card.endDate === "1900-01-01T00:00:00" && card.insurancePlanTypeName!=="MLUM" && card.insurancePlanTypeName!=="MLUS" ? (
                      <Button
                        primary={true}
                        className="k-flat"
                        onClick={() => modifyClick(card)}
                      >
                        {" "}
                        Modify{" "}
                      </Button>
                    ):<Tooltip
                    content={(prop) => (
                      <CustomButtonToolTip/>
                    )}
                    anchorElement="target"
                    position="bottom"
                  > <span className="k-button k-primary k-flat k-i-information disabledControlButton"
                    title="Modify">Modify</span></Tooltip>}
                    {card.endDate === "1900-01-01T00:00:00" ? (
                      card.startDate <= props.electionDates.currentdate ? (
                        <Button
                          primary={true}
                          className="k-flat"
                          onClick={() => terminateClick(card)}
                        >
                          Terminate{" "}
                        </Button>
                      ) : (
                        <Button
                          primary={true}
                          className="k-flat"
                          onClick={() => cancelClick(card)}
                        >
                          Terminate{" "}
                        </Button>
                      )
                    ) : null}
                  </div>
                </CardActions>
              </div>
            </Card>
          </div>
        );
      })}
    </>
  );
}