import React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import {
  currencyFormat
} from "../../services/shareconstant";
export default function EOIRequestDetails(props) {
  const ApprovedCost = (props) => {  
    const cost = parseFloat(props.dataItem.ApprovedAmount).toFixed(2);
    return <td>{currencyFormat(cost)}</td>;
  };
  const ElectedAmountCost = (props) => {  
    const cost = parseFloat(props.dataItem.ElectedAmount).toFixed(2);
    return <td>{currencyFormat(cost)}</td>;
  };
  const AmountPendingEOI = (props) => {  
    const cost = parseFloat(props.dataItem.AmountPendingEOI).toFixed(2);
    return <td>{currencyFormat(cost)}</td>;
  };
  const EOIURL = (props) => {  
    // console.log(window.location.href);
    // console.log(window.location.origin);
    // console.log(props.dataItem);
    if (props.dataItem.Plan === 'LTDFDX' || props.dataItem.Plan === 'ltdfdx'){
      var URL = window.location.origin + props.dataItem.EOIURL;
    }
    else
    URL = props.dataItem.EOIURL;
    
    return URL? <td><a class="k-button k-primary btnLink" href={URL} target="_blank">Click here</a></td>:<td></td>;
  };
  return (
    props.data[0] ? (<div class="page-wrapper" className="eoi-details">
        <div class="heading-form">Notifications</div>  <br />
        <div class="mb-3 styledGrid EOIRequestGrid">
        <Grid data={props.data}>
          {/* <Column field="Benefit" title="Benefit" /> */}
          <Column field="ElectedPlan" title="Elected Plan" />
          <Column cell={ElectedAmountCost}   title="Elected Amount" />
          <Column  cell={ApprovedCost}   title="Approved Amount" />
          <Column  cell={AmountPendingEOI}   title="Amount Pending EOI" />
          {/* <Column  field="Tier"   title="Tier Level" /> */}
          <Column cell={EOIURL}  title="EOI Link" />
        </Grid>
        </div>
    </div>) : (
        <div>
            <div class="heading-form">Notifications</div>  <br />
            <p>You do not have pending EOI for any plan</p> <br /> </div>
    )
);
}