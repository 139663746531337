import { formatDate, parseDate } from "@telerik/kendo-intl";

export const redirectHome = () => {
  return {
    pathname: "/",
    key: Date.now(),
  };
};

export const currencyFormat = (num) => {
  return (
    "$" +
    parseFloat(num)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  );
};

export const dateFormat = (date1) => {
  return formatDate(
    parseDate(date1, ["yyyy/MM/dd", "yyyy-MM-ddTHH:mm:ss"]),
    "MM/dd/yyyy"
  );
};
export const datewotimestampFormat = (date1) => {
  return formatDate(
    parseDate(date1, ["yyyy/MM/dd", "yyyy-MM-ddTHH:mm:ss.SSS"]),
    "MM/dd/yyyy"
  );
};
export const errorSaveMessage = `Error while Saving Information.`;
export const SuccessSaveMessage = `Information Saved Successfully`;
export const ChangesSaveMessage = `Changes have been successfully saved.`;

export const undoMessage1 = `This will undo all changes you have made to`;
export const undoMessage2 = `Click Confirm to proceed.`;
export const terminationMessage1 = `Please confirm termination of the below plan.`;
export const terminationMessage2 = `Clicking Confirm will terminate the plan effective`;
export const reinstatementMessage1 = `Please confirm reinstatement of the below plan.`;
export const reinstatementMessage2 = `Clicking Confirm will reinstate the plan effective`;

export const clearLocalStorage = () => {
  localStorage.removeItem("freeOptionRequired");
  localStorage.removeItem("showApprentice");
  localStorage.removeItem("isAP");
};

export const historyMinDate = new Date(1901, 1, 1);
export const historyMaxDate = new Date();
