import React from "react";
const tooltipMessageAmount = `Please contact ALPA at insurance@alpa.org to make changes to this election.`;

export default function CustomButtonToolTip() {
  return (
           <>
          <span>{tooltipMessageAmount}</span>
        </>     
  );
}
