import React, { useState } from "react";
import {
  Card,
  CardTitle,
  CardBody,
  CardActions,
} from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { urlMemberPlanDetails } from "../../services/serviceURLs";
import { GetFetch } from "../../services/useFetch";
import { dateFormat } from "../../services/shareconstant";
// import CoverageCard from "./CoverageCard";
import HistoricalCoverageCard from "./HistoricalCoverageCard";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Notification } from "@progress/kendo-react-notification";
import { STATUS } from "../../model/constants";
import Spinner from "../Spinner";

export default function HistoricalShowDetails(props) {
  const [status, setStatus] = useState(STATUS.IDLE);
  const [getError, setGetError] = useState(null);
  //const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [showChanges, setshowChanges] = useState(false);
  const [details, setDetails] = useState({});
  const [dataLoaded, setdataLoaded] = useState(false);

  const showClick = async () => {
    setStatus(STATUS.SUBMITTING);
    setGetError(null);
    setError(false);
    if (!dataLoaded) {
      try {
        const saveresponse = await GetFetch(
          `${urlMemberPlanDetails}/${props.card.insuranceID}`
        );
        if (saveresponse.ok) {
          const output = await saveresponse.json();
          setDetails(output);
          setdataLoaded(true);
          setStatus(STATUS.COMPLETED);
        } else {
          throw saveresponse;
        }
      } catch (e) {
        setGetError(e.statusText);
        setStatus(STATUS.ERROR);
        // setSuccess(false);
        setError(true);
      }
    } else {
      setStatus(STATUS.COMPLETED);
    }
    setshowChanges(!showChanges);
  };

  return (
    <>
      {status === STATUS.SUBMITTING && <Spinner />}

      {error && (
        <Notification type={{ style: "error", icon: true }} closable={false}>
          {getError}
        </Notification>
      )}
      <div key={props.card.insuranceID} className="card-items">
        <Card>
          <div>
            <CardBody>
              <>
                <CardTitle>
                  <p class="text-ellipsis description-block">
                {props.card.insurancePlanTypeDescription} ID #
                  {props.card.insuranceID}{" "}
                  {props.card.nonContributoryOptIn === true &&
                  props.isAP === true
                    ? ` (Apprentice)`
                    : null}
                    </p>
                </CardTitle>                
                <p className="startDate-label">
                  Start Date: {dateFormat(props.card.startDate)}
                  {props.card.endDate !== "1900-01-01T00:00:00" && (
                    <span className="endDate-label">
                      End Date:{" "}
                      <span className="endDate-new">
                        {dateFormat(props.card.endDate)}
                      </span>
                    </span>
                  )}
                </p>
                <HistoricalCoverageCard
                  card={props.card}
                ></HistoricalCoverageCard>{" "}
              </>
            </CardBody>
            <CardActions>
              <div className="main-action">
                <Button
                  primary={true}
                  className="k-flat"
                  onClick={() => showClick()}
                >
                  {" "}
                  {showChanges ? `Hide Details` : `Show Details`}{" "}
                </Button>
              </div>
            </CardActions>

            {showChanges === true && (
              <>
                {details.Beneficiaries === undefined &&
                  details.Dependents === undefined && (
                    <Notification
                      type={{ style: "info", icon: true }}
                      closable={false}
                    >
                      No details to show.
                    </Notification>
                  )}

                {details.Beneficiaries && (
                  <>
                    {" "}
                    <div className="currentShowDetailsBeneficiary">
                      <span className="label-viewdetails">Beneficiaries</span>
                      <Grid data={details.Beneficiaries}>
                        <Column field="Name" title="Name" />
                        <Column field="Type" title="Type" />
                        <Column
                          field="Percentage"
                          title="Percentage"
                          format="{0:#'%'}"
                        />
                      </Grid>
                    </div>
                  </>
                )}
                {details.Dependents && (
                  <>
                    <div className="currentShowDetailsDependant">
                      {details.Beneficiaries && <br />}
                      <span className="label-viewdetails">Dependents</span>
                      <Grid data={details.Dependents}>
                        <Column field="Name" title="Name" />
                        <Column field="Relationship" title="Relationship" />
                      </Grid>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </Card>
      </div>
    </>
  );
}
