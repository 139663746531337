import * as React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import {
  benificaryrelationships as relationships,
  beneficiarytypes,
  countries,
  genders,
} from "./../../model/constants";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import {
  FormInput,
  FormDropDownList,
  FormComboBox,
  FormMaskedDate, FormAutoComplete
} from "../Common/form-components";
import { requiredValidator } from "../Common/validators";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";

export const CoverageBeneficiaryForm = (props) => {
  let filteredBeneficiary;
  if (props.category === "Life" && props.primaryInsuredParty === "Member")
    filteredBeneficiary = props.beneficiaries.filter(
      (item) =>
        item.inPlan === false &&
        item.isActive === true &&
        item.relationship !== "Self"
    );
  else if (props.category === "Life" && props.primaryInsuredParty === "Spouse")
    filteredBeneficiary = props.beneficiaries.filter(
      (item) =>
        item.inPlan === false &&
        item.isActive === true &&
        item.relationship !== "Spouse"
    );
  else
    filteredBeneficiary = props.beneficiaries.filter(
      (item) => item.inPlan === false && item.isActive === true
    );

  const AddCommandCell = (props) => {
    return (
      <td className="editBtn">
        <Button primary={false} onClick={() => props.enterAdd(props.dataItem)}>
          <span className="k-icon k-i-add"></span>
        </Button>
      </td>
    );
  };

  const MyAddCommandCell = (props) => (
    <AddCommandCell {...props} enterAdd={enterAdd} />
  );

  const enterAdd = (dataItem) => {
    const arr = [...props.beneficiaries];
    let index = arr.findIndex((x) => x.id === dataItem.id);
    dataItem.inPlan = true;
    arr[index] = dataItem;
    props.setBeneficiaries(arr);
    props.cancelEdit();
  };

  return (
    <Dialog title={"Allocate Beneficiary"} onClose={props.cancelEdit}>
      <div className="panelbar-wrapper addBeneficaryDialog">
        <PanelBar expandMode="single">
          <PanelBarItem
            expanded={`${props.IsAdd}` === "true" ? true : false}
            title="Choose from existing Beneficiaries List"
          >
            <div className="mb-3 styledGrid review-beneficiaryGrid editBeneficiary">
              <Grid data={filteredBeneficiary}>
                <Column field="beneficiarytype" title="Beneficiary Type" />
                <Column field="name" title="Name" />
                <Column
                  field="dateOfBirth"
                  title="Date of Birth"
                  format="{0:MM/dd/yyyy}"
                />
                <Column field="relationship" title="Relationship" />
                <Column cell={MyAddCommandCell} title="Add" />
              </Grid>
            </div>
            <br />
          </PanelBarItem>
          <PanelBarItem
            expanded={`${props.IsAdd}` === "true" ? false : true}
            title={
              `${props.IsAdd}` === "true"
                ? "Add New Beneficiary"
                : "Edit Beneficiary"
            }
          >
            <div>
              <Form
                onSubmit={props.onSubmit}
                initialValues={props.item}
                render={(formRenderProps) => (
                  <FormElement>
                    <div className="editDependency-family">
                      <fieldset className={"k-form-fieldset"}>
                        <span>Fill in the Beneficiary fields (*required) </span>
                        <div className="heading-form">Basic Information</div>
                        <div className="combine-form">
                          <div className="mb-3">
                            <Field
                              key={"beneficiarytype"}
                              id={"beneficiarytype"}
                              name={"beneficiarytype"}
                              component={FormDropDownList}
                              label={"Type"}
                              data={beneficiarytypes}
                              validator={requiredValidator}
                            />
                          </div>
                          {`${formRenderProps.valueGetter(
                            "beneficiarytype"
                          )}` !== "My Estate" &&
                            `${formRenderProps.valueGetter(
                              "beneficiarytype"
                            )}` !== "Person" && (
                              <div className="mb-3">
                                <Field
                                  key={"taxidentifier"}
                                  id={"taxidentifier"}
                                  name={"taxidentifier"}
                                  component={FormInput}
                                  label={"Tax Identifier"}
                                  minLength={3}
                                  maxLength={50}
                                />
                              </div>
                            )}
                        </div>
                        {`${formRenderProps.valueGetter("beneficiarytype")}` !==
                          "My Estate" && (
                          <div className="mb-3">
                            <Field
                              key={"name"}
                              id={"name"}
                              name={"name"}
                              component={FormInput}
                              label={"*Name"}
                              minLength={3}
                              maxLength={100}
                              validator={requiredValidator}
                            />
                          </div>
                        )}
                        {`${formRenderProps.valueGetter("beneficiarytype")}` ===
                          "Trust" && (
                          <div className="mb-3">
                            <Field
                              key={"dateOfTrust"}
                              id={"dateOfTrust"}
                              name={"dateOfTrust"}
                              component={FormMaskedDate}
                              label={"Date of Trust"}
                            />
                          </div>
                        )}
                        <div className="combine-form">
                          {`${formRenderProps.valueGetter(
                            "beneficiarytype"
                          )}` === "Person" && (
                            <div className="mb-3">
                              <Field
                                key={"dateOfBirth"}
                                id={"dateOfBirth"}
                                name={"dateOfBirth"}
                                component={FormMaskedDate}
                                label={"Date of Birth"}
                              />
                            </div>
                          )}
                          {`${formRenderProps.valueGetter(
                            "beneficiarytype"
                          )}` === "Person" && (
                            <div className="mb-3">
                              <Field
                                key={"relationship"}
                                id={"relationship"}
                                name={"relationship"}
                                label={"*Relationship"}
                                data={relationships}
                                validator={requiredValidator}
                                component={FormComboBox}
                                suggest={true}
                                placeholder="Select One"
                              />
                            </div>
                          )}{" "}
                        </div>
                        {`${formRenderProps.valueGetter("beneficiarytype")}` ===
                          "Person" && (
                          <div className="mb-3">
                            <Field
                              key={"gender"}
                              id={"gender"}
                              name={"gender"}
                              component={FormComboBox}
                              data={genders}
                              label={"Gender"}
                              suggest={true}
                              placeholder="Select One"
                            />
                          </div>
                        )}
                        {/* <div className="mb-3">
                    <Field
                      key={'isActive'}
                      id={'isActive'}
                      name={"isActive"}
                      component={FormCheckbox}
                      label={"Active"}
                    //validator={requiredValidator}

                    />
                  </div> */}
                        {`${formRenderProps.valueGetter("beneficiarytype")}` ===
                          "Person" && (
                          <div className="mb-3">
                            <Field
                              key={"telephone"}
                              id={"telephone"}
                              name={"telephone"}
                              label={"Telephone"}
                              component={FormInput}
                              maxLength={30}
                            />
                          </div>
                        )}
                      </fieldset>
                      {`${formRenderProps.valueGetter("beneficiarytype")}` !==
                        "My Estate" && (
                        <fieldset className={"k-form-fieldset"}>
                          <div className="heading-form">Address</div>
                          <div className="mb-3">
                            <Field
                              key={"line1"}
                              id={"line1"}
                              name={"line1"}
                              label={"Line 1"}
                              component={FormInput}
                              maxLength={100}
                            />
                          </div>
                          <div className="mb-3">
                            <Field
                              key={"line2"}
                              id={"line2"}
                              name={"line2"}
                              label={"Line 2"}
                              component={FormInput}
                              maxLength={100}
                            />
                          </div>
                          <div className="mb-3">
                            <Field
                              key={"line3"}
                              id={"line3"}
                              name={"line3"}
                              label={"Line 3"}
                              component={FormInput}
                              maxLength={100}
                            />
                          </div>
                          <div className="combine-form">
                            <div className="mb-3">
                              <Field
                                key={"city"}
                                id={"city"}
                                name={"city"}
                                label={"City"}
                                component={FormInput}
                                maxLength={50}
                              />
                            </div>
                            {/* <div className="mb-3">
                              <Field
                                key={"country"}
                                id={"country"}
                                name={"country"}
                                label={"Country"}
                                data={countries}
                                component={FormComboBox}
                                suggest={true}
                                placeholder="Select One"
                              />
                            </div> */}
                            <div className="mb-3">
                                <Field
                                  key={"country"}
                                  id={"country"}
                                  name={"country"}
                                  label={"Country"}
                                  data={countries}
                                  component={FormAutoComplete}
                                  suggest={true}
                                  placeholder="Select One"
                                />
                              </div>{" "}
                          </div>
                          <div className="combine-form">
                            <div className="mb-3">
                              <Field
                                key={"stateprovince"}
                                id={"stateprovince"}
                                name={"stateprovince"}
                                label={"State/Province"}
                                component={FormInput}
                                maxLength={30}
                              />
                            </div>
                            <div className="mb-3">
                              <Field
                                key={"postalcode"}
                                id={"postalcode"}
                                name={"postalcode"}
                                label={"Postal Code"}
                                component={FormInput}
                                maxLength={25}
                              />
                            </div>{" "}
                          </div>
                          <div className="mb-3">
                            <Field
                              key={"county"}
                              id={"county"}
                              name={"county"}
                              label={"County"}
                              component={FormInput}
                              maxLength={100}
                            />
                          </div>
                        </fieldset>
                      )}
                    </div>

                    <DialogActionsBar>
                      <button
                        type={"submit"}
                        className="k-button k-primary"
                        onClick={formRenderProps.onSubmit}
                        disabled={!formRenderProps.allowSubmit}
                      >
                        {`${props.item.name}` === "" ? "Save" : "Update"}
                      </button>
                      <button
                        type={"button"}
                        className="k-button"
                        onClick={props.cancelEdit}
                      >
                        Cancel
                      </button>
                    </DialogActionsBar>
                  </FormElement>
                )}
              />
            </div>
          </PanelBarItem>
        </PanelBar>
      </div>
    </Dialog>
  );
};
