import * as React from "react";
import { Field } from "@progress/kendo-react-form";
import { Notification } from "@progress/kendo-react-notification";
import { FormCheckbox } from "../Common/form-components";
import { checkboxValidator } from "../Common/validators";
import {
  urlAffirmationContents
} from "../../services/serviceURLs";
import { Fetch } from "./../../services/useFetch";
import Spinner from "./../Spinner";
export const Affirmation = (props) => {
  
  return (
    <div className="terms-conditions">
      <p className="StepName-Mbl visible-Mb">{props.labelValue}</p>
      <Notification type={{ style: "info", icon: true }} closable={false}>
        {" "}
        Please review and acknowledge:
      </Notification>
        
      <Fetch url={  urlAffirmationContents +"/"+props.plan.insurancePlanTypeID}>
        {(data, loading, error) => {
          if (loading) return <Spinner />;
          if (error) throw error;

          return(
            <div>
              <form className="k-form">
                <div className="heading-form"></div>
                {/* <div>{data.HTMLData}</div> */}
                <div dangerouslySetInnerHTML={{__html: data.HTMLData}}/>           
              </form>
              <br></br>
            </div>
              );
        }}
      </Fetch>
      {/* <AffirmationForm>
      plan={props.plan}
      </AffirmationForm> */}
      {/* <ul>
        <li>
          I am actively at work or physically/psychologically available for work
          in the capacity for which I hold an FAA license.
        </li>
        <li>
          My health status has not changed since my last FAA physical exam.
        </li>
        <li>
          I declare that I am able to perform the normal activities of a person
          of such age and sex with a like occupation or retired status on the
          date I am enrolling. I understand that if I am unable to perform such
          normal activities on the scheduled effective date of insurance, such
          insurance will not take effective until I am able to resume performing
          such activities.
        </li>
        <li>
          If I do not enroll for the maximum coverage for which I am eligible,
          evidence of insurability satisfactory to MetLife may be required to
          enroll for or increase such coverage. Coverage will not take effect,
          or it will be limited, until notice is received that MetLife has
          approved the coverage or increase.
        </li>
        <li>
          I have read the Beneficiary Designation section provided in this
          enrollment form and I have made a designation if I so choose (if
          applicable).
        </li>
        <li>
          I have read the attached Fraud Warning(s) provided in this enrollment
          form.
        </li>
      </ul> */}
      <Field
        id={"terms"}
        name={"terms"}
        label={"I agree with terms and conditions"}
        hint={"Hint: By checking this, you agree to our Terms & Conditions"}
        component={FormCheckbox}
        validator={checkboxValidator}
      />
    </div>
  );
};
