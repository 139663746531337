import React from "react";
export default function FDXEOIRequest() {
  debugger;
    return (
       
            <div class="page-wrapper">
                 <br />
                <p>Thank you for submitting your LTD application. You will receive an email from The Hartford insurance company, providing next steps on completing the Evidence of Insurability form. If you do not have an email on file, a form will be mailed to you.  Please contact ALPA Insurance at <a href="mailto:insurance@alpa.org" target="_top">
                insurance@alpa.org</a> if you have any additional questions or require assistance.</p> <br /> </div>
        
    );
}