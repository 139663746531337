import React from "react";
const tooltipMessageMemberCost = `Member cost can be affected by factors such as discounts and premium waivers.`;
const tooltipMessageFactor = `Factors affecting this plan:`;

export default function CustomToolTip(props) {
  return (
    <>
      {props.data && Object.entries(props.data).length !== 0 ? (
        <>
          <span>{tooltipMessageMemberCost}</span>
          <br />
          <span>{tooltipMessageFactor}</span>
          {Object.keys(props.data).map((key, i) => (
            <>
              <br />
              <span key={i}>
                {" "}
                {key} : {props.data[key]}
              </span>
            </>
          ))}
        </>
      ) : (
        <>
          <span>{tooltipMessageMemberCost}</span>
        </>
      )}
    </>
  );
}
