import React, { useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import {
  depedentrelationships as relationships,
  genders,
  countries,
} from "./../../model/constants";
import {
  FormInput,
  FormComboBox,
  FormCheckbox,
  FormMaskedDate,
  FormAutoComplete
} from "../Common/form-components";
import { requiredValidator } from "../Common/validators";
import { Notification } from "@progress/kendo-react-notification";
import { GetFetch } from "../../services/useFetch";
import { urlLinkDepedent } from "../../services/serviceURLs";

export default function EditDependentForm(props) {
  const [showwarning, setShowwarning] = useState(false);

  async function onValueChange(event) {
    if (props.item.id > 0) {
      if (event.value === false) {
        try {
          const saveresponse = await GetFetch(
            `${urlLinkDepedent}${props.item.id}`
          );
          if (saveresponse.ok) {
            const json = await saveresponse.json();
            if (json.count > 0) setShowwarning(true);
            else setShowwarning(false);
          } else throw saveresponse;
        } catch (e) {
          console.log(e.statusText);
        }
      } else setShowwarning(false);
    }
  }
  return (
    <Dialog
      title={
        `${props.item.firstname}` === ""
          ? "Add"
          : `Edit ${props.item.firstname} ${props.item.lastname}`
      }
      onClose={props.cancelEdit}
    >
      <Form
        onSubmit={props.onSubmit}
        initialValues={props.item}
        render={(formRenderProps) => (
          <FormElement>
            {" "}
            <div className="editDependency">
              <fieldset className={"k-form-fieldset"}>
                <div className="heading-form">Basic Information</div>
                <div className="combine-form">
                  <div className="mb-3">
                    <Field
                      key={"firstname"}
                      id={"firstname"}
                      name={"firstname"}
                      label={"*First Name"}
                      component={FormInput}
                      validator={requiredValidator}
                      maxLength={20}
                    />
                  </div>

                  <div className="mb-3">
                    <Field
                      key={"middlename"}
                      id={"middlename"}
                      name={"middlename"}
                      label={"Middle Name"}
                      component={FormInput}
                      maxLength={20}
                    />
                  </div>
                </div>
                <div className="combine-form">
                  <div className="mb-3">
                    <Field
                      key={"lastname"}
                      id={"lastname"}
                      name={"lastname"}
                      label={"*Last Name"}
                      component={FormInput}
                      validator={requiredValidator}
                      maxLength={20}
                    />
                  </div>
                  <div className="mb-3">
                    <Field
                      key={"suffix"}
                      id={"suffix"}
                      name={"suffix"}
                      label={"Suffix"}
                      component={FormInput}
                      maxLength={10}
                    />
                  </div>
                  <div className="mb-3">
                    <Field
                      key={"isActive"}
                      id={"isActive"}
                      name={"isActive"}
                      component={FormCheckbox}
                      label={"Active"}
                      onChange={onValueChange}
                    />
                  </div>
                </div>
                {showwarning === true && (
                  <div className="mb-3">
                    <Notification
                      type={{ style: "warning", icon: true }}
                      closable={false}
                    >
                      This dependent is currently covered, or has been selected
                      to be covered, on one or more policies. Are you sure you
                      want to deactivate them? Doing so will not remove them
                      from current policies, but you will no longer be able to
                      add them to other policies.
                    </Notification>
                  </div>
                )}
                <div>{props.activecount}</div>
                <div className="combine-form">
                  <div className="mb-3">
                    <Field
                      key={"relationship"}
                      id={"relationship"}
                      name={"relationship"}
                      data={relationships}
                      label={"*Relationship"}
                      validator={requiredValidator}
                      component={FormComboBox}
                      suggest={true}
                      placeholder="Select One"
                      disabled={props.item.id > 0 ? true : false}
                    />
                  </div>

                  <div className="mb-3">
                    <Field
                      key={"dateOfBirth"}
                      id={"dateOfBirth"}
                      name={"dateOfBirth"}
                      label={"Date of Birth"}
                      component={FormMaskedDate}
                      disabled={
                        props.item.id > 0
                          ? formRenderProps.valueGetter("relationship") ===
                            `Spouse`
                            ? true
                            : false
                          : false
                      }
                    />
                  </div>
                </div>
                <div className="combine-form">
                  {" "}
                  {`${formRenderProps.valueGetter("relationship")}` ===
                    "Disabled Child" && (
                    <div className="mb-3">
                      <Field
                        key={"disabilitydate"}
                        id={"disabilitydate"}
                        name={"disabilitydate"}
                        label={"Disability Date"}
                        component={FormMaskedDate}
                      />
                    </div>
                  )}
                </div>
                <div className="combine-form">
                  <div className="mb-3">
                    <Field
                      key={"gender"}
                      id={"gender"}
                      name={"gender"}
                      component={FormComboBox}
                      data={genders}
                      label={"Gender"}
                      suggest={true}
                      placeholder="Select One"
                      disabled={
                        props.item.id > 0
                          ? formRenderProps.valueGetter("relationship") ===
                            `Spouse`
                            ? true
                            : false
                          : false
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <Field
                      key={"telephone"}
                      id={"telephone"}
                      name={"telephone"}
                      label={"Telephone"}
                      component={FormInput}
                      maxLength={30}
                    />
                  </div>
                </div>
              </fieldset>
              <fieldset className={"k-form-fieldset"}>
                <div className="heading-form">Address</div>
                <div className="mb-3">
                  <Field
                    key={"line1"}
                    id={"line1"}
                    name={"line1"}
                    label={"Line 1"}
                    component={FormInput}
                    maxLength={100}
                  />
                </div>
                <div className="mb-3">
                  <Field
                    key={"line2"}
                    id={"line2"}
                    name={"line2"}
                    label={"Line 2"}
                    component={FormInput}
                    maxLength={100}
                  />
                </div>
                <div className="mb-3">
                  <Field
                    key={"line3"}
                    id={"line3"}
                    name={"line3"}
                    label={"Line 3"}
                    component={FormInput}
                    maxLength={100}
                  />
                </div>
                <div className="combine-form">
                  <div className="mb-3">
                    <Field
                      key={"city"}
                      id={"city"}
                      name={"city"}
                      label={"City"}
                      component={FormInput}
                      maxLength={50}
                    />
                  </div>

                  {/* <div className="mb-3">
                    <Field
                      key={"country"}
                      id={"country"}
                      name={"country"}
                      label={"Country"}
                      data={countries}
                      component={FormComboBox}
                      suggest={true}
                      placeholder="Select One"
                    />
                  </div> */}
                   <div className="mb-3">
                      <Field
                        key={"country"}
                        id={"country"}
                        name={"country"}
                        label={"Country"}
                        data={countries}
                        component={FormAutoComplete}
                        suggest={true}
                        placeholder="Select One"
                      />
                    </div>
                </div>
                <div className="combine-form">
                  <div className="mb-3">
                    <Field
                      key={"stateprovince"}
                      id={"stateprovince"}
                      name={"stateprovince"}
                      label={"State/Province"}
                      component={FormInput}
                      maxLength={30}
                    />
                  </div>
                  <div className="mb-3">
                    <Field
                      key={"postalcode"}
                      id={"postalcode"}
                      name={"postalcode"}
                      label={"Postalcode"}
                      component={FormInput}
                      maxLength={25}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <Field
                    key={"county"}
                    id={"county"}
                    name={"county"}
                    label={"County"}
                    component={FormInput}
                    maxLength={100}
                  />
                </div>
              </fieldset>
            </div>
            <DialogActionsBar>
              <button
                type={"submit"}
                className="k-button k-primary"
                onClick={formRenderProps.onSubmit}
                disabled={!formRenderProps.allowSubmit}
              >
                {`${props.item.firstname}` === "" ? "Save" : "Update"}
              </button>
              <button
                type={"button"}
                className="k-button"
                onClick={props.cancelEdit}
              >
                Cancel
              </button>
            </DialogActionsBar>
          </FormElement>
        )}
      />
    </Dialog>
  );
}
