import * as React from "react";

import { Field } from "@progress/kendo-react-form";
import { requiredValidator } from "../Common/validators";
import {
  FormInput,
  FormComboBox,
  FormNumericTextBox,
} from "../Common/form-components";

export const QuestionIndividual = (props) => {
  return (
    <div className="question">
      {props.question.questionType === "Text" && (
        <>
          <p>{`*${props.question.questionText}`}</p>
          <Field
            key={`questions.${props.question.questionID}`}
            id={`questions.${props.question.questionID}`}
            name={`questions.${props.question.questionID}`}
            component={FormInput}
            validator={requiredValidator}
          />
        </>
      )}

      {/* {props.question.QuestionType==='Text' && props.question.Requiered=== 'N' &&
        <Field
        key={`questions.${props.question.QuestionID}`}
        id={`questions.${props.question.QuestionID}`}
      name={`questions.${props.question.QuestionID}`}
       label={props.question.QuestionText}
        component={FormInput}
      
   />
        } */}
      {props.question.questionType === "Dropdown" && (
        <>
          <p>{`*${props.question.questionText}`}</p>
          <Field
            key={`questions.${props.question.questionID}`}
            id={`questions.${props.question.questionID}`}
            name={`questions.${props.question.questionID}`}
            data={props.question.answerChoices.split("|")}
            validator={requiredValidator}
            component={FormComboBox}
            suggest={true}
            placeholder="Select One"
          />
        </>
      )}
      {/* {props.question.QuestionType==='Dropdown' && props.question.Requiered=== 'N' && <Field
                                     key={`questions.${props.question.QuestionID}`}
                                     id={`questions.${props.question.QuestionID}`}
                                   name={`questions.${props.question.QuestionID}`}
                                   data={props.question.AnswerChoices.split('|')}
                                   label={props.question.QuestionText}
                                   component={FormComboBox}
                                   suggest={true}
                                   placeholder="Select One"
                               
                                />
      } */}
      {props.question.questionType === "Currency" && (
        <Field
          key={`questions.${props.question.questionID}`}
          id={`questions.${props.question.questionID}`}
          name={`questions.${props.question.questionID}`}
          label={`*${props.question.questionText}`}
          component={FormNumericTextBox}
          validator={requiredValidator}
          format="c2"
          min={0}
        />
      )}
      {/* {props.question.QuestionType==='Currency' && props.question.Requiered=== 'N' && <Field
                                  key={`questions.${props.question.QuestionID}`}
                                  id={`questions.${props.question.QuestionID}`}
                                name={`questions.${props.question.QuestionID}`}
                                label={props.question.QuestionText}
                                component={FormNumericTextBox}
                                format="c2"
                             
                            />
      } */}
    </div>
  );
};
