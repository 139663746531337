import React, { useState, useEffect } from "react";
import { urlElectionCost } from "../../services/serviceURLs";
import { Notification } from "@progress/kendo-react-notification";
import {
  currencyFormat,
  dateFormat,
  datewotimestampFormat,
} from "../../services/shareconstant";
import { STATUS } from "../../model/constants";
import Spinner from "../Spinner";

export const PlanCost = (props) => {
  const [status, setStatus] = useState(STATUS.IDLE);
  const [getError, setGetError] = useState(null);
  //const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [electionCost, setelectionCost] = useState([]);

  const effectivedate = dateFormat(props.electionDates.effectivedate)
    ? dateFormat(props.electionDates.effectivedate)
    : datewotimestampFormat(props.electionDates.effectivedate);

  useEffect(() => {
    setStatus(STATUS.SUBMITTING);
    setGetError(null);
    setError(false);
    let attributes = {};
    if (props.plan.enrollmentPages.includes("coverage")) {
      props.coverageData.forEach(function (coverage) {
        attributes = {
          ...attributes,
          [coverage.name]: props.formProps
            .valueGetter(coverage.name)
            .toString(),
        };
      });
    }

    let enrollmentQuestions = [];
    if (props.plan.enrollmentPages.includes("questions")) {
      for (const property in props.formProps.valueGetter("questions")) {
        const tempQuestion = {
          QuestionID: parseInt(property),
          Answer: props.formProps.valueGetter(`questions.${property}`),
        };
        enrollmentQuestions.push(tempQuestion);
      }
    }

      const requestOptions = {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      };
      try {
        fetch(
          `${urlElectionCost}${props.insuranceDraftElectionID}/${props.plan.insuranceID}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            setelectionCost(result);
            setStatus(STATUS.COMPLETED);
          });
      } catch (e) {
        setGetError(e.statusText);
        setStatus(STATUS.ERROR);
        // setSuccess(false);
        setError(true);
      }
  }, [
    props.coverageData,
    props.formProps,
    props.plan.enrollmentPages,
    props.plan.insuranceID,
    props.plan.insurancePlanTypeID,
    props.insuranceDraftElectionID
  ]);

  return (
    <>
      {status === STATUS.SUBMITTING && <Spinner />}

      {error && (
        <Notification type={{ style: "error", icon: true }} closable={false}>
          {getError}
        </Notification>
      )}
      <div className="plan-cost">
        <p className="StepName-Mbl visible-Mb">Plan Cost</p>
        <p>Please review your selections. </p> <br />
        <Notification type={{ style: "info", icon: true }} closable={false}>
          {" "}
          This may not take effect until your information is submitted and
          approved. You will see the appropriate cost reflected in your invoice.
        </Notification>
        <br />
        <br />
        {electionCost && (
          <div>
            {electionCost.summaryAttributes &&
              Object.keys(electionCost.summaryAttributes).map((key, i) => (
                <p key={i}>
                  <span> {key} : </span>
                  <span>
                    {" "}
                    {isNaN(electionCost.summaryAttributes[key])
                      ? electionCost.summaryAttributes[key]
                      : currencyFormat(
                        parseFloat(electionCost.summaryAttributes[key])
                      )}
                  </span>
                </p>
              ))}
            <br />
            {/* <p>Effective Date: {dateFormat(electionCost.effectiveDate)}</p> */}
            {electionCost.estimatedMonthlyCost >=-1 ? (
                   <><p>Effective Date: {effectivedate}</p><p>
                        Estimated Monthly Cost:{" "}
                        {electionCost.estimatedMonthlyCost === -1 ? 'TBD' : electionCost.estimatedMonthlyCost > 0 ? currencyFormat(parseFloat(electionCost.estimatedMonthlyCost)) : "TBD"}
                      </p></>
                  ) : null
            }            
          </div>
        )}
      </div>
    </>
  );
};
