import React from "react";
import ReactDOM from "react-dom";

import "./styles/kendo-theme-bootstrap.css";
import "./styles/index.css";
//import 'bootstrap/dist/css/bootstrap.css';
//import './styles/theme/AlpaTheme.css';
//import '@progress/kendo-theme-bootstrap/dist/all.css'
import App from "./components/App";
import ErrorBoundary from "./components/ErrorBoundary";
import { BrowserRouter as Router, Route } from "react-router-dom";
import AuthProvider from "./components/AuthProvider";
import ScrollToTop from "./components/ScrollToTop";
//import reportWebVitals from './reportWebVitals';
// Comment added by Sachin
ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Router>
        <ScrollToTop />
        <AuthProvider>
          <Route component={App} />
        </AuthProvider>
      </Router>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
