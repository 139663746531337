import React from "react";
import { Fetch } from "./../../services/useFetch";
import Spinner from "./../../components/Spinner";
import PageNotFound from "./../../components/PageNotFound";
import PersonalInformationForm from "./PersonalInformationForm";
import { urlPersonalInformation } from "../../services/serviceURLs";

export default function PersonalInformation() {
  return (
    <Fetch url={urlPersonalInformation}>
      {(data, loading, error) => {
        if (loading) return <Spinner />;
        if (!data) return <PageNotFound />;
        if (error) throw error;
        return <PersonalInformationForm data={data} />;
      }}
    </Fetch>
  );
}
