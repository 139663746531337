import React, { Component , Suspense} from "react";
import { urlLogout } from "../services/serviceURLs";
import { withRouter } from "react-router";
import { AuthContext } from "./AuthContext";
import { redirectHome, clearLocalStorage } from "../services/shareconstant";
import { urlCompanyAffilliatedCountry } from "../services/serviceURLs";
//import Spinner from "./Spinner";
import { Loader } from "@progress/kendo-react-indicators";
class AuthProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userinfoglobal: {},
      redirectLoginURL: "",
      CompanyAffilliatedCountry:"",
    };
  }
 
  
  addUserHandler = (user) => {
    const redirect = this.state.redirectLoginURL;
    const requestOptions = {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    };

    fetch(urlCompanyAffilliatedCountry, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          this.setState(
            {
              CompanyAffilliatedCountry:result.includes("CA")?"CA":"US"
            });            
        })
    this.setState(
      {
        userinfoglobal: user,
        redirectLoginURL: "",
      },
      () => {
        this.state.CompanyAffilliatedCountry==="CA"?
        this.props.history.push("/Restricted"):(
        this.state.CompanyAffilliatedCountry!=="CA" && redirect.includes("Restricted")?
        this.props.history.push(redirectHome()):(
        this.props.history.push(
          redirect === "" ? redirectHome() : JSON.parse(redirect)
        )));
      }
    );
  };

  addRedirectOnLoginHandler = (redirectURL) => {
    this.setState({ redirectLoginURL: redirectURL });
  };

  removeUserHandler = (resetRediect) => {
    //console.log("test logout");
    resetRediect === true
      ? this.setState({ userinfoglobal: {}, redirectLoginURL: "" })
      : this.setState({
          userinfoglobal: {},
          redirectLoginURL: this.props.history.location,
          CompanyAffilliatedCountry:"",
        });
        this.props.history.push('/logout');
  };

  clearStorage = () => {
    this.props.history.push(redirectHome());
  };

  reLogin = () => {
    this.logout(this.removeUserHandler(false));
    this.login();
  };

  logout = (resetRediect = true) => {
    const requestOptions = {
      method: "POST",
      credentials: "include",
      redirect: "follow",
    };

    fetch(urlLogout, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        clearLocalStorage();
        this.removeUserHandler(resetRediect);
      })
      .catch((error) => console.log("error", error));
  };

  login = () => {
    clearLocalStorage();
    window.location.href = process.env.REACT_APP_API_SSO_URL;
    return null;
  };

  render() {
    return (
      <>
      {/* <Suspense Fallback={<Spinner/>}> */}
      <Suspense Fallback={ <div className="col-4 spinnerLoading">
      <Loader type="infinite-spinner" size="large" themeColor="primary" />
    </div>}>
        <AuthContext.Provider
          value={{
            state: this.state,
            addUserHandler: this.addUserHandler,
            removeUserHandler: this.removeUserHandler,
            logout: this.logout,
            login: this.login,
            clearStorage: this.clearStorage,
            addRedirectOnLoginHandler: this.addRedirectOnLoginHandler,
            reLogin: this.reLogin,
          }}
        >
          {this.props.children}
        </AuthContext.Provider>
        </Suspense>
      </>
    );
  }
}

export default withRouter(AuthProvider);
