import React, { useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardActions,
  CardSubtitle,
} from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import { Notification } from "@progress/kendo-react-notification";
import { STATUS } from "../../model/constants";
import { PostSave } from "../../services/useFetch";
import { urlEnrollmentSave } from "../../services/serviceURLs";
import Spinner from "../Spinner";
import CurrentCoverageCard from "./CurrentCoverageCard";
import {
  redirectHome,
  errorSaveMessage,
  SuccessSaveMessage,
  undoMessage1,
  undoMessage2,
} from "../../services/shareconstant";
import CustomButtonToolTip from "./CustomButtonToolTip";
import { Tooltip } from "@progress/kendo-react-tooltip";

export default function PendingUpdateElectionsForNonFuturePlans(props) {
  const [openCancelForm, setOpenCancelForm] = useState(false);
  const [cancelCard, setCancelCard] = useState({});
  const [status, setStatus] = useState(STATUS.IDLE);
  const [saveError, setSaveError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [electionMsg, setElectionMsg] = useState(``);
  const history = useHistory();

  const modifyClick = (card) => {
    history.push({
      pathname: `/plan`,
      state: { plan: card, electionDates: props.electionDates },
    });
  };
  const EOIClick = (card) => {
    history.push({
      pathname: `/eoirequest`
    });
  };
  const cancelClick = (card) => {
    setCancelCard(card);
    setOpenCancelForm(true);
  };

  const toggleDialog = () => {
    setOpenCancelForm(!openCancelForm);
  };

  const onCancelConfirm = async () => {
    try {
      setOpenCancelForm(false);
      setCancelCard({});
      setStatus(STATUS.SUBMITTING);
      const saveElection = {
        electionType: `CancelPending`,
        insuranceElectionID: cancelCard.insuranceElectionID,
        insuranceID: cancelCard.insuranceID,
      };
      const saveresponse = await PostSave(
        `${urlEnrollmentSave}`,
        `POST`,
        saveElection
      );
      setSaveError(null);

      if (saveresponse.ok) {
        const electionresult = await saveresponse.json();
        setElectionMsg(electionresult.Message);
        setSuccess(true);
        setError(false);
        setStatus(STATUS.COMPLETED);
      } else {
        throw saveresponse;
      }
    } catch (e) {
      setSaveError(e.statusText);
      setStatus(STATUS.ERROR);
      setSuccess(false);
      setError(true);
    }
  };

  function handleCloseDialog() {
    history.push(redirectHome());
  }

  return (
    <>
      {status === STATUS.SUBMITTING && <Spinner />}

      {(success || error) && (
        <Dialog onClose={handleCloseDialog}>
          {success && electionMsg === "Cancelled" && (
            <Notification
              type={{ style: "success", icon: true }}
              closable={false}
            >
              <span>{SuccessSaveMessage}</span>
            </Notification>
          )}
          {error && (
            <Notification
              type={{ style: "error", icon: true }}
              closable={false}
            >
              <span>
                {errorSaveMessage} {saveError}
              </span>
            </Notification>
          )}

          <DialogActionsBar>
            <button className="k-button" onClick={handleCloseDialog}>
              OK
            </button>
          </DialogActionsBar>
        </Dialog>
      )}

      {openCancelForm && (
        <Dialog
          className="confirmUndo"
          title={"Confirm Undo"}
          onClose={toggleDialog}
        >
          <p>{undoMessage1} </p>
          <CardSubtitle>
            {cancelCard.insurancePlanTypeDescription}
          </CardSubtitle>
          <p>{undoMessage2} </p>

          <DialogActionsBar>
            <Button primary={true} onClick={onCancelConfirm}>
              {" "}
              Confirm
            </Button>
            <Button primary={false} onClick={toggleDialog}>
              {" "}
              Cancel
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}

      {props.pendingUpdateElectionsForNonFuturePlans.map((card, index) => {
        return (
          <div key={index} className="card-items">
            <Card>
              <div>
                <CardBody>
                <br/>
                  <CurrentCoverageCard
                    key={index}
                    card={card}
                    current={false}
                  ></CurrentCoverageCard>
                </CardBody>
                <CardActions>
                  <div className="pending-action">
                    <Label style={{ color: "Red" }}> Unsubmitted Changes</Label>
                  </div>
                  <div className="eoi-action">
                      {card.status==='EOI Pending' ? (
                          <Button
                            primary={true}
                            className="k-flat"
                            onClick={() => EOIClick(card)}
                          >
                            {" "}
                            EOI Pending{" "}
                          </Button>
                      ):null}  
                  </div>
                  <div className="main-action">
                    {" "}                    
                    {card.insurancePlanTypeName!=="MLUM" && card.insurancePlanTypeName!=="MLUS" ? (       
                    <Button
                      primary={true}
                      className="k-flat"
                      onClick={() => modifyClick(card)}
                    >
                      {" "}
                      Modify{" "}
                    </Button>
                    ):<Tooltip
                    content={(prop) => (
                      <CustomButtonToolTip/>
                    )}
                    anchorElement="target"
                    position="bottom"
                  > <span className="k-button k-primary k-flat k-i-information disabledControlButton"
                    title="Modify">Modify</span></Tooltip>}
                    <Button
                      primary={true}
                      className="k-flat"
                      onClick={() => cancelClick(card)}
                    >
                      Undo{" "}
                    </Button>
                  </div>
                </CardActions>
              </div>
            </Card>
          </div>
        );
      })}
    </>
  );
}
