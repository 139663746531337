import React, { useContext } from "react";
import { AuthContext } from "./AuthContext";
import { Button } from "@progress/kendo-react-buttons";

export default function PageNotFound() {
  const authContext = useContext(AuthContext);
  return (
    
    authContext.Login()
      /* <h1>Page not found.</h1>

      <p>
        It might be due to Logout from another session or timeout, Click on Log
        In by try again.{" "}
      </p>
      <Button onClick={() => authContext.reLogin()} primary={true}>
        Log In
      </Button> */
    
  );
}
