import React, { useState, useContext } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import FamilyReviewDependentForm from "./FamilyReviewDependentForm";
import { STATUS } from "../../model/constants";
import Spinner from "./../Spinner";
import { PostSave } from "../../services/useFetch";
import { urlDependents } from "../../services/serviceURLs";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { AuthContext } from "../AuthContext";
import { ChangesSaveMessage } from "../../services/shareconstant";
import { Label } from "@progress/kendo-react-labels";

export const FamilyReview = (props) => {
  const authContext = useContext(AuthContext);
  const loggedinpersonid = authContext.state.userinfoglobal.LinkId;
  const filteredDependent = props.dependents.filter(
    (item) => item.inPlan === true
  );
  const [openAddForm, setOpenAddForm] = useState(false);
  const [status, setStatus] = useState(STATUS.IDLE);
  const [saveError, setSaveError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [editItem, setEditItem] = useState({});

  const emptyDependent = {
    id: -1,
    personid: loggedinpersonid,
    firstname: "",
    lasttname: "",
    dateOfBirth: "",
    gender: "",
    relationship: "",
    line1: "",
    line2: "",
    line3: "",
    city: "",
    country: "",
    stateprovince: "",
    postalcode: "",
    county: "",
    telephone: "",
    middlename: "",
    suffix: "",
    disabilitydate: "",
    inPlan: true,
    isActive: true,
  };

  const RemoveCommandCell = (props) => {
    return (
      <td className="editBtn">
        <Button
          primary={false}
          onClick={() => props.enterRemove(props.dataItem)}
        >
          <span className="k-icon k-i-delete"></span>
        </Button>
      </td>
    );
  };

  const MyRemoveCommandCell = (props) => (
    <RemoveCommandCell {...props} enterRemove={enterRemove} />
  );

  const EditCommandCell = (props) => {
    return (
      <td className="editBtn">
        <Button primary={false} onClick={() => props.enterEdit(props.dataItem)}>
          <span className="k-icon k-i-edit"></span>
        </Button>
      </td>
    );
  };

  const MyEditCommandCell = (props) => (
    <EditCommandCell {...props} enterEdit={enterEdit} />
  );

  const enterEdit = (dataItem) => {
    //Date format with moment to handle date issue
    //dataItem.dateOfBirth = (dataItem.dateOfBirth === null || dataItem.dateOfBirth === 'null' || dataItem.dateOfBirth === '' || dataItem.dateOfBirth === '1900-01-01')? '': new Date(moment(dataItem.dateOfBirth).format("YYYY/MM/DD"));
    dataItem.dateOfBirth =
      dataItem.dateOfBirth === null ||
      dataItem.dateOfBirth === "null" ||
      dataItem.dateOfBirth === "" ||
      dataItem.dateOfBirth === "1900-01-01"
        ? ""
        : new Date(dataItem.dateOfBirth);
    dataItem.disabilitydate =
      dataItem.disabilitydate === null ||
      dataItem.disabilitydate === "null" ||
      dataItem.disabilitydate === "" ||
      dataItem.disabilitydate === "1900-01-01" ||
      dataItem.relationship !== "Disabled Child"
        ? ""
        : new Date(dataItem.disabilitydate);
    setEditItem(dataItem);
    setOpenForm(true);
  };

  const enterRemove = (dataItem) => {
    const arr = [...props.dependents];
    const index = arr.findIndex((x) => x.id === dataItem.id);
    dataItem.inPlan = false;
    arr[index] = dataItem;
    props.setDependents(arr);
  };

  const enterAdd = (event) => {
    event.preventDefault();
    setOpenAddForm(true);
  };
  function handleAddCancelEdit() {
    setOpenAddForm(false);
  }

  function handleCancelEdit() {
    setOpenForm(false);
  }

  async function handleSubmit(event) {
    if (event.id > 0) {
      setOpenForm(false);
    } else {
      setOpenAddForm(false);
    }
    //Update Event Value based on Condition
    // event.dateOfBirth = (event.dateOfBirth === null || event.dateOfBirth === '' || event.dateOfBirth === '0001-01-01T00:00:00')? '1900-01-01': new Date(moment(event.dateOfBirth).format("YYYY-MM-DD"));
    event.dateOfBirth =
      event.dateOfBirth === null ||
      event.dateOfBirth === "" ||
      event.dateOfBirth === "1900-01-01" ||
      event.dateOfBirth === "0001-01-01T00:00:00"
        ? "1900-01-01"
        : event.dateOfBirth.toLocaleDateString();
    event.disabilitydate =
      event.disabilitydate === null ||
      event.disabilitydate === "" ||
      event.disabilitydate === "1900-01-01" ||
      event.disabilitydate === "0001-01-01T00:00:00" ||
      event.relationship !== "Disabled Child"
        ? "1900-01-01"
        : event.disabilitydate.toLocaleDateString();
    setStatus(STATUS.SUBMITTING);

    try {
      const saveresponse = await PostSave(
        `${urlDependents}/${event.id}`,
        `POST`,
        event
      );
      event.dateOfBirth =
        event.dateOfBirth === null ||
        event.dateOfBirth === "" ||
        event.dateOfBirth === "1900-01-01"
          ? ""
          : new Date(event.dateOfBirth);
      event.disabilitydate =
        event.disabilitydate === null ||
        event.disabilitydate === "" ||
        event.disabilitydate === "1900-01-01" ||
        event.relationship !== "Disabled Child"
          ? ""
          : new Date(event.disabilitydate);
      setSaveError(null);
      if (saveresponse.ok) {
        if (event.id > 0) {
          const arr = [...props.dependents];
          let index = arr.findIndex((x) => x.id === event.id);
          arr[index] = event;
          props.setDependents(arr);
        } else {
          const json = await saveresponse.json();
          const newDependent = { ...event, id: json.id };
          props.setDependents((olddependents) => [
            ...olddependents,
            newDependent,
          ]);
        }
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
        setError(false);
        setStatus(STATUS.COMPLETED);
      } else {
        throw saveresponse;
      }
    } catch (e) {
      setSaveError(e.statusText);
      setStatus(STATUS.ERROR);
      setSuccess(false);
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  }

  return (
    <div>
      <p className="StepName-Mbl visible-Mb">{props.labelValue}</p>
      {/* <div className="heading-form">My Family</div> */}
      <Notification type={{ style: "info", icon: true }} closable={false}>
        {" "}
        Update your dependent data with current information if applicable.
        Please note that updating your dependent information here does not automatically enroll your dependents in an insurance product,
        nor does it designate them as beneficiaries. For survivor's benefit, you must separately add your
        beneficiaries to the coverage you want them to receive. If you would like your dependents to be covered under any of your plans, you must also add them separately to the desired programs.
      </Notification>
      <div className="wrap-header">
        <div className="heading2-form">{props.primaryInsuredParty}</div>
        <div className="mb-3 wraper-btn-add-beneficiary">
          <Button primary={true} onClick={enterAdd}>
            Add New Dependent
          </Button>
        </div>
        {openAddForm && (
          <FamilyReviewDependentForm
            cancelEdit={handleAddCancelEdit}
            onSubmit={handleSubmit}
            item={emptyDependent}
            dependents={props.dependents}
            setDependents={props.setDependents}
            isAdd={true}
          />
        )}
        {status === STATUS.SUBMITTING && <Spinner />}

        <div className="mb-3 notificationGrp">
          <NotificationGroup
            style={{
              alignItems: "flex-start",
              flexWrap: "wrap",
            }}
          >
            <Fade enter={true} exit={true}>
              {success && (
                <Notification
                  type={{ style: "success", icon: true }}
                  closable={true}
                  onClose={() => setSuccess(false)}
                >
                  <span>{ChangesSaveMessage}</span>
                </Notification>
              )}
            </Fade>
            <Fade enter={true} exit={true}>
              {error && (
                <Notification
                  type={{ style: "error", icon: true }}
                  closable={true}
                  onClose={() => setError(false)}
                >
                  <span> {saveError}</span>
                </Notification>
              )}
            </Fade>
          </NotificationGroup>
        </div>
        <div className="mb-3 styledGrid reviewDependentsGrid">
          <Grid data={filteredDependent}>
            <Column field="firstname" title="First Name" />
            <Column field="lastname" title="Last Name" />
            <Column
              field="dateOfBirth"
              title="Date of Birth"
              format="{0:MM/dd/yyyy}"
            />
            <Column field="relationship" title="Relationship" />
            <Column field="gender" title="Gender" />
            <Column cell={MyEditCommandCell} title="Edit" />
            <Column cell={MyRemoveCommandCell} title="Remove" />
          </Grid>
          {openForm && (
            <FamilyReviewDependentForm
              cancelEdit={handleCancelEdit}
              onSubmit={handleSubmit}
              item={editItem}
              dependents={props.dependents}
              setDependents={props.setDependents}
              isAdd={false}
            />
          )}
        </div>
      </div>

      {props.benificaryValidMsg !== `` && (
        <Label style={{ color: "Red" }}> {props.dependentValidMsg}</Label>
      )}
    </div>
  );
};
