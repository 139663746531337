import React from "react";
import { Fetch } from "../../services/useFetch";
import Spinner from "../Spinner";
import PageNotFound from "../PageNotFound";
import ReviewDependentsForm from "./ReviewDependentsForm";
import { urlDependents } from "../../services/serviceURLs";

export default function ReviewDependents(props) {
  return (
    <Fetch url={urlDependents}>
      {(data, loading, error) => {
        if (loading) return <Spinner />;
        if (!data) return <PageNotFound />;
        if (error) throw error;

        return (
          <ReviewDependentsForm
            data={data}
            showBackButton={props.showBackButton}
          />
        );
      }}
    </Fetch>
  );
}
