import React, { useState, useContext } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { CoverageBeneficiaryForm } from "./CoverageBeneficiaryForm";
import { STATUS, types } from "../../model/constants";
import Spinner from "./../Spinner";
import { PostSave } from "../../services/useFetch";
import { urlBeneficiaries } from "../../services/serviceURLs";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { AuthContext } from "../AuthContext";
import { Label } from "@progress/kendo-react-labels";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { CopyBeneficiaryForm } from "./CopyBeneficiaryForm";
import { ChangesSaveMessage } from "../../services/shareconstant";

//Numeric Textbox cell
const MyCustomPercentageCell = (props) => {
  const handleOnChange = (e) => {
    props.onChange({
      dataItem: props.dataItem,
      field: props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.value,
    });
  };
  return (
    <td>
      <NumericTextBox
        required
        value={props.dataItem[props.field]}
        format="###"
        min={1}
        max={100}
        step={1}
        onChange={handleOnChange}
      />
    </td>
  );
};
//Till Here

export const CoverageBeneficiary = (props) => {
  const editField = "inEdit";
  const authContext = useContext(AuthContext);
  const loggedinpersonid = authContext.state.userinfoglobal.LinkId;
  const [openAddForm, setOpenAddForm] = useState(false);
  const filteredBeneficiary = props.beneficiaries.filter(
    (item) => item.inPlan === true
  );
  const [status, setStatus] = useState(STATUS.IDLE);
  const [saveError, setSaveError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [editItem, setEditItem] = useState({});
  const [openCopyForm, setOpenCopyForm] = useState(false);
  // const [type, setType] = useState('');
  // const [percentage, setPercentage] = useState('');

  const emptyBeneficiary = {
    id: -1,
    personid: loggedinpersonid,
    name: "",
    dateOfBirth: "",
    gender: "",
    relationship: "",
    isActive: true,
    beneficiarytype: "Person",
    taxidentifier: "",
    line1: "",
    line2: "",
    line3: "",
    city: "",
    country: "",
    stateprovince: "",
    postalcode: "",
    county: "",
    telephone: "",
    dateOfTrust: "",
    inPlan: true,
    type: "Primary",
    percentage: 0,
    inEdit: true,
  };

  function handleTypeChange(e) {
    let arr = [...props.beneficiaries];
    arr = arr.map((item) =>
      item.id === e.dataItem.id ? { ...item, [e.field]: e.value } : item
    );
    props.setBeneficiaries(arr);
  }
  const CustomTypeCell = (props) => {
    return (
      <td>
        <DropDownList
          value={types.find((type) => type === props.dataItem[props.field])}
          data={types}
          onChange={(e) => {
            props.onChange({
              dataItem: props.dataItem,
              field: props.field,
              syntheticEvent: e.syntheticEvent,
              value: e.target.value,
            });
          }}
        />
      </td>
    );
  };

  const MyCustomTypeCell = (props) => (
    <CustomTypeCell {...props} onChange={handleTypeChange} />
  );
  //Ends Here

  //Remove Cell
  const RemoveCommandCell = (props) => {
    return (
      <td className="editBtn">
        <Button
          primary={false}
          onClick={() => props.enterRemove(props.dataItem)}
        >
          <span className="k-icon k-i-delete"> </span>
        </Button>
      </td>
    );
  };

  const MyRemoveCommandCell = (props) => (
    <RemoveCommandCell {...props} enterRemove={enterRemove} />
  );

  const enterRemove = (dataItem) => {
    const arr = [...props.beneficiaries];
    const index = arr.findIndex((x) => x.id === dataItem.id);
    dataItem.inPlan = false;
    dataItem.percentage = 0; //Need to update original percent value
    //dataItem.type = ''; //Not required to set blank
    arr[index] = dataItem;
    props.setBeneficiaries(arr);
  };
  function handleAddCancelEdit() {
    setOpenAddForm(false);
  }
  function handleCancelEdit() {
    setOpenForm(false);
  }

  function handleCopyCancel() {
    setOpenCopyForm(false);
  }
  //Edit Cell
  const EditCommandCell = (props) => {
    return (
      <td className="editBtn">
        <Button primary={false} onClick={() => props.enterEdit(props.dataItem)}>
          <span className="k-icon k-i-edit"></span>
        </Button>
      </td>
    );
  };

  const MyEditCommandCell = (props) => (
    <EditCommandCell {...props} enterEdit={enterEdit} />
  );

  const enterEdit = (dataItem) => {
    //Date format with moment to handle date issue
    //dataItem.dateOfBirth = (dataItem.dateOfBirth === null || dataItem.dateOfBirth === 'null' || dataItem.dateOfBirth === '' || dataItem.dateOfBirth === '1900-01-01') ? '' : new Date(moment(dataItem.dateOfBirth).format("YYYY/MM/DD"));
    dataItem.dateOfBirth =
      dataItem.dateOfBirth === null ||
      dataItem.dateOfBirth === "null" ||
      dataItem.dateOfBirth === "" ||
      dataItem.dateOfBirth === "1900-01-01"
        ? ""
        : new Date(dataItem.dateOfBirth);
    dataItem.dateOfTrust =
      dataItem.dateOfTrust === null ||
      dataItem.dateOfTrust === "null" ||
      dataItem.dateOfTrust === "" ||
      dataItem.dateOfTrust === "1900-01-01" ||
      dataItem.dateOfTrust === "0001-01-01T00:00:00" ||
      dataItem.beneficiarytype !== "Trust"
        ? ""
        : new Date(dataItem.dateOfTrust);
    setEditItem(dataItem);
    setOpenForm(true);
  };

  async function handleSubmit(event) {
    if (event.id > 0) {
      setOpenForm(false);
    } else {
      setOpenAddForm(false);
    }

    //Update Event Value based on Condition
    event.relationship =
      event.beneficiarytype !== "Person" ? "" : event.relationship;
    event.telephone = event.beneficiarytype !== "Person" ? "" : event.telephone;
    event.gender = event.beneficiarytype !== "Person" ? "" : event.gender;

    //Update taxidentifier for My Estate & Person Type, Address fields for My Estate Type
    event.taxidentifier =
      event.beneficiarytype === "My Estate" ||
      event.beneficiarytype === "Person"
        ? ""
        : event.taxidentifier;
    event.name = event.beneficiarytype === "My Estate" ? "" : event.name;
    event.line1 = event.beneficiarytype === "My Estate" ? "" : event.line1;
    event.line2 = event.beneficiarytype === "My Estate" ? "" : event.line2;
    event.line3 = event.beneficiarytype === "My Estate" ? "" : event.line3;
    event.city = event.beneficiarytype === "My Estate" ? "" : event.city;
    event.country = event.beneficiarytype === "My Estate" ? "" : event.country;
    event.stateprovince =
      event.beneficiarytype === "My Estate" ? "" : event.stateprovince;
    event.postalcode =
      event.beneficiarytype === "My Estate" ? "" : event.postalcode;
    event.county = event.beneficiarytype === "My Estate" ? "" : event.county;
    // event.dateOfBirth = (event.dateOfBirth === null || event.dateOfBirth === 'null' || event.dateOfBirth === '' || event.dateOfBirth === '0001-01-01T00:00:00' || event.beneficiarytype !== 'Person') ? '1900-01-01' : new Date(moment(event.dateOfBirth).format("YYYY-MM-DD"));
    event.dateOfBirth =
      event.dateOfBirth === null ||
      event.dateOfBirth === "null" ||
      event.dateOfBirth === "" ||
      event.dateOfBirth === "1900-01-01" ||
      event.dateOfBirth === "0001-01-01T00:00:00" ||
      event.beneficiarytype !== "Person"
        ? "1900-01-01"
        : event.dateOfBirth.toLocaleDateString();
    event.dateOfTrust =
      event.dateOfTrust === null ||
      event.dateOfTrust === "null" ||
      event.dateOfTrust === "" ||
      event.dateOfTrust === "1900-01-01" ||
      event.dateOfTrust === "0001-01-01T00:00:00" ||
      event.dateOfTrust === "0001-01-01T00:00:00" ||
      event.beneficiarytype !== "Trust"
        ? "1900-01-01"
        : event.dateOfTrust.toLocaleDateString();
    setStatus(STATUS.SUBMITTING);

    try {
      const saveresponse = await PostSave(
        `${urlBeneficiaries}/${event.id}`,
        `POST`,
        event
      );
      // To handle null date
      //event.dateOfBirth = (event.dateOfBirth === null || event.dateOfBirth === '' || event.dateOfBirth === '1900-01-01' || event.beneficiarytype !== 'Person') ? '' : new Date(moment(event.dateOfBirth).format("YYYY-MM-DD"));
      event.dateOfBirth =
        event.dateOfBirth === null ||
        event.dateOfBirth === "" ||
        event.dateOfBirth === "1900-01-01"
          ? ""
          : new Date(event.dateOfBirth);
      event.dateOfTrust =
        event.dateOfTrust === null ||
        event.dateOfTrust === "" ||
        event.dateOfTrust === "1900-01-01" ||
        event.beneficiarytype !== "Trust"
          ? ""
          : new Date(event.dateOfTrust);
      setSaveError(null);
      if (saveresponse.ok) {
        if (event.id > 0) {
          const arr = [...props.beneficiaries];
          const index = arr.findIndex((x) => x.id === event.id);
          arr[index] = event;
          props.setBeneficiaries(arr);
        } else {
          const json = await saveresponse.json();
          const newBeneficiary = { ...event, id: json.id };

          props.setBeneficiaries((newBeneficiaries) => [
            ...newBeneficiaries,
            newBeneficiary,
          ]);
        }
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
        setError(false);
        setStatus(STATUS.COMPLETED);
      } else {
        throw saveresponse;
      }
    } catch (e) {
      setSaveError(e.statusText);
      setStatus(STATUS.ERROR);
      setSuccess(false);
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  }

  function handleAdd(event) {
    event.preventDefault();
    setOpenAddForm(true);
  }

  //Split functionality
  function splitPercentage(event) {
    event.preventDefault();
    const reservedPercent = 100;

    const primaryBeneficiary = filteredBeneficiary.filter(
      (item) => item.type === "Primary" && item.inPlan === true
    );
    let evenPrimaryPercent =
      primaryBeneficiary.length > 0
        ? reservedPercent / primaryBeneficiary.length
        : reservedPercent;
    evenPrimaryPercent = Math.round(evenPrimaryPercent);

    //const SecondaryBeneficiary =props.beneficiaries.filter(item=>item.type === 'Secondary' && item.inPlan === true );
    const SecondaryBeneficiary = filteredBeneficiary.filter(
      (item) => item.type === "Secondary" && item.inPlan === true
    );
    let evenSecondaryPercent =
      SecondaryBeneficiary.length > 0
        ? reservedPercent / SecondaryBeneficiary.length
        : reservedPercent;
    evenSecondaryPercent = Math.round(evenSecondaryPercent);

    //Primary Split functionality
    let arrPrimary = [...props.beneficiaries]; //original array
    arrPrimary = arrPrimary.map((item) => {
      if (item.type === "Primary" && item.inPlan === true) {
        //item.percentage = evenPrimaryPercent.toFixed(2);
        item.percentage = evenPrimaryPercent;

        let lastrecvalue =
          reservedPercent -
          evenPrimaryPercent * (primaryBeneficiary.length - 1);
        arrPrimary = arrPrimary.filter(
          (item) => item.type === "Primary" && item.inPlan === true
        );
        arrPrimary[arrPrimary.length - 1].percentage = lastrecvalue;
      }
      return item;
    });
    props.setBeneficiaries(arrPrimary);

    let arrSecondary = [...props.beneficiaries]; //original array
    arrSecondary = arrSecondary.map((item) => {
      if (item.type === "Secondary" && item.inPlan === true) {
        //item.percentage = evenSecondaryPercent.toFixed(2);
        item.percentage = evenSecondaryPercent;

        let lastrecvalue =
          reservedPercent -
          evenSecondaryPercent * (SecondaryBeneficiary.length - 1);
        arrSecondary = arrSecondary.filter(
          (item) => item.type === "Secondary" && item.inPlan === true
        );
        arrSecondary[arrSecondary.length - 1].percentage = lastrecvalue;
      }
      return item;
    });
    props.setBeneficiaries(arrSecondary);
  }

  const itemChange = (e) => {
    let arr = [...props.beneficiaries];
    arr = arr.map((item) =>
      item.id === e.dataItem.id ? { ...item, [e.field]: e.value } : item
    );
    props.setBeneficiaries(arr);
  };

  //Copy From
  function copyFrom(event) {
    event.preventDefault();
    setOpenCopyForm(true);
  }
  return (
    <div>
      <div className="heading-form">
        <b>Allocate Beneficiaries</b>
      </div>
      <div className="mb-3 wraper-btn-add-beneficiary allocateBeneficiary">
        <Button className="btnSplit" primary={false} onClick={splitPercentage}>
          Split Percentage
        </Button>

        <Button className="btnSplit" primary={false} onClick={copyFrom}>
          Copy From
        </Button>

        <Button primary={true} onClick={handleAdd}>
          Add New Beneficiary
        </Button>
      </div>
      {openAddForm && (
        <CoverageBeneficiaryForm
          cancelEdit={handleAddCancelEdit}
          onSubmit={handleSubmit}
          item={emptyBeneficiary}
          data={props.data}
          beneficiaries={props.beneficiaries}
          setBeneficiaries={props.setBeneficiaries}
          IsAdd={true}
          primaryInsuredParty={props.primaryInsuredParty}
          category={props.category}
        />
      )}
      {openCopyForm && (
        <CopyBeneficiaryForm
          cancelEdit={handleCopyCancel}
          item={emptyBeneficiary}
          beneficiaries={props.beneficiaries}
          setBeneficiaries={props.setBeneficiaries}
          copybeneficiaries={props.copybeneficiaries}
          setOpenCopyForm={setOpenCopyForm}
          primaryInsuredParty={props.primaryInsuredParty}
          category={props.category}
        />
      )}
      {status === STATUS.SUBMITTING && <Spinner />}

      <div className="mb-3 notificationGrp">
        <NotificationGroup
          style={{
            alignItems: "flex-start",
            flexWrap: "wrap",
          }}
        >
          <Fade enter={true} exit={true}>
            {success && (
              <Notification
                type={{ style: "success", icon: true }}
                closable={true}
                onClose={() => setSuccess(false)}
              >
                <span>{ChangesSaveMessage}</span>
              </Notification>
            )}
          </Fade>
          <Fade enter={true} exit={true}>
            {error && (
              <Notification
                type={{ style: "error", icon: true }}
                closable={true}
                onClose={() => setError(false)}
              >
                <span> {saveError}</span>
              </Notification>
            )}
          </Fade>
        </NotificationGroup>
      </div>
      <br />
      <div className="mb-3 styledGrid coverageBeneficiary">
        <Grid
          data={filteredBeneficiary}
          editField={editField}
          onItemChange={itemChange}
        >
          <Column
            field="beneficiarytype"
            title="Beneficiary Type"
            width="130px"
            editable={false}
          />
          <Column field="name" title="Name" editable={false} />
          <Column
            field="dateOfBirth"
            title="Date of Birth"
            format="{0:MM/dd/yyyy}"
            editable={false}
          />
          <Column field="relationship" title="Relationship" editable={false} />
          <Column
            field="type"
            title="Type"
            cell={MyCustomTypeCell}
            editor="text"
          />
          <Column
            field="percentage"
            title="Percentage"
            cell={MyCustomPercentageCell}
          />
          <Column cell={MyEditCommandCell} title="Edit" />
          <Column cell={MyRemoveCommandCell} title="Remove" />
        </Grid>
        {openForm && (
          <CoverageBeneficiaryForm
            cancelEdit={handleCancelEdit}
            onSubmit={handleSubmit}
            item={editItem}
            beneficiaries={props.beneficiaries}
            setBeneficiaries={props.setBeneficiaries}
            IsAdd={false}
          />
        )}
      </div>
      {props.benificaryValidMsg !== `` && (
        <Label style={{ color: "Red" }}> {props.benificaryValidMsg}</Label>
      )}
    </div>
  );
};
