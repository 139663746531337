import * as React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import {
  depedentrelationships as relationships,
  genders,
  countries,
} from "../../model/constants";
import {
  FormInput,
  FormComboBox,
  FormMaskedDate, FormAutoComplete
} from "../Common/form-components";
import { requiredValidator } from "../Common/validators";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";

export default function FamilyReviewDependentForm(props) {
  const filteredDependent = props.dependents?.filter(
    (item) => item.inPlan === false && item.isActive === true
  );

  const AddCommandCell = (props) => {
    return (
      <td className="editBtn">
        <Button primary={false} onClick={() => props.enterAdd(props.dataItem)}>
          <span className="k-icon k-i-add"></span>
        </Button>
      </td>
    );
  };

  const MyAddCommandCell = (props) => (
    <AddCommandCell {...props} enterAdd={enterAdd} />
  );

  const enterAdd = (dataItem) => {
    const arr = [...props.dependents];
    const index = arr.findIndex((x) => x.id === dataItem.id);
    dataItem.inPlan = true;
    arr[index] = dataItem;
    props.setDependents(arr);
    props.cancelEdit();
  };

  return (
    <Dialog title={`Dependent`} onClose={props.cancelEdit}>
      <div className="panelbar-wrapper styledGrid addBeneficaryDialog">
        <PanelBar expandMode="single">
          <PanelBarItem
            expanded={`${props.isAdd}` === "true" ? true : false}
            title="Choose from existing dependents List"
          >
            <div className="mb-3 styledGrid reviewDependentsGrid editBeneficiary">
              <Grid data={filteredDependent}>
                <Column field="firstname" title="First Name" />
                <Column field="lastname" title="Last Name" />
                <Column
                  field="dateOfBirth"
                  title="Date of Birth"
                  format="{0:MM/dd/yyyy}"
                />
                <Column field="relationship" title="Relationship" />
                <Column field="gender" title="Gender" />
                <Column cell={MyAddCommandCell} title="Add" />
              </Grid>
            </div>
            <br />
          </PanelBarItem>
          <PanelBarItem
            expanded={`${props.isAdd}` === "true" ? false : true}
            title={
              `${props.isAdd}` === "true"
                ? "Add New Dependent"
                : "Edit Dependent"
            }
          >
            <div>
              <Form
                onSubmit={props.onSubmit}
                initialValues={props.item}
                render={(formRenderProps) => (
                  <FormElement>
                    {" "}
                    <div className="editDependency-family">
                      <fieldset className={"k-form-fieldset"}>
                        <div className="heading-form">Basic Information</div>
                        <div className="combine-form">
                          <div className="mb-3">
                            <Field
                              key={"firstname"}
                              id={"firstname"}
                              name={"firstname"}
                              label={"*First Name"}
                              component={FormInput}
                              validator={requiredValidator}
                              maxLength={20}
                            />
                          </div>
                          <div className="mb-3">
                            <Field
                              key={"middlename"}
                              id={"middlename"}
                              name={"middlename"}
                              label={"Middle Name"}
                              component={FormInput}
                              maxLength={20}
                            />
                          </div>
                        </div>
                        <div className="combine-form">
                          <div className="mb-3">
                            <Field
                              key={"lastname"}
                              id={"lastname"}
                              name={"lastname"}
                              label={"*Last Name"}
                              component={FormInput}
                              validator={requiredValidator}
                              maxLength={20}
                            />
                          </div>
                          <div className="mb-3">
                            <Field
                              key={"suffix"}
                              id={"suffix"}
                              name={"suffix"}
                              label={"Suffix"}
                              component={FormInput}
                              maxLength={10}
                            />
                          </div>
                        </div>

                        <div className="combine-form">
                          {" "}
                          <div className="mb-3">
                            <Field
                              key={"relationship"}
                              id={"relationship"}
                              name={"relationship"}
                              data={relationships}
                              label={"*Relationship"}
                              validator={requiredValidator}
                              component={FormComboBox}
                              suggest={true}
                              placeholder="Select One"
                              disabled={
                                formRenderProps.valueGetter("id") > 0
                                  ? true
                                  : false
                              }
                            />
                          </div>
                          <div className="mb-3">
                            <Field
                              key={"dateOfBirth"}
                              id={"dateOfBirth"}
                              name={"dateOfBirth"}
                              label={"Date of Birth"}
                              validator={requiredValidator}
                              component={FormMaskedDate}
                              disabled={
                                formRenderProps.valueGetter("id") > 0
                                  ? formRenderProps.valueGetter(
                                      "relationship"
                                    ) === `Spouse`
                                    ? true
                                    : false
                                  : false
                              }
                            />
                          </div>
                        </div>
                        {`${formRenderProps.valueGetter("relationship")}` ===
                          "Disabled Child" && (
                          <div className="mb-3">
                            <Field
                              key={"disabilitydate"}
                              id={"disabilitydate"}
                              name={"disabilitydate"}
                              label={"Disability Date"}
                              component={FormMaskedDate}
                            />
                          </div>
                        )}
                        <div className="combine-form">
                          <div className="mb-3">
                            <Field
                              key={"gender"}
                              id={"gender"}
                              name={"gender"}
                              component={FormComboBox}
                              data={genders}
                              label={"Gender"}
                              suggest={true}
                              placeholder="Select One"
                              disabled={
                                formRenderProps.valueGetter("id") > 0
                                  ? formRenderProps.valueGetter(
                                      "relationship"
                                    ) === `Spouse`
                                    ? true
                                    : false
                                  : false
                              }
                            />
                          </div>
                          <div className="mb-3">
                            <Field
                              key={"telephone"}
                              id={"telephone"}
                              name={"telephone"}
                              label={"Telephone"}
                              component={FormInput}
                              maxLength={30}
                            />
                          </div>
                        </div>
                      </fieldset>
                      <fieldset className={"k-form-fieldset"}>
                        <div className="heading-form">Address</div>
                        <div className="mb-3">
                          <Field
                            key={"line1"}
                            id={"line1"}
                            name={"line1"}
                            label={"Line 1"}
                            component={FormInput}
                            maxLength={100}
                          />
                        </div>
                        <div className="mb-3">
                          <Field
                            key={"line2"}
                            id={"line2"}
                            name={"line2"}
                            label={"Line 2"}
                            component={FormInput}
                            maxLength={100}
                          />
                        </div>
                        <div className="mb-3">
                          <Field
                            key={"line3"}
                            id={"line3"}
                            name={"line3"}
                            label={"Line 3"}
                            component={FormInput}
                            maxLength={100}
                          />
                        </div>
                        <div className="combine-form">
                          <div className="mb-3">
                            <Field
                              key={"city"}
                              id={"city"}
                              name={"city"}
                              label={"City"}
                              component={FormInput}
                              maxLength={50}
                            />
                          </div>

                          {/* <div className="mb-3">
                            <Field
                              key={"country"}
                              id={"country"}
                              name={"country"}
                              label={"Country"}
                              data={countries}
                              component={FormComboBox}
                              suggest={true}
                              placeholder="Select One"
                            />
                          </div> */}
                          <div className="mb-3">
                            <Field
                              key={"country"}
                              id={"country"}
                              name={"country"}
                              label={"Country"}
                              data={countries}
                              component={FormAutoComplete}
                              suggest={true}
                              placeholder="Select One"
                            />
                          </div>
                        </div>
                        <div className="combine-form">
                          <div className="mb-3">
                            <Field
                              key={"stateprovince"}
                              id={"stateprovince"}
                              name={"stateprovince"}
                              label={"State/Province"}
                              component={FormInput}
                              maxLength={30}
                            />
                          </div>
                          <div className="mb-3">
                            <Field
                              key={"postalcode"}
                              id={"postalcode"}
                              name={"postalcode"}
                              label={"Postalcode"}
                              component={FormInput}
                              maxLength={25}
                            />
                          </div>
                        </div>
                        <div className="mb-3">
                          <Field
                            key={"county"}
                            id={"county"}
                            name={"county"}
                            label={"County"}
                            component={FormInput}
                            maxLength={100}
                          />
                        </div>
                      </fieldset>
                    </div>
                    <DialogActionsBar>
                      <button
                        type={"submit"}
                        className="k-button k-primary"
                        onClick={formRenderProps.onSubmit}
                        disabled={!formRenderProps.allowSubmit}
                      >
                        {`${props.item.firstname}` === "" ? "Save" : "Update"}
                      </button>
                      <button
                        type={"button"}
                        className="k-button"
                        onClick={props.cancelEdit}
                      >
                        Cancel
                      </button>
                    </DialogActionsBar>
                  </FormElement>
                )}
              />
            </div>
          </PanelBarItem>
        </PanelBar>
      </div>
    </Dialog>
  );
}
