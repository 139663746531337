import React, { useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import {
  benificaryrelationships as relationships,
  beneficiarytypes,
  countries,
  genders,
} from "./../../model/constants";
import { requiredValidator } from "../Common/validators";
import {
  FormInput,
  FormDropDownList,
  FormCheckbox,
  FormComboBox,
  FormMaskedDate,
  FormAutoComplete
} from "../Common/form-components";
import { Notification } from "@progress/kendo-react-notification";
import { GetFetch } from "../../services/useFetch";
import { urlLinkBeneficiary } from "../../services/serviceURLs";

export default function EditBeneficiaryForm(props) {
  const [showwarning, setShowwarning] = useState(false);

  async function onValueChange(event) {
    if (props.item.id > 0) {
      if (event.value === false) {
        try {
          const saveresponse = await GetFetch(
            `${urlLinkBeneficiary}${props.item.id}`
          );
          if (saveresponse.ok) {
            const json = await saveresponse.json();
            if (json.count > 0) setShowwarning(true);
            else setShowwarning(false);
          } else throw saveresponse;
        } catch (e) {
          console.log(e.statusText);
        }
      } else setShowwarning(false);
    }
  }
  return (
    <Dialog
      title={
        `${props.item.name}` === ""
          ? "Add New Beneficiary"
          : `Edit ${props.item.name}`
      }
      onClose={props.cancelEdit}
    >
      <Form
        onSubmit={props.onSubmit}
        initialValues={props.item}
        render={(formRenderProps) => (
          <FormElement style={{ maxWidth: 650 }}>
            <div className="editDependency">
              <fieldset className={"k-form-fieldset"}>
                <div className="heading-form">Basic Information</div>
                <div className="combine-form">
                  <div className="mb-3">
                    <Field
                      key={"beneficiarytype"}
                      id={"beneficiarytype"}
                      name={"beneficiarytype"}
                      component={FormDropDownList}
                      label={"Type"}
                      data={beneficiarytypes}
                      validator={requiredValidator}
                    />
                  </div>
                  {`${formRenderProps.valueGetter("beneficiarytype")}` !==
                    "My Estate" &&
                    `${formRenderProps.valueGetter("beneficiarytype")}` !==
                      "Person" && (
                      <div className="mb-3">
                        <Field
                          key={"taxidentifier"}
                          id={"taxidentifier"}
                          name={"taxidentifier"}
                          component={FormInput}
                          label={"Tax Identifier"}
                          minLength={3}
                          maxLength={50}
                        />
                      </div>
                    )}
                </div>
                {`${formRenderProps.valueGetter("beneficiarytype")}` !==
                  "My Estate" && (
                  <div className="mb-3">
                    <Field
                      key={"name"}
                      id={"name"}
                      name={"name"}
                      component={FormInput}
                      label={"*Name"}
                      minLength={3}
                      maxLength={100}
                      validator={requiredValidator}
                    />
                  </div>
                )}
                {`${formRenderProps.valueGetter("beneficiarytype")}` ===
                  "Trust" && (
                  <div className="mb-3">
                    <Field
                      key={"dateOfTrust"}
                      id={"dateOfTrust"}
                      name={"dateOfTrust"}
                      component={FormMaskedDate}
                      label={"Date of Trust"}
                    />
                  </div>
                )}
                <div className="combine-form">
                  {`${formRenderProps.valueGetter("beneficiarytype")}` ===
                    "Person" && (
                    <div className="mb-3">
                      <Field
                        key={"dateOfBirth"}
                        id={"dateOfBirth"}
                        name={"dateOfBirth"}
                        component={FormMaskedDate}
                        label={"Date of Birth"}
                      />
                    </div>
                  )}
                  {`${formRenderProps.valueGetter("beneficiarytype")}` ===
                    "Person" && (
                    <div className="mb-3">
                      <Field
                        key={"relationship"}
                        id={"relationship"}
                        name={"relationship"}
                        label={"*Relationship"}
                        data={relationships}
                        validator={requiredValidator}
                        component={FormComboBox}
                        suggest={true}
                        placeholder="Select One"
                      />
                    </div>
                  )}{" "}
                </div>
                {`${formRenderProps.valueGetter("beneficiarytype")}` ===
                  "Person" && (
                  <div className="mb-3">
                    <Field
                      key={"gender"}
                      id={"gender"}
                      name={"gender"}
                      component={FormComboBox}
                      data={genders}
                      label={"Gender"}
                      suggest={true}
                      placeholder="Select One"
                    />
                  </div>
                )}
                <div className="mb-3">
                  <Field
                    key={"isActive"}
                    id={"isActive"}
                    name={"isActive"}
                    component={FormCheckbox}
                    label={"Active"}
                    onChange={onValueChange}
                  />
                </div>
                {showwarning === true && (
                  <div className="mb-3">
                    <Notification
                      type={{ style: "warning", icon: true }}
                      closable={false}
                    >
                      This beneficiary has been linked to one or more policies.
                      Are you sure you want to deactivate them? Doing so will
                      not remove them from current policies, but you will no
                      longer be able to designate them as beneficiaries on other
                      policies.
                    </Notification>
                  </div>
                )}
                {`${formRenderProps.valueGetter("beneficiarytype")}` ===
                  "Person" && (
                  <div className="mb-3">
                    <Field
                      key={"telephone"}
                      id={"telephone"}
                      name={"telephone"}
                      label={"Telephone"}
                      component={FormInput}
                      maxLength={30}
                    />
                  </div>
                )}
              </fieldset>
              {`${formRenderProps.valueGetter("beneficiarytype")}` !==
                "My Estate" && (
                <fieldset className={"k-form-fieldset"}>
                  <div className="heading-form">Address</div>
                  <div className="mb-3">
                    <Field
                      key={"line1"}
                      id={"line1"}
                      name={"line1"}
                      label={"Line 1"}
                      component={FormInput}
                      maxLength={100}
                    />
                  </div>
                  <div className="mb-3">
                    <Field
                      key={"line2"}
                      id={"line2"}
                      name={"line2"}
                      label={"Line 2"}
                      component={FormInput}
                      maxLength={100}
                    />
                  </div>
                  <div className="mb-3">
                    <Field
                      key={"line3"}
                      id={"line3"}
                      name={"line3"}
                      label={"Line 3"}
                      component={FormInput}
                      maxLength={100}
                    />
                  </div>
                  <div className="combine-form">
                    <div className="mb-3">
                      <Field
                        key={"city"}
                        id={"city"}
                        name={"city"}
                        label={"City"}
                        component={FormInput}
                        maxLength={50}
                      />
                    </div>
                    {/* <div className="mb-3">
                      <Field
                        key={"country"}
                        id={"country"}
                        name={"country"}
                        label={"Country"}
                        data={countries}
                        component={FormComboBox}
                        suggest={true}
                        placeholder="Select One"
                      />
                    </div>{" "} */}
                    <div className="mb-3">
                      <Field
                        key={"country"}
                        id={"country"}
                        name={"country"}
                        label={"Country"}
                        data={countries}
                        component={FormAutoComplete}
                        suggest={true}
                        placeholder="Select One"
                      />
                    </div>
                  </div>
                  <div className="combine-form">
                    <div className="mb-3">
                      <Field
                        key={"stateprovince"}
                        id={"stateprovince"}
                        name={"stateprovince"}
                        label={"State/Province"}
                        component={FormInput}
                        maxLength={30}
                      />
                    </div>
                    <div className="mb-3">
                      <Field
                        key={"postalcode"}
                        id={"postalcode"}
                        name={"postalcode"}
                        label={"Postal Code"}
                        component={FormInput}
                        maxLength={25}
                      />
                    </div>{" "}
                  </div>
                  <div className="mb-3">
                    <Field
                      key={"county"}
                      id={"county"}
                      name={"county"}
                      label={"County"}
                      component={FormInput}
                      maxLength={100}
                    />
                  </div>
                </fieldset>
              )}
            </div>
            <DialogActionsBar>
              <button
                type={"submit"}
                className="k-button k-primary"
                onClick={formRenderProps.onSubmit}
                disabled={!formRenderProps.allowSubmit}
              >
                {`${props.item.name}` === "" ? "Save" : "Update"}
              </button>
              <button
                type={"button"}
                className="k-button"
                onClick={props.cancelEdit}
              >
                Cancel
              </button>
            </DialogActionsBar>
          </FormElement>
        )}
      />
    </Dialog>
  );
}
