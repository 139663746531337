import React, { useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, FormElement } from "@progress/kendo-react-form";
import { CoverageEstimateInput } from "./CoverageEstimateInput";
import { urlEstimateCost } from "../../services/serviceURLs";
import { PostSave } from "../../services/useFetch";
import { Notification } from "@progress/kendo-react-notification";
import {
  currencyFormat,
  dateFormat,
  datewotimestampFormat,
} from "../../services/shareconstant";

export default function CoverageRateEstimater(props) {
  const [saveError, setSaveError] = useState(null);
  const [error, setError] = useState(false);
  const [estimatedCost, setEstimatedCost] = useState(0);

  const handleSubmit = async (dataItem) => {
    if (props.rateEstimationAttributes[0].type === "Money With Dropdown") {
      dataItem.CoverageAmount = Number(
        dataItem.CoverageAmount.replace(/[^0-9.-]+/g, "")
      );
    }

    const date = dateFormat(props.electionDates.effectivedate)
      ? dateFormat(props.electionDates.effectivedate)
      : datewotimestampFormat(props.electionDates.effectivedate);

    let estimateCostData = {
      insurancePlanTypeID: props.plan.insurancePlanTypeID,
      insuranceStartDate: date,
      asOfDate: date,
      attributes: JSON.stringify(dataItem),
    };

    //Reset back currency format
    if (props.rateEstimationAttributes[0].type === "Money With Dropdown") {
      dataItem.CoverageAmount = currencyFormat(dataItem.CoverageAmount);
    }

    try {
      const saveresponse = await PostSave(
        `${urlEstimateCost}`,
        `POST`,
        estimateCostData
      );

      setSaveError(null);
      if (saveresponse.ok) {
        const output = await saveresponse.json();
        setEstimatedCost(output.estimatedCost);
      } else {
        throw saveresponse;
      }
    } catch (e) {
      setSaveError(e.statusText);
      setError(true);
    }
  };

  return (
    <Dialog title={"Rate Estimator"} onClose={props.onClose}>
      <Form
        onSubmit={handleSubmit}
        initialValues={{}}
        render={(formRenderProps) => (
          <FormElement style={{ maxWidth: 650 }}>
            <fieldset className={"k-form-fieldset"}>
              {props.rateEstimationAttributes.map((item, i) => (
                <CoverageEstimateInput
                  key={i}
                  estimateinput={item}
                ></CoverageEstimateInput>
              ))}
              <br />
              <DialogActionsBar>
                <button
                  type={"submit"}
                  className="k-button k-primary"
                  onClick={formRenderProps.onSubmit}
                  disabled={!formRenderProps.allowSubmit}
                >
                  Show Rates
                </button>
              </DialogActionsBar>
            </fieldset>
          </FormElement>
        )}
      />

      <p className="estimatedAmnt">
        {" "}
        Estimated Monthly Cost: {currencyFormat(estimatedCost)}
      </p>
      {error && (
        <Notification type={{ style: "error", icon: true }} closable={true}>
          Error while Retrive Estimate Cost Information. {saveError}
        </Notification>
      )}
      <DialogActionsBar>
        <button type={"button"} className="k-button" onClick={props.onClose}>
          Close
        </button>
      </DialogActionsBar>
    </Dialog>
  );
}
