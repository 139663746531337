import React from "react";
import { Fetch } from "./../../services/useFetch";
import Spinner from "./../../components/Spinner";
import PageNotFound from "./../../components/PageNotFound";
import EOIRequestForm from "./EOIRequestForm";
import { urlPersonInsuranceEOIRequests } from "../../services/serviceURLs";
export default function EoiRequest() {
  return (
    
    <Fetch url={urlPersonInsuranceEOIRequests}>
      {(data, loading, error) => {
        if (loading) return <Spinner />;
        if (!data) return <PageNotFound />;
        if (error) throw error;

        return <EOIRequestForm data={data} />;
      }}
    </Fetch>
  );
}
