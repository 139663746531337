import React from "react";
const Restricted = () => {
  return (    
    <div className="page-wrapper">
      <p><b>The ALPA Enrollment portal is only available to US members. <a target="_blank" href="https://www.alpa.org/resources/~/link.aspx?_id=C0DC3645A2CA4368BB39400D10206157&_z=z">Click here</a> to learn more about ALPA-Sponsored Canadian coverage and how to apply.</b></p>
    </div>
  );
};

export default Restricted;
