import React from "react";
import { FetchAll } from "../../services/useFetchAll";
import Spinner from "../Spinner";
import PageNotFound from "../PageNotFound";
import CurrentCoverageForm from "./CurrentCoverageForm";
import {
  urlEnrollNewCoverage,
  urlPendingUpdateElectionsForNonFuturePlans,
  urlPendingUpdateElectionsForFuturePlans,
  urlPendingTerminations,
  urlNoPendingElections,
  urlPendingNewElections,
  urlPendingCancellations,
  urlPendingReinstatements,
  urlEffectiveDate,
  urlEOIPendingNewElections,
  urlEOIPendingUpdateElectionsForNonFuturePlans,
  urlEOIPendingUpdateElectionsForFuturePlans,
  urlEnrollNewCoverageLife,
  urlEnrollNewCoverageDisability,
  urlEnrollNewCoverageCritical,
  urlEnrollNewCoverageDental,
  urlPersonInsuranceEOIRequests,
} from "../../services/serviceURLs";
import { Notification } from "@progress/kendo-react-notification";

export default function CurrentCoverage() {
  return (
    <FetchAll
      urls={[
        urlPendingUpdateElectionsForNonFuturePlans,
        urlPendingUpdateElectionsForFuturePlans,
        urlPendingNewElections,
        urlPendingTerminations,
        urlNoPendingElections,
        urlPendingCancellations,
        urlPendingReinstatements,
        urlEnrollNewCoverage,
        urlEffectiveDate,
        urlEOIPendingUpdateElectionsForNonFuturePlans,
        urlEOIPendingUpdateElectionsForFuturePlans,
        urlEOIPendingNewElections,
        urlEnrollNewCoverageLife,
        urlEnrollNewCoverageDisability,
        urlEnrollNewCoverageCritical,
        urlEnrollNewCoverageDental,
        urlPersonInsuranceEOIRequests,
      ]}
    >
      {(data, loading, error) => {
        if (loading) return <Spinner />;
        if (!data) return <PageNotFound />;
        if (error) throw error;
        const enrollCoverage = data[7].map((el) => ({
          ...el,
          insuranceElectionID: -1,
          insuranceID: -1,
        }));
        const enrollNewCoverageLife = data[12].map((el) => ({
          ...el,
          insuranceElectionID: -1,
          insuranceID: -1,
        }));
        const enrollNewCoverageDisability = data[13].map((el) => ({
          ...el,
          insuranceElectionID: -1,
          insuranceID: -1,
        }));
        const enrollNewCoverageCritical = data[14].map((el) => ({
          ...el,
          insuranceElectionID: -1,
          insuranceID: -1,
        }));
        const enrollNewCoverageDental = data[15].map((el) => ({
          ...el,
          insuranceElectionID: -1,
          insuranceID: -1,
        }));
        
        const visibleButton =
          data[0].length > 0 ||
          data[1].length > 0 ||
          data[2].length > 0 ||
          data[3].length > 0 ||
          data[5].length > 0 ||
          data[6].length > 0;

        return (
          <>
            <div className="page-wrapper">
              {" "}
              <div className="heading-page">
                <p className="heading-form">Select Your Benefits</p>
                {data[16].length>0? 
                    <Notification show
                    type={{ style: "warning", icon: true }}
                    closable={false}
                  >
                    {" "}
                    You have an election that is pending evidence of insurability or conditional question. <a href="/eoirequest" >Click here</a> for more information.
                  </Notification> : null}
                <Notification
                  type={{ style: "info", icon: true }}
                  closable={false}
                >
                  {" "}
                  Applicable discounts will not be reflected until up to one month after enrollment.
                </Notification>

                <Notification
                  type={{ style: "info", icon: true }}
                  closable={false}
                >
                  {" "}
                  To make changes to your current coverage, click "Modify" under the plan you would like to change. To enroll in a new plan, click "Enroll".
                </Notification>                
              </div>
              <CurrentCoverageForm
                pendingUpdateElectionsForNonFuturePlans={data[0]}
                pendingUpdateElectionsForFuturePlans={data[1]}
                pendingNewElections={data[2]}
                pendingTerminations={data[3]}
                noPendingElections={data[4]}
                pendingCancellations={data[5]}
                pendingReinstatements={data[6]}
                enrollCoverage={enrollCoverage}
                visibleButton={visibleButton}
                electionDates={data[8]}                
                EOIpendingUpdateElectionsForNonFuturePlans={data[9]}
                EOIpendingUpdateElectionsForFuturePlans={data[10]}
                EOIpendingNewElections={data[11]}
                EnrollNewCoverageLife={enrollNewCoverageLife}
                EnrollNewCoverageDisability={enrollNewCoverageDisability}
                EnrollNewCoverageCritical={enrollNewCoverageCritical}
                EnrollNewCoverageDental={enrollNewCoverageDental}
              />
            </div>
          </>
        );
      }}
    </FetchAll>
  );
}
