import * as React from "react";
import { CoverageRateSection } from "./CoverageRateSection";
import { CoverageBeneficiary } from "./CoverageBeneficiary";

export const DetermineCoverage = (props) => {
  return (
    <>
      <CoverageRateSection
        formProps={props}
        card={props.card}
        coverageData={props.coverageData}
        setCoverageData={props.setCoverageData}
        coverageamountValidMsg={props.coverageamountValidMsg}
        labelValue={props.labelValue}
        rateEstimationAttributes={props.rateEstimationAttributes}
        plan={props.plan}
        electionDates={props.electionDates}
      ></CoverageRateSection>
      <br />
      {props.hasBeneficiaries === true && (
        <CoverageBeneficiary
          beneficiaries={props.beneficiaries}
          setBeneficiaries={props.setBeneficiaries}
          benificaryValidMsg={props.benificaryValidMsg}
          primaryInsuredParty={props.primaryInsuredParty}
          category={props.category}
          copybeneficiaries={props.copybeneficiaries}
        ></CoverageBeneficiary>
      )}
    </>
  );
};
