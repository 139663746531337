import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import EditDependentForm from "./EditDependentForm";
import { STATUS } from "../../model/constants";
import { PostSave } from "../../services/useFetch";
import { urlDependents } from "../../services/serviceURLs";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import Spinner from "./../Spinner";
import { AuthContext } from "../AuthContext";
import { redirectHome, ChangesSaveMessage } from "../../services/shareconstant";

export default function ReviewDependentsForm(props) {
  const [dependents, setDependents] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [openAddForm, setOpenAddForm] = useState(false);
  const [editItem, setEditItem] = useState({});
  const [status, setStatus] = useState(STATUS.IDLE);
  const [saveError, setSaveError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const authContext = useContext(AuthContext);
  const loggedinpersonid = authContext.state.userinfoglobal.LinkId;
  const emptyDependent = {
    id: -1,
    personid: loggedinpersonid,
    firstname: "",
    lasttname: "",
    dateOfBirth: "",
    gender: "",
    relationship: "",
    line1: "",
    line2: "",
    line3: "",
    city: "",
    country: "",
    stateprovince: "",
    postalcode: "",
    county: "",
    telephone: "",
    middlename: "",
    suffix: "",
    disabilitydate: "",
    isActive: true,
  };

  useEffect(() => {
    const initialdependents = props.data.map(function (item) {
      return {
        id: item.id,
        personid: loggedinpersonid,
        firstname: item.firstname,
        lastname: item.lastname,
        dateOfBirth:
          item.dateOfBirth === "1900-01-01T00:00:00" ||
          item.dateOfBirth === "0001-01-01T00:00:00"
            ? ""
            : new Date(item.dateOfBirth),
        gender: item.gender,
        relationship: item.relationship,
        line1: item.line1,
        line2: item.line2,
        line3: item.line3,
        city: item.city,
        country: item.country,
        stateprovince: item.stateprovince,
        postalcode: item.postalcode,
        county: item.county,
        telephone: item.telephone,
        middlename: item.middlename,
        suffix: item.suffix,
        disabilitydate:
          item.disabilitydate === "1900-01-01T00:00:00" ||
          item.disabilitydate === "0001-01-01T00:00:00"
            ? ""
            : new Date(item.disabilitydate),
        isActive: item.isActive,
      };
    });
    setDependents(initialdependents);
  }, [loggedinpersonid, props.data]);

  const enterEdit = (dataItem) => {
    //Date format with moment to handle date issue
    // dataItem.dateOfBirth = (dataItem.dateOfBirth === null || dataItem.dateOfBirth === 'null' || dataItem.dateOfBirth === '' || dataItem.dateOfBirth === '1900-01-01')? '': new Date(moment(dataItem.dateOfBirth).format("YYYY/MM/DD"));
    dataItem.dateOfBirth =
      dataItem.dateOfBirth === null ||
      dataItem.dateOfBirth === "null" ||
      dataItem.dateOfBirth === "" ||
      dataItem.dateOfBirth === "1900-01-01"
        ? ""
        : new Date(dataItem.dateOfBirth);
    dataItem.disabilitydate =
      dataItem.disabilitydate === null ||
      dataItem.disabilitydate === "null" ||
      dataItem.disabilitydate === "" ||
      dataItem.disabilitydate === "1900-01-01" ||
      dataItem.relationship !== "Disabled Child"
        ? ""
        : new Date(dataItem.disabilitydate);
    setEditItem(dataItem);
    setOpenForm(true);
  };

  const enterAdd = (event) => {
    event.preventDefault();
    setOpenAddForm(true);
  };

  async function handleSubmit(event) {
    if (event.id > 0) {
      setOpenForm(false);
    } else {
      setOpenAddForm(false);
    }
    //event.dateOfBirth = (event.dateOfBirth === null || event.dateOfBirth === '' || event.dateOfBirth === '0001-01-01T00:00:00')? '1900-01-01': new Date(moment(event.dateOfBirth).format("YYYY-MM-DD"));
    event.dateOfBirth =
      event.dateOfBirth === null ||
      event.dateOfBirth === "" ||
      event.dateOfBirth === "1900-01-01" ||
      event.dateOfBirth === "0001-01-01T00:00:00"
        ? "1900-01-01"
        : event.dateOfBirth.toLocaleDateString();
    event.disabilitydate =
      event.disabilitydate === null ||
      event.disabilitydate === "" ||
      event.disabilitydate === "1900-01-01" ||
      event.disabilitydate === "0001-01-01T00:00:00" ||
      event.relationship !== "Disabled Child"
        ? "1900-01-01"
        : event.disabilitydate.toLocaleDateString();
    setStatus(STATUS.SUBMITTING);
    try {
      //const saveresponse = await PostSave(`${urlDependents}/${event.id}`,`POST`,editDependent);
      const saveresponse = await PostSave(
        `${urlDependents}/${event.id}`,
        `POST`,
        event
      );
      // To handle null date
      //event.dateOfBirth = (event.dateOfBirth === null || event.dateOfBirth === '' || event.dateOfBirth === '1900-01-01')? '': new Date(moment(event.dateOfBirth).format("YYYY-MM-DD"));
      event.dateOfBirth =
        event.dateOfBirth === null ||
        event.dateOfBirth === "" ||
        event.dateOfBirth === "1900-01-01"
          ? ""
          : new Date(event.dateOfBirth);
      event.disabilitydate =
        event.disabilitydate === null ||
        event.disabilitydate === "" ||
        event.disabilitydate === "1900-01-01" ||
        event.relationship !== "Disabled Child"
          ? ""
          : new Date(event.disabilitydate);

      setSaveError(null);
      if (saveresponse.ok) {
        if (event.id > 0) {
          const arr = [...dependents];
          let index = arr.findIndex((x) => x.id === event.id);
          arr[index] = event;
          setDependents(arr);
        } else {
          const json = await saveresponse.json();
          const newDependent = { ...event, id: json.id };
          setDependents((olddependents) => [...olddependents, newDependent]);
        }
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
        setError(false);
        setStatus(STATUS.COMPLETED);
      } else {
        throw saveresponse;
      }
    } catch (e) {
      setSaveError(e.statusText);
      setStatus(STATUS.ERROR);
      setSuccess(false);
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  }

  function handleCancelEdit() {
    setOpenForm(false);
  }

  function handleAddCancelEdit() {
    setOpenAddForm(false);
  }

  const EditCommandCell = (props) => {
    return (
      <td className="editBtn">
        <Button primary={false} onClick={() => props.enterEdit(props.dataItem)}>
          <span className="k-icon k-i-edit"></span>
        </Button>
      </td>
    );
  };

  const MyEditCommandCell = (props) => (
    <EditCommandCell {...props} enterEdit={enterEdit} />
  );

  const history = useHistory();
  const gotoHome = () => {
    history.push(redirectHome());
  };

  return (
    <div className="page-wrapper">
      <div className="panelbar-wrapper myBeneficiaries">
        <form className="k-form">
          <div className="heading-form">My Family</div>

          <Notification type={{ style: "info", icon: true }} closable={false}>
            {" "}
            Update your dependent data with current information if
            applicable. Please note that updating your dependent information here does not automatically enroll in an
            insurance product nor does it designate them as beneficiaries. For survivor's benefits, you must separately add your beneficiaries 
            to the coverage you want them to receive. If you would like your dependents to be covered under any of your plans, 
            you must also add them separately to the desired programs. 
          </Notification>

          <div className="heading-form">My Dependents</div>
          <div className="mb-3 wraper-btn-add-beneficiary">
            <Button primary={true} onClick={enterAdd}>
              Add New Dependent
            </Button>
            {openAddForm && (
              <EditDependentForm
                cancelEdit={handleAddCancelEdit}
                onSubmit={handleSubmit}
                item={emptyDependent}
              />
            )}
            {status === STATUS.SUBMITTING && <Spinner />}
          </div>
          <div className="mb-3 notificationGrp">
            <NotificationGroup
              style={{
                alignItems: "flex-start",
                flexWrap: "wrap",
              }}
            >
              <Fade enter={true} exit={true}>
                {success && (
                  <Notification
                    type={{ style: "success", icon: true }}
                    closable={true}
                    onClose={() => setSuccess(false)}
                  >
                    <span>{ChangesSaveMessage}</span>
                  </Notification>
                )}
              </Fade>
              <Fade enter={true} exit={true}>
                {error && (
                  <Notification
                    type={{ style: "error", icon: true }}
                    closable={true}
                    onClose={() => setError(false)}
                  >
                    <span> {saveError}</span>
                  </Notification>
                )}
              </Fade>
            </NotificationGroup>
          </div>
          <fieldset>
            <div className="mb-3 styledGrid reviewDependentsGrid">
              <Grid data={dependents}>
                <Column field="firstname" title="First Name" />
                <Column field="lastname" title="Last Name" />
                <Column
                  field="dateOfBirth"
                  title="Date of Birth"
                  format="{0:MM/dd/yyyy}"
                />
                <Column field="relationship" title="Relationship" />
                <Column field="gender" title="Gender" />
                <Column
                  field="isActive"
                  title="Active"
                  cell={(props) => (
                    <td>
                      <input
                        type="checkbox"
                        checked={props.dataItem[props.field]}
                        onChange={() => {}} 
                        className="disabledCheckBox"
                      />
                    </td>
                  )}
                />
                <Column cell={MyEditCommandCell} title="Edit" />
              </Grid>
              {openForm && (
                <EditDependentForm
                  cancelEdit={handleCancelEdit}
                  onSubmit={handleSubmit}
                  item={editItem}
                />
              )}
            </div>
          </fieldset>

          {props.showBackButton && (
            <Button onClick={gotoHome} primary={true} className="btnLink">
              Back to Home
            </Button>
          )}
        </form>
      </div>
    </div>
  );
}

ReviewDependentsForm.defaultProps = {
  showBackButton: true,
};
