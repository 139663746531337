import React, { useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { useHistory } from "react-router-dom";
import EnrollNewForm from "./EnrollNewForm";
import PendingUpdateElectionsForNonFuturePlans from "./PendingUpdateElectionsForNonFuturePlans";
import PendingUpdateElectionsForFuturePlans from "./PendingUpdateElectionsForFuturePlans";
import PendingNewElections from "./PendingNewElections";
import PendingTerminations from "./PendingTerminations";
import NoPendingElections from "./NoPendingElections";
import PendingCancellations from "./PendingCancellations";
import PendingReinstatements from "./PendingReinstatements";
import EOIPendingUpdateElectionsForNonFuturePlans from "./EOIPendingUpdateElectionsForNonFuturePlans";
import EOIPendingUpdateElectionsForFuturePlans from "./EOIPendingUpdateElectionsForFuturePlans";
import EOIPendingNewElections from "./EOIPendingNewElections";
import { Label } from "@progress/kendo-react-labels";
import { Switch } from "@progress/kendo-react-inputs";
//import CurrentInEffectElections from "./CurrentInEffectElections";
import { urlReviewElections } from "../../services/serviceURLs";
import { GetFetch } from "../../services/useFetch";
import { STATUS } from "../../model/constants";
import Spinner from "../Spinner";
import { Notification } from "@progress/kendo-react-notification";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import HistoricalCoverage from "./HistoricalCoverage";
import { historyMinDate, historyMaxDate } from "../../services/shareconstant";
//import {AuthContext} from "../AuthContext";

export default function CurrentCoverageForm(props) {
  const [showCurrent, setShowCurrent] = useState(true);
  const [showNew, setShowNew] = useState(true);
  const [checked, setChecked] = useState(true);
  const [value, setValue] = React.useState(new Date());

  const [status, setStatus] = useState(STATUS.IDLE);
  const [getError, setGetError] = useState(null);
  //const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  // const currentnoPendingElections = props.noPendingElections.filter(
  //   (item) => item.startDate < props.electionDates.currentdate
  // );

  // useEffect(() => {
  //   authContext.addDateHandler(props.electionDates) }, [props.electionDates, authContext])

  const onCurrentClick = () => {
    setShowCurrent(!showCurrent);
  };
  const onNewClick = () => {
    setShowNew(!showNew);
  };

  const handleChange = (event) => {
    setChecked(!checked);
  };

  const handleDateChange = (event) => {
    setValue(event.value);
  };
  const history = useHistory();
  const onSelectingBenefits = async () => {
    setStatus(STATUS.SUBMITTING);
    setGetError(null);
    setError(false);
    try {
      const saveresponse = await GetFetch(`${urlReviewElections}`);
      if (saveresponse.ok) {
        const electionData = await saveresponse.json();
        setStatus(STATUS.COMPLETED);
        //setSuccess(true);
        history.push({
          pathname: `/reviewelection`,
          state: {
            pendingUpdateElectionsForFuturePlans:
              props.pendingUpdateElectionsForFuturePlans,
            pendingUpdateElectionsForNonFuturePlans:
              props.pendingUpdateElectionsForNonFuturePlans,
            pendingNewElections: props.pendingNewElections,
            pendingTerminations: props.pendingTerminations,
            pendingCancellations: props.pendingCancellations,
            pendingReinstatements: props.pendingReinstatements,
            electionDates: props.electionDates,
            electionData: electionData,
            EOIpendingUpdateElectionsForFuturePlans:
              props.EOIpendingUpdateElectionsForFuturePlans,
            EOIpendingUpdateElectionsForNonFuturePlans:
              props.EOIpendingUpdateElectionsForNonFuturePlans,
            EOIpendingNewElections: props.EOIpendingNewElections,
          },
        });
      } else {
        throw saveresponse;
      }
    } catch (e) {
      setGetError(e.statusText);
      setStatus(STATUS.ERROR);
      // setSuccess(false);
      setError(true);
    }
  };

  const submitButton = (
    <>
      {props.visibleButton && (
        <div className="action-button-submit">
          <Label style={{ color: "Red" }}>You have unsubmitted changes</Label>
          <Button primary={true} onClick={onSelectingBenefits}>
            Continue to Submit Changes
            <span className="k-icon k-i-arrow-chevron-right" />
          </Button>
        </div>
      )}
    </>
  );

  const currentenrollment = showCurrent ? (
    <>
      {checked && (
        <>
          {submitButton}
          <div className="card-list-wrap">
            <PendingUpdateElectionsForFuturePlans
              pendingUpdateElectionsForFuturePlans={
                props.pendingUpdateElectionsForFuturePlans
              }
              electionDates={props.electionDates}
            ></PendingUpdateElectionsForFuturePlans>
            <PendingUpdateElectionsForNonFuturePlans
              pendingUpdateElectionsForNonFuturePlans={
                props.pendingUpdateElectionsForNonFuturePlans
              }
              electionDates={props.electionDates}
            ></PendingUpdateElectionsForNonFuturePlans>             
            <PendingNewElections
              pendingNewElections={props.pendingNewElections}
              electionDates={props.electionDates}
            ></PendingNewElections>
            <PendingTerminations
              pendingTerminations={props.pendingTerminations}
            ></PendingTerminations>
            <PendingCancellations
              pendingCancellations={props.pendingCancellations}
            ></PendingCancellations>
            <PendingReinstatements
              pendingReinstatements={props.pendingReinstatements}
            ></PendingReinstatements>
            <NoPendingElections
              noPendingElections={props.noPendingElections}
              electionDates={props.electionDates}
            ></NoPendingElections>
            <EOIPendingUpdateElectionsForFuturePlans
              EOIpendingUpdateElectionsForFuturePlans={
                props.EOIpendingUpdateElectionsForFuturePlans
              }
              electionDates={props.electionDates}
            ></EOIPendingUpdateElectionsForFuturePlans>
            <EOIPendingUpdateElectionsForNonFuturePlans
              EOIpendingUpdateElectionsForNonFuturePlans={
                props.EOIpendingUpdateElectionsForNonFuturePlans
              }
              electionDates={props.electionDates}
            ></EOIPendingUpdateElectionsForNonFuturePlans>             
            <EOIPendingNewElections
              EOIpendingNewElections={props.EOIpendingNewElections}
              electionDates={props.electionDates}
            ></EOIPendingNewElections>
          </div>

          {submitButton}
        </>
      )}
      {!checked && (
        // (
        //   <div className="card-list-wrap">
        //     <CurrentInEffectElections
        //       Data={props.pendingUpdateElectionsForNonFuturePlans}
        //     ></CurrentInEffectElections>
        //     <CurrentInEffectElections
        //       Data={props.pendingTerminations}
        //     ></CurrentInEffectElections>
        //     <CurrentInEffectElections
        //       Data={props.pendingReinstatements}
        //     ></CurrentInEffectElections>
        //     <CurrentInEffectElections
        //       Data={currentnoPendingElections}
        //     ></CurrentInEffectElections>
        //   </div>
        // )

        <div className="card-list-wrap">
          <HistoricalCoverage historyDate={value}></HistoricalCoverage>
        </div>
      )}
    </>
  ) : null;

  const newenrollment = showNew ? (
    <div className="card-list-wrap">
       <span className="cardCategory">Life Insurance</span>
      <EnrollNewForm
        enrollCoverage={props.EnrollNewCoverageLife}
        electionDates={props.electionDates}
      />      
      <span className="cardCategory">Disability Insurance</span>
      <EnrollNewForm
        enrollCoverage={props.EnrollNewCoverageDisability}
        electionDates={props.electionDates}
      />
      <span className="cardCategory">Limited Benefit Indemnity Plans</span>      
      <EnrollNewForm
        enrollCoverage={props.EnrollNewCoverageCritical}
        electionDates={props.electionDates}
      />
      <span className="cardCategory">Dental</span>
      <EnrollNewForm
        enrollCoverage={props.EnrollNewCoverageDental}
        electionDates={props.electionDates}
      />
    </div>
  ) : null;

  return (
    <>
      {status === STATUS.SUBMITTING && <Spinner />}

      {error && (
        <Notification type={{ style: "error", icon: true }} closable={false}>
          {getError}
        </Notification>
      )}

      <div className="k-panelbar zIndexFix panelBarWrapper">
        <div className="k-panelbar-header">
          <span className="k-link">
            <span
              className="k-panelbar-item-text panelHeading"
              onClick={onCurrentClick}
            >
              <div class="card-header">
              Current Coverage
              </div>
            </span>
                     
            <span
              className={
                showCurrent
                  ? "k-icon k-i-arrow-chevron-up k-panelbar-collapse"
                  : "k-icon k-i-arrow-chevron-down k-panelbar-collapse"
              }
              onClick={onCurrentClick}
            ></span>
          </span>
        </div>
        <div className="panelBarBody">
        
          {showCurrent? <span className="switchButton">
            <Switch onChange={handleChange} checked={checked} />
            <Label style={{ color: "Grey" }}>
              Show Unsubmitted and Approved Changes
            </Label>
            {!checked && (
              <Label className="DisplayCoverageDate">
                Display coverage as of
              </Label>
            )}
            {!checked && (
              <DatePicker
                value={value}
                min={historyMinDate}
                max={historyMaxDate}
                onChange={handleDateChange}
              />
            )}
          </span>
          :<div> </div>}
          {currentenrollment}
        </div>
      </div>

      <div className="k-panelbar zIndexFix panelBarWrapper">
        <div className="k-panelbar-header" onClick={onNewClick}>
          <span className="k-link">
            <span className="k-panelbar-item-text card-header">Enroll for New Plans</span>
            <span
              className={
                showNew
                  ? "k-icon k-i-arrow-chevron-up k-panelbar-collapse"
                  : "k-icon k-i-arrow-chevron-down k-panelbar-collapse"
              }
            ></span>
          </span>
        </div>

        <div className="panelBarBody">{newenrollment}</div>
      </div>
    </>
  );
}
