import React from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { exceptionMessage: "" };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { exceptionMessage: error.toString() };
  }

  // handle exception
  componentDidCatch = (error, logInfo) => {
    // log it on console
    console.log(
      `Exception Occured ${error.toString()} and log stack${
        logInfo.componentStatck
      }`
    );
  };

  render() {
    if (this.state.exceptionMessage) {
      // You can render any custom fallback UI
      return (
        <h1>
          Something went wrong,please reload the page by clicking on reload
          button
        </h1>
      );
    }

    return this.props.children;
  }
}
