import React, { useState, useContext } from "react";
import { PostFetch } from "../services/useFetch";
import Spinner from "./Spinner";
import { Input } from "@progress/kendo-react-inputs";
import { AuthContext } from "./AuthContext";
import { urlLogin } from "../services/serviceURLs";
import { STATUS } from "../model/constants";

const Login = (props) => {
  const authContext = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState(STATUS.IDLE);
  const [errorMsg, setErrorMsg] = useState("");

  async function handleSubmit(event) {
    event.preventDefault();
    setStatus(STATUS.SUBMITTING);

    try {
      let formdata = new FormData();
      formdata.append("UserName", username);
      formdata.append("Password", password);
      formdata.append("RememberMe", "False");
      const saveresponse = await PostFetch(urlLogin, formdata);
      setStatus(STATUS.COMPLETED);

      if (saveresponse.ok) {
        authContext.state.CompanyAffilliatedCountry="";
        const json = await saveresponse.json();
        authContext.addUserHandler(json);
      } else {
        setErrorMsg(saveresponse.statusText);
        setStatus(STATUS.ERROR);
        throw saveresponse;
      }
    } catch (e) {
      setStatus(STATUS.ERROR);
    }
  }

  function handleUserChange(evt) {
    setUsername(evt.target.value);
  }

  function handlePassChange(evt) {
    setPassword(evt.target.value);
  }

  return (
    <div className="Login">
      <h3>Login</h3>
      <form onSubmit={handleSubmit}>
        {
          <h3 data-test="errorMsg" style={{ color: "red" }}>
            {errorMsg}
          </h3>
        }

        <div className="mb-3">
          <Input
            name="username"
            value={username}
            label="User Name"
            required={true}
            onChange={handleUserChange}
          />
        </div>
        <div className="mb-3">
          <Input
            name="password"
            value={password}
            label="Password"
            type="password"
            required={true}
            onChange={handlePassChange}
          />
        </div>
        <br />
        <div>
          <input type="submit" className="k-button k-primary" value="Log In" />
          {status === STATUS.SUBMITTING && <Spinner />}
        </div>
      </form>
    </div>
  );
};

export default Login;
