import * as React from "react";
import { Field } from "@progress/kendo-react-form";
import { requiredValidator } from "../Common/validators";
import { FormComboBox, FormNumericTextBox } from "../Common/form-components";
import { currencyFormat } from "../../services/shareconstant";

export const CoverageEstimateInput = (props) => {
  const validvalues = [];
  if (props.estimateinput.type === "Money With Dropdown") {
    props.estimateinput.validValues.split("|").forEach((element) => {
      validvalues.push(currencyFormat(element).toString());
    });
  }
  return (
    <>
      {props.estimateinput.type === "Money" && (
        <>
          <Field
            id={props.estimateinput.name}
            name={props.estimateinput.name}
            label={props.estimateinput.label}
            component={FormNumericTextBox}
            validator={requiredValidator}
            step={props.estimateinput.increment}
            format="c0"
            min={props.estimateinput.minValue}
            max={props.estimateinput.maxValue}
          />
        </>
      )}

      {props.estimateinput.type === "Number" && (
        <>
          <Field
            id={props.estimateinput.name}
            name={props.estimateinput.name}
            label={props.estimateinput.label}
            component={FormNumericTextBox}
            validator={requiredValidator}
            step={props.estimateinput.increment}
            format="###"
            min={props.estimateinput.minValue}
            max={props.estimateinput.maxValue}
          />
        </>
      )}

      {props.estimateinput.type === "Dropdown" && (
        <>
          <Field
            label={props.estimateinput.label}
            key={props.estimateinput.name}
            id={props.estimateinput.name}
            name={props.estimateinput.name}
            data={props.estimateinput.validValues.split("|")}
            validator={requiredValidator}
            component={FormComboBox}
            suggest={true}
            placeholder="Select One"
          />
        </>
      )}
      {props.estimateinput.type === "Money With Dropdown" && (
        <>
          <Field
            label={props.estimateinput.label}
            key={props.estimateinput.name}
            id={props.estimateinput.name}
            name={props.estimateinput.name}
            data={validvalues}
            validator={requiredValidator}
            component={FormComboBox}
            suggest={true}
            placeholder="Select One"
          />
        </>
      )}
    </>
  );
};
