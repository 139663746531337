import React, { useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Zoom } from "@progress/kendo-react-animation";
import { CardTitle, CardSubtitle } from "@progress/kendo-react-layout";
import { formatDate, parseDate } from "@telerik/kendo-intl";
import { Tooltip } from "@progress/kendo-react-tooltip";
import CustomToolTip from "./CustomToolTip";
import CustomEOIToolTip from "./CustomEOIToolTip";
import { currencyFormat } from "../../services/shareconstant";

export default function CurrentCoverageCard(props) {
  const [current, setCurrent] = useState(props.current);
  const [index, setIndex] = useState(props.current ? 1 : 2);
  const [buttonlable, setButtonLabel] = useState(
    props.current
      ? `View Changes Effective ${formatDate(
          parseDate(props.card.futureDetails.effectiveDate, [
            "yyyy/MM/dd",
            "yyyy-MM-ddTHH:mm:ss",
          ]),
          "MM/dd/yyyy"
        )}`
      : `View Current Plan Details`
  );

  const toggleCard = () => {
    setCurrent(!current);
    if (
      buttonlable ===
      `View Changes Effective ${formatDate(
        parseDate(props.card.futureDetails.effectiveDate, [
          "yyyy/MM/dd",
          "yyyy-MM-ddTHH:mm:ss",
        ]),
        "MM/dd/yyyy"
      )}`
    )
      setButtonLabel("View Current Plan Details");
    else
      setButtonLabel(
        `View Changes Effective ${formatDate(
          parseDate(props.card.futureDetails.effectiveDate, [
            "yyyy/MM/dd",
            "yyyy-MM-ddTHH:mm:ss",
          ]),
          "MM/dd/yyyy"
        )}`
      );
    index === 1 ? setIndex(2) : setIndex(1);
  };

  const children = current ? (
    <>  
      <div className="wrapper-Block">
        <div className="planDetailsBlock">
          {props.card.currentDetails.summaryAttributes &&
            Object.keys(props.card.currentDetails.summaryAttributes).map(
              (key, i) => (
                <p key={i}>
                  {(props.card.status==='EOI Pending') && key==='EOI Amount' ?
                   <CardSubtitle className="label-viewdetails">
                   {" "}
                   {key}
                 </CardSubtitle>                
                  :((key!=='EOI Amount')? 
                  <CardSubtitle className="label-viewdetails">
                   {" "}
                   {key}
                 </CardSubtitle>  
                 :null)             
                }
                
                 {(props.card.status==='EOI Pending') && key==='EOI Amount' ?
                      currencyFormat(
                        parseInt(
                          props.card.currentDetails.summaryAttributes[key]
                        )
                      )               
                  :((key!=='EOI Amount')? 
                  <span>
                   {" "}                    
                   {isNaN(props.card.currentDetails.summaryAttributes[key])
                     ? props.card.currentDetails.summaryAttributes[key]
                     : currencyFormat(
                         parseInt(
                           props.card.currentDetails.summaryAttributes[key]
                         )
                       )}
                 </span>
                 :null)
                 }
                </p>
              )
            )}
        </div>
        <div className="memberCostBlock">
          {props.card.discountOrPremiumWaiverEligible === true
            ? props.card.currentDetails &&
              Object.keys(props.card.currentDetails).map(
                (key, i) =>
                  key !== "discountOrPremiumWaiverAttributes" &&
                  key !== "summaryAttributes" && (
                    <p key={i}>
                      {key === "memberCost" ? (
                        <>
                          <Tooltip
                            content={(prop) => (
                              <CustomToolTip
                                key={i}
                                data={
                                  props.card?.currentDetails
                                    ?.discountOrPremiumWaiverAttributes
                                }
                              />
                            )}
                            anchorElement="target"
                            position="bottom"
                          >
                            <span className="label-viewdetails">
                              Member Monthly Cost{" "}
                            </span>
                            <span
                              className="k-icon k-i-information"
                              title="Member Monthly Cost"
                            ></span>
                          </Tooltip>
                        </>
                      ) : (
                        <div>
                          {" "}                          
                          {key === "fullCost" && (props.card.status==='EOI Pending') ? <>
                          <Tooltip
                            content={(prop) => (
                              <CustomEOIToolTip                               
                              />
                            )}
                            anchorElement="target"
                            position="bottom"
                          >
                            <span className="label-viewdetails">
                            Full Monthly Cost{" "}
                            </span>
                            <span
                              className="k-icon k-i-information"
                              title="Full Monthly Cost"
                            ></span>
                          </Tooltip>
                        </> : (key === "fullCost"?<CardSubtitle className="label-viewdetails">Full Monthly Cost</CardSubtitle>:key)}
                        </div>
                      )}
                      <span>
                        {" "}
                        {isNaN(props.card.currentDetails[key])
                          ? props.card.currentDetails[key]
                          : props.card.currentDetails[key] === -1
                          ? "TBD"
                          : currencyFormat(props.card.currentDetails[key])}{" "}
                      </span>
                    </p>
                  )
              )
            : props.card.currentDetails &&
              Object.keys(props.card.currentDetails).map(
                (key, i) =>
                  key !== "discountOrPremiumWaiverAttributes" &&
                  key !== "summaryAttributes" &&
                  key !== "fullCost" && (
                    <p key={i}>
                      <CardSubtitle className="label-viewdetails">
                        {" "}
                        {key === "memberCost" ? "Member Monthly Cost" : key}{" "}
                      </CardSubtitle>
                      <span>
                        {" "}
                        {isNaN(props.card.currentDetails[key])
                          ? props.card.currentDetails[key]
                          : props.card.currentDetails[key] === -1
                          ? "TBD"
                          : currencyFormat(props.card.currentDetails[key])}{" "}
                      </span>
                    </p>
                  )
              )}
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="wrapper-Block contentChanged">
        <div className="planDetailsBlock">
          {props.card.futureDetails.summaryAttributes &&
            Object.keys(props.card.futureDetails.summaryAttributes).map(
              (key, i) => (
                <p key={i}>
                  <CardSubtitle className="label-viewdetails">
                    {" "}
                    {key}
                  </CardSubtitle>
                  <span className="value-viewDetails">
                    {" "}
                    {isNaN(props.card.futureDetails.summaryAttributes[key])
                      ? props.card.futureDetails.summaryAttributes[key]
                      : currencyFormat(
                          parseInt(
                            props.card.futureDetails.summaryAttributes[key]
                          )
                        )}
                  </span>
                </p>
              )
            )}
        </div>
        <div className="memberCostBlock">
          {props.card.discountOrPremiumWaiverEligible === true
            ? props.card.futureDetails &&
              Object.keys(props.card.futureDetails).map(
                (key, i) =>
                  key !== "discountOrPremiumWaiverAttributes" &&
                  key !== "summaryAttributes" &&
                  key !== "effectiveDate" && (
                    <p key={i}>
                      {key === "memberCost" ? (
                        <>
                          <Tooltip
                            content={(prop) => (
                              <CustomToolTip
                                key={i}
                                data={
                                  props.card?.futureDetails
                                    ?.discountOrPremiumWaiverAttributes
                                }
                              />
                            )}
                            anchorElement="target"
                            position="bottom"
                          >
                            <span className="label-viewdetails">
                              Member Monthly Cost{" "}
                            </span>
                            <span
                              className="k-icon k-i-information"
                              title="Member Monthly Cost"
                            ></span>
                          </Tooltip>
                        </>
                      ) : (
                       <div>
                          {" "}
                          {key === "fullCost" && (props.card.status==='EOI Pending') ? <>
                          <Tooltip
                            content={(prop) => (
                              <CustomEOIToolTip                               
                              />
                            )}
                            anchorElement="target"
                            position="bottom"
                          >
                            <span className="label-viewdetails">
                              Full Monthly Cost{" "}
                            </span>
                            <span
                              className="k-icon k-i-information"
                              title="Full Monthly Cost"
                            ></span>
                          </Tooltip>
                        </> : (key === "fullCost"?<CardSubtitle className="label-viewdetails">Full Monthly Cost</CardSubtitle>:key)}
                        </div>
                      )}
                      <span className="value-viewDetails">
                        {" "}
                        {isNaN(props.card.futureDetails[key])
                          ? props.card.futureDetails[key]
                          : props.card.futureDetails[key] === -1
                          ? "TBD"
                          : currencyFormat(props.card.futureDetails[key])}{" "}
                      </span>
                    </p>
                  )
              )
            : props.card.futureDetails &&
              Object.keys(props.card.futureDetails).map(
                (key, i) =>
                  key !== "discountOrPremiumWaiverAttributes" &&
                  key !== "summaryAttributes" &&
                  key !== "fullCost" &&
                  key !== "effectiveDate" && (
                    <p key={i}>
                      <CardSubtitle className="label-viewdetails">
                        {" "}
                        {key === "memberCost" ? "Member Monthly Cost" : key}{" "}
                      </CardSubtitle>
                      <span className="value-viewDetails">
                        {" "}
                        {isNaN(props.card.futureDetails[key])
                          ? props.card.futureDetails[key]
                          : props.card.futureDetails[key] === -1
                          ? "TBD"
                          : currencyFormat(props.card.futureDetails[key])}{" "}
                      </span>
                    </p>
                  )
              )}
        </div>
      </div>
    </>
  );
  return (
    <>
      <Button
        primary={true}
        className="k-flat btn-viewDetails"
        onClick={toggleCard}
      >
        {" "}
        {buttonlable}
      </Button>
      {/* <CardSubtitle>Unsubmitted update election - ALPAIN-61 -PendingUpdateElectionsForNonFuturePlans  </CardSubtitle> */}
      <CardTitle>
        {props.card.insuranceID >= 0 ? (
        <p class="text-ellipsis description-block">
          {props.card.insurancePlanTypeDescription} ID #{props.card.insuranceID}
        </p>
      ) : (
        <p class="text-ellipsis description-block">
          {props.card.insurancePlanTypeDescription}
        </p>
      )}
        {props.card.nonContributoryOptIn === true &&
        JSON.parse(localStorage.getItem("showApprentice")) === true
          ? ` (Apprentice)`
          : null}
      </CardTitle>          
      <p className="startDate-label">
        Start Date:{" "}
        {formatDate(
          parseDate(props.card.startDate, [
            "yyyy/MM/dd",
            "yyyy-MM-ddTHH:mm:ss",
          ]),
          "MM/dd/yyyy"
        )}
      </p>
      <Zoom stackChildren={true}>
        <div className="content wrapper-Animation" key={index}>
          {children}
        </div>
      </Zoom>
    </>
  );
}
