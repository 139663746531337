import React from "react";
import { Fetch } from "../../services/useFetch";
import Spinner from "../Spinner";
import PageNotFound from "../PageNotFound";
import ReviewBeneficiariesForm from "./ReviewBeneficiariesForm";
import { urlBeneficiaries } from "../../services/serviceURLs";

export default function ReviewBeneficiaries() {
  return (
    <Fetch url={urlBeneficiaries}>
      {(data, loading, error) => {
        if (loading) return <Spinner />;
        if (!data) return <PageNotFound />;
        if (error) throw error;

        return <ReviewBeneficiariesForm data={data} />;
      }}
    </Fetch>
  );
}
