import React from "react";
import EOIRequestDetails from "../Notification/EOIRequestDetails";
export default function EOIRequestForm(props) {
    return (
        props.data[0] ? (<div class="page-wrapper">
          <div>
            <div>
             <EOIRequestDetails data={props.data} />
            </div>
            </div>
        </div>) : (
            <div class="page-wrapper">
                <div class="heading-form">Notifications</div>  <br /> <br />
                <p>You do not have pending EOI for any plan</p> <br /> </div>
        )
    );
}