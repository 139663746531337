import React from "react";
const tooltipMessageEOIAmount = `Full Cost and Member Cost can be affected by pending EOI decisions.`;

export default function CustomEOIToolTip() {
  return (
           <>
          <span>{tooltipMessageEOIAmount}</span>
        </>     
  );
}
